@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.welcome-partial {
  @extend %padded-top-default;

  @include on("tablet-down") {
    text-align: center;
  }
}

.welcome-partial__title {
  @extend %text-peta;
  @extend %font-style-heading;
  margin: 0 0 $spacer-std;
}

.welcome-partial__body {
  @extend %text-kilo;
  margin: 0;
}
