@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";

.blog-article {
  margin-bottom: get-size("default");

  @include on("fablet-down") {
    &.hidden-xs {
      display: none;
    }
  }

  img {
    width: 100%;
    margin-bottom: get-size("less");
  }

  a {
    text-decoration: none;
  }
}

.blog-article-category {
  margin-bottom: get-size("small");
  color: $color-racing-green;
}

.blog-article-title {
  margin-bottom: get-size("small");
}

.blog-article-content {
  margin-top: 0;

  *:first-child {
    margin-top: 0;
  }
}
