@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.line-item {
  @extend %padded-small-on-fablet-down;
  @extend %padded-on-tablet-up;
  position: relative;
  min-height: rem(135);
  display: flex;
}

.line-item-content-wrapper {
  flex: 1;
  display: flex;

  @include on("mobile") {
    display: inline-block;
  }

  .line-item-content {
    @extend %spaced-tiny-on-mobile;
  }

  .line-item-option {
    &.line-item-option--empty {
      @extend %spaced-top-none;
    }
  }

  .line-item-edit {
    @extend %padded-none;
    @extend %text-milli;
    color: get-colour("green");
    border: none;
    display: block;
    &:hover,
    &:focus,
    &:active {
      color: get-colour("green-dark");
    }
    &.line-item-edit--preview {
      @extend %spaced-top-small;
    }
  }
}

.line-item-image-wrapper {
  @extend %spaced-right-small;
  position: relative;
  width: rem(120);
  flex-shrink: 0;
  display: inline-block;
}

.line-item-image {
  width: 100%;
  &.line-item-image--default {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.line-item-content {
  flex-grow: 1;

  @include on("tablet-up") {
    display: flex;
    justify-content: space-between;
  }
}

.line-item-customisation,
.line-item-option {
  display: block;
}

.line-item-price {
  @extend %spaced-top-small-on-fablet-down;
  color: get-colour("red");
  .saving-price {
    top: 0;
  }
  @include on("tablet-up") {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.line-item-delete {
  @extend %spaced-left;
  color: get-colour("white");
  background: none;
  padding: 0;
  border: 0;
  height: rem(32);
  line-height: rem(32);
  text-align: center;
  width: rem(36);
  cursor: pointer;
  position: relative;
  top: -0.5rem;
  right: -0.5rem;

  @include on("fablet-down") {
    position: absolute;
    margin: 0;
  }

  &:before {
    @extend %circled;
    background-color: get-colour("red");
    content: "";
    height: rem(32);
    width: rem(32);
    position: absolute;
    left: rem(2);
    top: rem(0);
    transition: 0.25s ease;
  }

  &:hover,
  &:focus {
    outline: none;
    &:before {
      background-color: get-colour("red-dark");
    }
  }

  &:active {
    outline: none;
    &:before {
      background: get-colour("almost-black");
    }
  }

  span {
    position: relative;
  }
}
