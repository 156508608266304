@import "~chameleon/helpers/box";

.refer-a-friend {
  @extend %spaced-top-default;
  text-align: center;

  .refer-a-friend-title {
    @extend %spaced-bottom-default;
    h3 {
      margin-bottom: 0;
    }
  }

  .mention-me {
    @extend %padded-none;
    display: flex;
    justify-content: center;
  }
}
