@import "~wonderbly-css/base";

.product-review-list {
  background-color: white;
  padding: 40px 0;
  .row > div {
    margin: 0 auto;
    display: block;
    @include on("fablet-down") {
      width: 100%;
    }
  }
  .product-review-title {
    float: left;
  }
  .overall-reviews {
    float: right;
    text-align: right;
    .total {
      p {
        margin: 0;
        strong {
          @extend %text-mega;
        }
      }
    }
  }
  .reviews {
    clear: both;
    .product-reviews {
      margin-top: 40px;
    }
  }
  .product-review-list-button {
    margin: 0 auto;
    display: block;
  }
}
