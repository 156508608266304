@import "~wonderbly-css/base";

.grown-up-reviews.hidden {
  display: none;
}
.grown-up-review {
  .grown-up-review-meta {
    display: flex;
    margin-bottom: 20px;
    @include on("fablet-down") {
      flex-direction: row-reverse;
      & > div {
        width: 50%;
      }
      .star-rating {
        text-align: right;
      }
    }
  }
  .review-name,
  .review-location {
    @extend %text-kilo;
    font-family: $font-family-display;
    font-weight: $font-weight-base;
  }
  .review-name {
    margin-top: $spacer-std;
  }
  .rule--medium {
    margin: 40px 0;
    opacity: 0.25;
  }
  .grown-up-review-content {
    .rich-text {
      margin-top: 0;
    }
  }
  @include on("tablet-up") {
    display: flex;
    flex-wrap: wrap;
    .grown-up-review-meta {
      flex-direction: column;
      width: 20%;
    }
    .grown-up-review-content {
      width: 80%;
      padding-left: 20px;
    }
  }
}
