@import "~wonderbly-css/functions/palette";
@import "~chameleon/helpers/box";

.pricing {
  display: flex;
  .current,
  .saving-price {
    @extend %spaced-none;
    width: auto;
  }
  .saving-price {
    @extend %spaced-left-small;
    color: get-colour("grey-light");
    font-size: rem(14px);
    position: relative;
    top: -2px;
    &.saving-price--slash {
      text-decoration: line-through;
    }
  }
  &.pricing--slash {
    width: 100%;
  }
  &.pricing--saved {
    flex-direction: column;
  }
}

.pricing.pricing--slash .current {
  color: get-colour("red");
}
