@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/palette";
@import "~chameleon/helpers/box";

.split-banner-image {
  @extend %spaced-top-big;
  @extend %spaced-bottom-big;
  counter-reset: item;
  .image-part {
    width: 100%;
    @extend %spaced-bottom;
    .image {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        float: left;
      }
    }
    .content {
      width: 100%;
      ul,
      ol {
        @extend %spaced-none;
        @extend %padded-left-none;
        li > strong {
          display: inline-block;
          & + br {
            display: block;
            @extend %spaced-bottom-small;
            content: "";
          }
        }
      }
      ol {
        & > li {
          @extend %spaced-none;
          @extend %padded-none;
          text-indent: 0;
          list-style-type: none;
          counter-increment: item;
          &:before {
            display: inline-block;
            width: rem(27px);
            @extend %padded-right-small;
            margin-left: -1.5em;
            font-weight: bold;
            text-align: right;
            content: counter(item) ".";
          }
          &.aligned-center {
            padding: 0;
            &:before {
              margin-left: 0;
            }
          }
        }
      }
      &.has-background {
        @extend %padded;
      }
    }
    &.above-image {
      .content {
        @extend %spaced-bottom;
      }
    }
    &.below-image {
      .content {
        @extend %spaced-top;
      }
    }
    &.inside-top,
    &.inside-center,
    &.inside-bottom {
      .content {
        @extend %padded;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    &.inside-center {
      .content {
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    &.inside-bottom {
      .content {
        top: auto;
        bottom: 0;
      }
    }
  }
  .rich-text {
    margin-top: 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-child {
        @extend %spaced-top-none;
      }
    }
    p {
      &:first-of-type {
        @extend %spaced-top-none;
      }
      &:last-of-type {
        @extend %spaced-bottom-none;
      }
    }
  }
  @include on("tablet-up") {
    .images-wrapper {
      display: flex;
    }
    .image-part {
      display: flex;
      flex-direction: column;
      padding: 0 rem(7px);
      .content {
        &.has-background {
          flex: 1;
        }
      }
      &.above-image {
        justify-content: flex-end;
      }
      &.inside-center {
        .content {
          top: 50%;
        }
      }
    }
  }
}

.split-banner-image-title {
  @extend %spaced-bottom-big;
}

.separate-number {
  position: absolute;
  top: 10px;
  left: 10px;
  width: rem(40px);
  height: rem(40px);
  font-weight: 600;
  color: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: rem(20px);
  .number {
    display: block;
    height: rem(28px);
    line-height: 33px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
