@import "~wonderbly-css/functions/units";
@import "~wonderbly-css/functions/sizing";

.promo-banner-inline {
  margin: get-size("default") 0;

  .promo-banner-content {
    padding: rem(8px);
    text-align: center;
    box-shadow: rem(0) rem(1) rem(6) rem(-2) rgba(0, 0, 0, 0.75);

    .promo-banner-inline-title {
      margin: 0;
      font-size: rem(18);
    }
    .text-milli {
      font-weight: normal;
    }
  }

  .rich-text {
    p {
      font-size: rem(16px);
    }
    > * {
      margin: 0;
    }
  }
}
