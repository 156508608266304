@import "~wonderbly-css/base";

.Characters__Footer {
  &__Thumbnails {
    display: flex;
    margin-bottom: $spacer-md;
    &__Item {
      width: 72px;
      height: 72px;
      cursor: pointer;
      background-size: cover;
      background-position: center;
      position: relative;
      .icon {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: get-colour("green");
        color: #fff;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:not(:first-child) {
        margin-left: $spacer-std;
      }
      &:not(:last-child) {
        margin-right: $spacer-std;
      }
    }
  }
  &__Submit {
    text-align: right;
  }
  @include on("fablet-down") {
    margin-top: $spacer-md;
    &__Submit {
      .button {
        width: 100%;
      }
    }
  }
}
