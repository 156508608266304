@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/functions/palette";
@import "~chameleon/helpers/box";

.order-summary__link {
  width: 100%;
  &,
  &:hover,
  &:focus,
  &:active {
    color: get-colour("grey");
  }
}

.order-summary {
  position: relative;
  top: 0;
  background-color: #fff;
  box-shadow: 0 rem(2) rem(2) 0 rgba(0, 0, 0, 0.1);
  padding: rem(2);
  transition: box-shadow 0.1s ease-in, top 0.1s ease-in;
  &,
  .order-images {
    overflow: hidden;
  }
  .order-images {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .order-summary__content {
    padding: get-size("less") rem(18) rem(13);
    .order-status {
      margin-bottom: rem(8);
    }
    .order-delivery {
      @extend %spaced-bottom-tiny;
    }
  }
  .order-summary__link:hover & {
    box-shadow: 0 rem(3) rem(4) 0 rgba(0, 0, 0, 0.15);
    top: -2px;
  }
}
