@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/mixins/responsive";
@import "~chameleon/helpers/box";

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-5px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(5px, 0, 0);
  }
}

.discount-form {
  @extend %padded;
  @extend %padded-top-small-on-tablet-down;
  @extend %spaced-bottom-small;
  width: 100%;
  border-top: rem(1) solid get-colour("stone");

  > form {
    position: relative;
  }

  &.panel {
    background-color: transparent;
  }
  .discount-cta-button {
    white-space: nowrap;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-inline: 0.5rem;
  }
  .discount-form-description,
  .discount-form-success {
    @extend %spaced-top-none;
    color: $color-sharpie;
    & > *:first-child {
      @extend %spaced-top-none;
    }
    strong {
      font-weight: 600;
    }
  }
  .discount-form-success {
    display: inline-block;
  }
  @include on("tablet-up") {
    form {
      display: flex;
    }
    input[type="text"].discount-form-input {
      text-transform: uppercase;
    }
    input[type="text"]::placeholder {
      text-transform: capitalize;
    }
    .discount-cta-button {
      flex: 1;
    }
  }
}

.discount-form-input--error {
  animation: shake 1 0.7s linear;
}

.accordion {
  .discount-form {
    @extend %padded-top-small;
    @extend %padded-bottom-small;
  }

  .discount-form-description {
    > p {
      margin: 0;
    }
  }
}
