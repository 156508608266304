@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.canvas-title {
  @extend %spaced-top-big;
  font-weight: $font-weight-base;
  font-size: 1.5rem;
  text-align: center;
  padding: 0 $spacer-md;
}

.canvas-drawers-hold {
  position: relative;
  z-index: 3;
}

.canvas-overlay {
  background: $color-sharpie;
  opacity: 0;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  visibility: hidden;
  transition: visibility 0.3s ease 0.3s, opacity 0.3s ease;
  position: fixed;
  z-index: 2;
}

.canvas-overlay--visible {
  opacity: 0.8;
  transition-delay: 0s, 0s;
  visibility: visible;
}
