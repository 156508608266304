@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";
@import "~chameleon/helpers/visibility";

.shipping {
  @extend %spaced-top-more;
  @extend %spaced-bottom-more;
}

.shipping-panel.panel {
  @extend %padded;
  @extend %padded-top-more;
  @extend %spaced-bottom-big;
  background-image: linear-gradient(
    45deg,
    transparent 0px,
    transparent 10px,
    $color-ketchup 10px,
    $color-ketchup 20px,
    transparent 20px,
    transparent 30px,
    $color-paper-boat 30px,
    $color-paper-boat 40px,
    transparent 40px,
    transparent 50px
  );
  background-position: top center;
  background-repeat: repeat-x;
  border: 1px solid $color-sharpie-10;
  box-shadow: none;
  background-size: 60px 6px;

  select {
    &:not(:first-child) {
      @extend %spaced-top-small;
    }
  }

  .shipping-address select {
    @extend %spaced-top-none;
  }
}

.shipping-title {
  @extend %spaced-bottom;
  @extend %text-mega;
  text-align: center;

  #cart & {
    @extend %hidden-on-tablet-down;
  }
}

.shipping-col:not(:last-child) {
  @extend %spaced-bottom-on-fablet-down;

  @include on("fablet-down") {
    border-bottom: solid 1px get-colour("stone");
  }

  @include on("tablet-up") {
    border-right: solid 1px get-colour("stone");
  }
}

.shipping-estimated,
.shipping-country,
.shipping-options,
.shipping-address,
.shipping-cost {
  @extend %spaced-right-on-tablet-up;

  .loading {
    margin: 1rem auto;
  }
}

.shipping-country {
  @extend %padded-bottom-on-fablet-down;
}

.shipping-address {
  .address-line {
    display: block;
  }

  .shipping-address-form {
    @extend %spaced-top;
    @extend %spaced-bottom;
  }

  button {
    @extend %spaced-top;
  }
}

.shipping-ii {
  .row {
    @include on("tablet-up") {
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        flex-basis: 33.33%;
      }
    }
  }

  .shipping-options {
    @extend %padded-top-small-on-fablet-down;
    @extend %padded-bottom-small-on-fablet-down;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &:first-child {
      @include on("fablet-down") {
        border-top: rem(1) solid get-colour("stone");
        border-bottom: rem(1) solid get-colour("stone");
      }
    }

    h3 {
      @extend %spaced-right-small;
    }

    p {
      margin: get-size("tiny") 0;
    }
  }

  .shipping-panel.panel {
    @extend %padded-top-default;
    @extend %padded-bottom-less;
    @extend %spaced-none;
  }

  .shipping-country-wrapper {
    @extend %spaced-top-less-on-fablet-down;
    @include on("tablet-up") {
      border-right: rem(1) solid get-colour("stone");
      border-left: rem(1) solid get-colour("stone");
    }
  }
}
