@import "~wonderbly-css/base";

.ProductHeroII {
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: $spacer-md;
  padding: $spacer-md $page-padding-mobile;

  @include on("fablet-up") {
    max-width: $page-max-width-fablet;
    margin: 0 auto;
  }

  .Carousel__thumbs {
    display: none;

    @include on("desktop-up") {
      display: block;
    }
  }

  &__carousel {
    margin-left: -$page-padding-mobile;
    margin-right: -$page-padding-mobile;
  }

  @include on("tablet-up") {
    max-width: none;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, auto) 1fr;
    grid-column-gap: $spacer-md;
    grid-row-gap: $spacer-md;
    max-width: $page-max-width;
    padding: rem(48) $page-padding-desktop;
    margin: 0 auto;

    &__carousel {
      margin-left: 0;
      margin-right: 0;
      grid-row: 1 / -1;
      grid-column: 1 / 7;
    }

    &__header,
    &__body,
    &__button-wrap,
    &__price,
    &__paypal,
    &__klarna,
    &__accordion,
    &__tags,
    &__review {
      grid-column: 8 / -2;
    }
  }

  &__button {
    width: 100%;
  }

  &__paypal {
    min-height: rem(42);
    @include on("fablet") {
      min-height: rem(29);
    }
  }

  &__price {
    padding: $spacer-std 0;
  }

  &__title {
    @extend %text-tera;
    padding-bottom: $spacer-std;

    @include on("tablet-up") {
      padding-bottom: 0;
    }
  }

  &__subtitle-wrap--inline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include on("tablet-up") {
      display: block;
      justify-content: initial;
      align-items: initial;
    }
  }

  &__subtitle {
    margin: 0 $spacer-std 0 0;

    @include on("tablet-up") {
      display: none;
    }
  }

  &__star-rating {
    margin-top: $spacer-sm;

    @include on("tablet-up") {
      margin-top: $spacer-std;
    }
  }

  &__body {
    p,
    ul {
      margin: 0;

      & + * {
        margin-top: $spacer-std;
      }
    }
  }

  &__prod-info > *:not(:last-child) {
    margin-bottom: $spacer-lg;
  }

  &__accordion {
    padding-bottom: $spacer-std;

    .rich-text {
      padding: $spacer-md 0;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        padding-left: $spacer-md;
        position: relative;

        &:not(last-child) {
          padding-bottom: $spacer-sm;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0.45rem;
          left: 0;
          height: $spacer-std;
          width: $spacer-std;
          border-radius: $spacer-std / 2;
          background: $text-color-heading;
        }
      }
    }

    ul,
    p {
      margin: 0;

      & + * {
        margin-top: $spacer-std;
      }
    }
  }

  &__button-wrap .FieldTemplate--select {
    margin-bottom: $spacer-md;
  }

  &__review {
    background: #f8fafb;
    padding: $spacer-md;
    order: 10;
  }

  &__widget {
    display: flex;
    color: $text-color-heading;

    &--is-stacked-mobile {
      @media screen and (max-width: 350px) {
        flex-flow: column;
        align-items: center;

        .ProductHeroII__tpwidget {
          margin: $spacer-sm 0 0 0;
        }
      }
    }
  }

  &__tpwidget {
    margin: rem(2) 0 0;
  }
}
