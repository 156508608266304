@import "~wonderbly-css/functions/palette";
@import "~chameleon/helpers/box";

.quick-add-or {
  @extend %spaced-top-small;
  @extend %spaced-bottom-small;
  text-align: center;
}

.quick-add-cta {
  @extend %spaced-top-small;
  &.button--grey-medium:active,
  &.button--grey-medium:focus,
  &.button--grey-medium:hover {
    background-color: get-colour("grey");
  }
}

.quick-add-form {
  display: flex;
  flex-direction: column;
}
