@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/config/animations";

.product-information-wrapper {
  @include on("desktop-up") {
    width: 1000px;
    margin: 0 auto;
  }
}

.product-information-slider {
  .product-information-wrapper {
    @include on("tablet-up") {
      display: flex;
      flex-direction: row-reverse;
      & > div {
        width: 50%;
      }
    }
  }

  // Slider
  .product-information-carousel-wrapper {
    @include on("fablet-down") {
      overflow: hidden;
      margin: 0 -20px;
    }
  }
  .carousel-product-information {
    @include on("tablet-up") {
      overflow: hidden;
      width: 50vw;
      & > div {
        max-width: rem(700);
      }
    }
  }
  .product-information-carousel-slide {
    float: left;
    img {
      width: 100%;
      float: left;
    }
  }

  // Content
  .product-information-content-wrapper {
    padding: get-size("default") 0;
    @include on("tablet-up") {
      padding-right: get-size("default");
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include on("desktop-up") {
      padding-right: get-size("big");
    }
  }
  .product-information-content-slide {
    display: none;
    .rich-text {
      margin-top: 0;
    }
    &.active {
      display: block;
    }
  }

  &.product-information-slider--reversed {
    .product-information-wrapper {
      @include on("tablet-up") {
        flex-direction: row;
      }
    }

    .carousel-product-information {
      @include on("tablet-up") {
        float: right;
        & > div {
          float: right;
        }
      }
    }

    .product-information-content-wrapper {
      @include on("desktop-up") {
        padding-right: 0;
        padding-left: get-size("big");
      }
    }
  }
}
