@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/palette";
@import "~chameleon/helpers/box";

.multi-adventurer-arrow {
  @extend %spaced-left-small;
  width: 32%;
  max-width: rem(290px);
  @include on("fablet-down") {
    display: none;
  }
}

.multi-adventurer-modal {
  background: #f5f5f0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .hero-form {
    margin-bottom: rem(80);
    & > .rows-spaced {
      &:after,
      & > .hero-form-row:after {
        @extend %spaced-bottom-none;
      }
      & > .hero-form-row {
        @extend %spaced-none;
        @extend %padded-none;
        flex-direction: column;

        .hero-form-field,
        .hero-form-cta-button {
          @extend %spaced-none;
          width: 100%;
          // Had to use !important here because the design system was using !important
          padding: get-size("small") get-size("default") !important;
        }

        .hero-form-field {
          &.name {
            background-color: $color-kraft-paper-60;
            border-bottom: rem(1px) solid $color-sharpie-10;
          }
          &.adventurer {
            background-color: $color-bright-smile;
          }
        }

        .hero-form-cta-button {
          background-color: $color-kraft-paper-60;
          border-top: rem(1px) solid $color-sharpie-10;
        }

        .inline-label .inline-label__tag {
          position: relative;
        }

        .adventurer-phototypes .tooltip--visible {
          position: relative;
          max-width: 100%;
          left: 0;
          transform: none;

          &:after {
            display: none;
          }

          .panel--highlight-grass-ceiling {
            @extend %padded-none;
            background-image: none;
          }
        }
      }
    }
  }
}

.multi-adventurer-panel {
  @extend %padded-top-default;
  @extend %spaced-top-default;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  &.panel {
    background: transparent;
    overflow: visible;
  }
  &:before {
    content: "";
    width: 100vw;
    display: block;
    height: 1px;
    background-color: $color-kraft-paper-60;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: rem(-20px);
  }
  .multi-adventurer-panel__header {
    padding: get-size("small") get-size("default");
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dbd8cc;
    background-color: #fff;
    .multi-adventurer-panel__close {
      font-size: rem(14px);
      padding: 0;
    }
    .multi-adventurer-panel__title {
      position: relative;
    }
  }
  @include on("fablet-down") {
    height: 100vh;
    padding-top: 0;
    margin-top: 0;
    &.panel {
      background: #f5f5f0;
    }
    &:before {
      display: none;
    }
    .multi-adventurer-panel__content {
      overflow-y: auto;
    }
    .hero-form {
      .hero-form-row:first-child {
        .hero-form-field:first-child {
          .inline-label {
            margin-top: 0;
          }
        }
      }
    }
  }
}
