@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.canvas-drawer-label {
  position: relative;
  overflow: hidden;
  padding: rem(22) rem(24) rem(22) rem(20);
  text-align: left;
  transition: 0.3s ease;
  color: $color-racing-green;
  cursor: pointer;
  text-transform: none;

  @include on("tablet-down") {
    border-bottom: 1px solid $color-sharpie-10;
    padding: rem(16) rem(20);
    width: 100%;
  }

  @include on("desktop-up") {
    border-right: 1px solid $color-sharpie-10;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
    color: $color-racing-green;
    text-decoration: none;
  }

  .complete-tick {
    @extend %spaced-right-small;
    line-height: 1.5;
    width: rem(20);
    height: rem(20);
    padding: rem(4);
    .Icon--legacy {
      vertical-align: top;
    }
  }
}

.canvas-drawer-label--open {
  background: $color-kraft-paper-60;
  box-shadow: 0 -1px $color-kraft-paper-60;
}

.canvas-drawer-label--hidden {
  display: none;
}

.canvas-drawer-label-subtitle {
  font-weight: $font-weight-bold;
}

.canvas-drawer-label-title-wrap {
  display: flex;
  padding-top: $spacer-std;
  align-items: center;
}

.canvas-drawer-title {
  @extend %spaced-top;
  @extend %spaced-bottom;

  strong {
    color: $color-racing-green;
  }
}

.canvas-drawer-title-main {
  color: $color-sharpie;
}

.canvas-drawer-title-description {
  @extend %spaced-top-tiny;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.remove-canvas-drawer {
  color: $color-racing-green;
  cursor: pointer;
}

.canvas-drawer-label-title--completed {
  color: $color-sharpie;
}

.canvas-drawer-label-title {
  display: inline-block;
  vertical-align: middle;
  color: $color-sharpie;

  .complete-tick--ticked ~ & {
    color: $color-sharpie;
  }
}

.canvas-drawer-label-subtitle {
  @extend %text-milli;
  @extend %spaced-right-small;
  color: $color-sharpie;
  display: inline-block;
  vertical-align: middle;
  margin-right: rem(5);
}

.canvas-drawer-label-chevron {
  display: inline-block;
  transition: 0.3s ease;
  width: 9px;
  height: 6px;
  position: relative;
  top: -2px;
  background-image: url(img/canvas-label-chevron.svg);

  @include on("tablet-down") {
    position: absolute;
    right: rem(16);
    top: 50%;
    margin-top: rem(-3);
    transform: scale(1.5) rotate(90deg);
  }

  @include on("desktop-up") {
    .canvas-drawer-label--open & {
      transform: rotate(180deg);
    }
  }
}
