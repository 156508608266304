@import "~wonderbly-css/base";

.SliceError {
  width: calc(100% - #{$spacer-xl});
  max-width: 600px;
  padding: $spacer-xl;
  text-align: center;
  background-color: #fff;
  border: $spacer-sm solid get-colour("red");
  box-shadow: 0px $spacer-std rem(20) rgba(107, 122, 135, 0.15),
    0px rem(2) rem(2) rgba(107, 122, 135, 0.12);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  h4 {
    margin-bottom: $spacer-std;
  }
  p {
    @extend %text-mega;
    color: get-colour("red");
    margin: 0;
    strong {
      font-size: rem(18);

      @include on("tablet-up") {
        font-size: rem(20);
      }
    }
  }
  .button {
    margin-top: $spacer-md;
  }
  &--hidden,
  &:not(.SliceError--hidden) ~ .SliceError {
    display: none;
  }
}
