@import "~wonderbly-css/base";

.FAQs {
  margin: 0 rem(15);
  ul {
    padding-left: rem(18);
  }
  @include on("tablet-up") {
    &__wrapper {
      margin: 0 auto;
    }
  }
}
