@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: get-size("small");

  .breadcrumbs__breadcrumb {
    white-space: nowrap;

    &:last-of-type {
      font-weight: $font-weight-bold;
    }

    a {
      color: $color-sharpie;
      text-decoration: underline;
    }

    .breadcrumbs__breadcrumb__separator {
      color: $color-sharpie;
      margin-left: get-size("tiny");
      margin-right: get-size("small");
    }
  }
}
