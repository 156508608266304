@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.family-members-cta-row {
  @extend %spaced-top-big;

  .family-members-additional-field-wrapper {
    display: flex;
  }

  .family-members-additional-field.col-1-2,
  .family-members-additional-cta.col-1-2 {
    @include on("tablet-down") {
      width: 100%;
    }
  }

  .Button {
    @extend %widest-on-fablet-down;
  }

  .family-members-cta-button {
    @extend %widest-on-fablet-down;
    @extend %spaced-top-small-on-fablet-down;
    text-align: right;
    float: right;
  }
}

.family-members-field {
  @extend %spaced-bottom-small;
  position: relative;

  .Button {
    color: get-colour("white");
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 50%;
    line-height: rem(32);
    text-align: center;
    cursor: pointer;
    z-index: 1;

    &:active {
      outline: none;
    }
  }
}
