@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.promo-banner {
  @extend %text-milli;
  position: relative;
  z-index: 6;
  transition: background-color 0.4s ease-out;

  p {
    display: inline-block;
    margin: 0;
    line-height: rem(30);
    width: 100%;
    @include on("fablet-down") {
      line-height: rem(20);
    }
  }

  strong {
    font-weight: 700;
  }

  &--desktop-only {
    display: none;

    @include on("tablet-up") {
      display: inline;
    }
  }

  .highlight {
    @extend %rounded;
    @extend %spaced-bottom-tiny;
    @extend %spaced-left-tiny;
    @extend %spaced-right-tiny;
    padding: get-size("tiny") get-size("small");
    padding-bottom: rem(2);
    font-weight: 700;
    display: inline-block;
    width: auto;
  }

  .promo-banner-supporting {
    @extend %spaced-left-tiny-on-tablet-up;
    display: none;
    @include on("tablet-up") {
      display: inline-block;
    }
  }

  .promo-banner-countdown {
    @extend %spaced-top-tiny-on-fablet-up;
    @include on("mobile-down") {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.promo-banner-inner {
  @extend %padded-top-tiny;
  @extend %padded-bottom-tiny;
  font-family: $font-family-sans-serif;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  @include on("fablet-down") {
    text-align: center;
  }

  & > span {
    @include on("fablet-down") {
      width: 100%;
    }
  }

  p:first-child {
    margin-top: 0;
  }
}

.promo-banner-trustpilot-micro {
  @extend %rounded;
  align-self: center;
  margin-left: auto;
  padding: rem(6px) get-size("tiny") rem(3px);
  @include on("fablet-down") {
    display: none;
  }
}

.product-added-banner {
  position: absolute;
  width: 100%;
  transition: top 0.5s ease-in;

  .promo-banner-inner {
    .ss-icon {
      @extend %spaced-bottom-tiny;
      @extend %spaced-right-tiny;
    }
  }

  .promo-banner-cta {
    .button {
      font-size: rem(14);
      padding: rem(3) get-size("default");
    }
  }
}
