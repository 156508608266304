@import "~wonderbly-css/base";

.SaveForLater__cta {
  margin-bottom: $spacer-std;

  span {
    margin-right: $spacer-sm;
  }

  @include on("tablet-up") {
    margin: 0 $spacer-std 0 0;
  }
}

.SaveForLaterModal {
  padding: $spacer-std;

  &__inner {
    background: white;
    max-width: rem(420);
    width: 100%;
    padding: $spacer-md $spacer-md $spacer-lg;
    text-align: center;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacer-std;
  }

  &__title {
    @extend %text-giga;
  }

  .FieldTemplate:not(.FieldTemplate--submit) {
    text-align: left;
  }
  .FieldTemplate--submit {
    margin-top: $spacer-md;
  }
}
