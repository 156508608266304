@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.multi-input-cta-row {
  @extend %spaced-top-big;

  .multi-input-additional-field-wrapper {
    display: flex;
  }

  .multi-input-additional-field.col-1-2,
  .multi-input-additional-cta.col-1-2 {
    @include on("tablet-down") {
      width: 100%;
    }
  }

  .button {
    @extend %widest-on-fablet-down;
  }

  .multi-input-cta-button {
    @extend %widest-on-fablet-down;
    @extend %spaced-top-small-on-fablet-down;
    text-align: right;
  }
}

.multi-input__field {
  @extend %spaced-bottom-small;
  position: relative;

  .button {
    @extend %circled;
    color: get-colour("white");
    background-color: get-colour("red");
    padding: 0;
    margin: -#{$spacer-md} 0 0;
    border: 0;
    height: rem(32);
    width: rem(32);
    line-height: rem(32);
    text-align: center;
    position: absolute;
    top: 50%;
    right: 0.5rem;
    cursor: pointer;
    z-index: 1;

    .ss-icon {
      top: rem(2);
    }

    &:hover,
    &:focus {
      outline: none;
      background-color: get-colour("red-dark");
    }

    &:active {
      outline: none;
      background: get-colour("almost-black");
    }
  }
}
