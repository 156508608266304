@import "~wonderbly-css/base";

.CanvasDrawerPrompt {
  padding: $spacer-std;

  &__inner {
    background: white;
    max-width: rem(420);
    width: 100%;
    padding: $spacer-md $spacer-md $spacer-lg;
    text-align: center;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacer-std;
  }

  &__title {
    @extend %text-giga;
  }

  &__description {
    ul {
      padding: 0;
      list-style: none;

      li {
        margin-bottom: $spacer-sm;
      }
    }
  }

  &__helper {
    margin: $spacer-sm 0;
  }
}
