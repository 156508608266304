@import "~wonderbly-css/base";

.NavCountryOption {
  color: $color-sharpie;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  line-height: rem(30);
  width: 100%;
  transition: 0.25s ease;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $color-racing-green;
    outline: none;
  }

  &:active {
    color: $color-sharpie;
    outline: none;
  }

  @include on("tablet-down") {
    padding: $spacer-sm 0;
  }

  &--active {
    color: get-colour("almost-black");
  }

  &__image {
    display: inline-block;
    vertical-align: middle;
    margin-right: $spacer-std;
  }

  &__text {
    display: inline-block;
  }
}
