@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~mixins";
@import "~chameleon/helpers/box";

.checkout-line-item-wrapper {
  @include unstyle-button;
  cursor: default;
  display: flex;
  font-size: rem(14);
  width: 100%;
  min-height: rem(63);
  &.checkout-line-item-wrapper--editable {
    cursor: pointer;
  }

  .line-item-ii-image-wrapper {
    width: rem(80);
  }

  .checkout-line-item-content {
    @extend %spaced-right-small;
    @extend %spaced-left-small;
    text-align: left;

    .checkout-line-item-type {
      color: $color-sharpie-60;
      font-size: 0.875rem;
    }

    .pricing {
      font-weight: 600;
    }
  }

  .checkout-line-item-title {
    font-family: $font-family-heading;

    span {
      font-weight: normal;
    }
  }

  .checkout-line-item-price {
    @extend %font-style-display;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: right;
    align-self: normal;
    font-weight: $font-weight-semi;
  }

  .pricing {
    color: get-colour("green");
    margin-top: auto;
    align-self: flex-end;

    @include on("fablet-and-tablet") {
      margin-top: get-size("less");
    }
  }

  .checkout-line-item-edit {
    text-align: right;

    span {
      background-color: get-colour("green");
      color: white;
      padding: rem(3) rem(5);
      font-size: rem(12);
    }
  }
}

.checkout-order-summary-modal {
  padding: get-size("less");
  background-color: #fff;
  max-width: rem(350);
  text-align: center;

  hr {
    @include line-break(rem(-15));
  }

  .checkout-order-summary-modal-title {
    font-size: rem(20);
    position: relative;

    button {
      @extend %padded-none;
      @include unstyle-button;
      position: absolute;
      right: 0;
    }
  }
}
