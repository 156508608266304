@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.Bundle__Product {
  padding: get-size("less");
  display: flex;
  align-items: center;
  @extend %padded-on-tablet-up;

  &__image {
    max-width: rem(100);

    @include on("fablet-up") {
      max-width: rem(160);
    }
  }

  &__title {
    @extend %text-mega;
  }

  &__description {
    margin: $spacer-std 0 0.5rem;
    display: none;

    @include on("fablet-up") {
      display: block;
    }
  }

  .button {
    margin-top: $spacer-std;

    @include on("fablet-up") {
      margin-top: $spacer-md;
    }
  }
}
