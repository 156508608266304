@import "~wonderbly-css/base";

.LinksNav {
  padding: 0;
  margin-block: 0.5rem;
  list-style: none;

  > * + * {
    margin-top: 0.25rem;
  }

  &__link {
    color: white;
    font-size: 0.875rem;

    &:hover,
    &:active {
      color: $color-kraft-paper;
    }
  }
}
