@import "~wonderbly-css/base";

.ShopNavigationMobile {
  display: block;
  height: 100%;
  padding: 2rem 0;

  &__nav-item {
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top: 1px solid rgba(#000, 0.3);

    &:first-child {
      border-top: none;
    }
  }

  & > li {
    list-style: none;
  }

  &__accordion-button {
    color: $color-bright-smile;
    background-color: $color-blueberry;
    cursor: pointer;
    padding: rem(20);
    width: 100%;
    text-align: left;
    outline: none;
    transition: 0.4s;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    @extend %text-giga;
    font-family: $font-family-sans-serif;
    color: $color-bright-smile;
  }

  &__nav-link,
  &__nav-link:hover {
    text-decoration: none;
    color: $color-bright-smile;
  }

  &__primary-list {
    padding: 0;
  }

  & > .SectionHeading > .container-guttered > .SectionHeading__heading {
    @extend %text-peta;
    color: $color-bright-smile;
  }

  &__nav-container {
    display: none;
    overflow: hidden;
    transition: all 2.5s ease-out;

    &--active {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: rem(16);
      padding: 0 rem(16) rem(24);
    }
  }

  &__secondary-list {
    font-family: $font-family-sans-serif;
    font-size: rem(16);
    padding: 0;
    list-style-type: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: rem(16);

    & > li {
      border: 1px solid rgba($color-paper-boat, 0.2);
      border-radius: 2px;
      background-color: rgba($color-paper-boat, 0.2);
      padding: 0 rem(12);
      height: rem(56);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
