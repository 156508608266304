@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "../Tags/Tags.scss";
@import "~chameleon/helpers/box";

.product-hero {
  background-image: url("img/product-hero.jpg");
  position: relative;
  float: left;
  width: 100%;
  @include on("tablet-up") {
    padding: get-size("default") 0;
  }
  img {
    user-select: none;
  }
}

.product-hero-wrapper {
  position: relative;
  @include on("desktop-up") {
    width: 1000px;
    margin: 0 auto;
  }
}

.product-hero-carousel-wrapper,
.product-hero-content-wrapper {
  @include on("tablet-up") {
    width: 50%;
    float: left;
  }
}

.product-hero-carousel-wrapper {
  overflow: hidden;
  .carousel-item-wrap {
    overflow: hidden;
    background: #fff;
    @include on("tablet-up") {
      border: rem(8px) solid #fff;
    }
  }
  @include on("fablet-down") {
    margin: 0 rem(-20px);
  }
  @include on("desktop-up") {
    width: 66.66%;
  }
}
.product-hero-video-slide,
.product-hero-image-slide {
  float: left;
  img {
    width: 100%;
    pointer-events: none;
    float: left;
  }
}

.product-hero-tagline {
  @include tags-style;
  color: #fff;
  background-color: get-colour("green");
}

.product-hero-content-wrapper {
  @extend %padded-top;
  @extend %padded-bottom;
  @extend %padded-left-on-tablet-up;
  @include on("desktop-up") {
    width: 33.33%;
  }
}
.product-hero-title {
  @extend %spaced-bottom-small;
}
.product-hero-price {
  @extend %spaced-bottom-tiny;
  @extend %font-style-display;
  color: get-colour("red");
  align-items: center;
  &.pricing--slash {
    width: 100%;
    .saving-price {
      top: 0;
    }
  }
}

.product-hero-description {
  @extend %spaced-top-small;
  p:first-child {
    @extend %spaced-top-none;
  }
  p:last-child {
    @extend %spaced-bottom-none;
  }
}

.product-hero-review {
  .trustpilot-icon {
    @extend %spaced-left-tiny;
    max-width: rem(70);
    vertical-align: middle;
    margin-bottom: rem(3);
  }

  a.text-micro {
    color: get-colour("grey-medium");
    text-decoration: underline;
  }

  .rich-text {
    @extend %spaced-top-small;
    @extend %spaced-bottom-small;

    p {
      margin: 0;
    }
  }
  p:last-child {
    @extend %spaced-bottom-none;
  }
}

.product-hero-rating-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-hero-review-quote--padding {
  @extend %padded-small;
}

.product-hero-trustpilot-productmini {
  @extend %spaced-top;
}

.product-hero-trustpilot {
  @extend %spaced-left;
  @extend %spaced-right-small;
  display: inline-block;
  p {
    margin: 0;
  }
}

.product-hero-add-to-cart {
  @extend %spaced-top;
}
