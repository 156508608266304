@import "~wonderbly-css/base";

.DesktopExpandedNav {
  display: none;
  z-index: -1;

  @include on("desktop-up") {
    display: block;
    max-width: rem(1400);
    margin: 0 auto;
    background: $color-bright-smile;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  &__menu-container {
    display: flex;
    column-gap: rem(120);
    max-width: 81.5rem;
    margin: auto;
    padding-block: $spacer-xxl;
    padding-inline: $spacer-md;
    overflow-x: hidden;

    @include on("desktop-up") {
      height: rem(450);
    }
  }

  &__dropdown {
    list-style-type: none;
  }

  // push promos to far right by moving first promo item
  &__first-promo {
    margin-left: auto;
  }
}
