@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.offers-list {
  .offer-panel {
    margin-right: get-size("small");
    margin-bottom: get-size("less");
  }

  .offer-image {
    height: rem(155);
  }
}

.offer-list-wrapper {
  @extend %spaced-top-default;
  @extend %spaced-bottom-default;
}

.offers-carousel__title {
  display: flex;
  justify-content: space-between;

  p {
    @extend %spaced-top-none;
    margin-bottom: get-size("small");
  }

  a {
    text-decoration: underline;
  }
}
