@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/config/animations";

$modifier: 1.5;
$sprite-height: (1053px / $modifier);
$sprite-width: (269px / $modifier);

.product-hero.product-hero--the-birthday-thief {
  background-color: #f7efec;
  background-image: none;
  [class*="product-hero-decoration-"] {
    background-image: url("img/product-hero-tbt.png");
    background-repeat: no-repeat;
    background-size: $sprite-width $sprite-height;
    display: none;
    position: absolute;
    pointer-events: none;

    @include on("wide-up") {
      display: block;
    }
  }

  .product-hero-decoration-tr {
    right: 0;
    top: 0;
    background-position: 0 0;
    height: (251px / $modifier);
    width: (263px / $modifier);
  }

  .product-hero-decoration-br {
    right: 0;
    bottom: 0;
    background-position: 0 (-251px / $modifier);
    height: (229px / $modifier);
    width: (269px / $modifier);
  }

  .product-hero-decoration-tl {
    left: 0;
    top: 0;
    background-position: 0 (-480px / $modifier);
    height: (419px / $modifier);
    width: (199px / $modifier);
  }

  .product-hero-decoration-bl {
    left: 0;
    bottom: 0;
    background-position: 0 (-899px / $modifier);
    height: (154px / $modifier);
    width: (195px / $modifier);
  }

  .product-hero-price {
    color: get-colour("red");
  }
}
