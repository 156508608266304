@import "~wonderbly-css/base";

$spacing: rem(22);

.CheckoutShipping {
  background-color: #fff;
  .loading {
    margin: $spacer-md 0;
  }
  &__header {
    padding: $spacing;
    border-bottom: rem(1) solid #d3cec1;
    h3 {
      @extend %text-giga;
    }
  }
  &__main {
    padding: $spacer-xl $spacing 0;
    h4 {
      @extend %text-mega;
      margin-top: $spacer-lg;
      margin-bottom: $spacer-md;
    }
    .js & .tooltip--alert.tooltip--address-lookup:not(.tooltip--absolute) {
      margin-bottom: $spacer-md;
    }
  }
  &__form {
    display: inline-block;
    width: 100%;
    &__group {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-bottom: $spacer-std;
      }
      @include on("tablet-up") {
        flex-direction: row;
        margin: 0 -#{$spacer-std};
        & > * {
          width: calc(50% - #{$spacer-md});
          margin: 0 $spacer-std;
        }
        &:not(:last-child) {
          margin-bottom: 0;
          .FieldTemplate:last-child {
            margin-bottom: $spacer-std;
          }
        }
      }
    }
    .FieldTemplate--checkbox,
    .FieldTemplate--radio {
      margin-top: $spacer-md;
      &,
      & .CheckboxField__text {
        font-size: $spacer-md;
      }
    }
  }
  &__address-lookup {
    &__seperator {
      position: relative;
      margin-top: -#{$spacer-std};
      padding: rem(14) 0;
      text-align: center;
      span {
        background-color: #fff;
        display: inline-block;
        padding: 0 $spacer-std;
        position: relative;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        border-bottom: rem(1) solid get-colour("stone-light");
        width: 100%;
      }
    }
    &__query {
      position: relative;
    }
    &__list {
      position: absolute;
      width: 100%;
      max-height: rem(240);
      background-color: #fff;
      border: rem(2) solid get-colour("stone");
      margin-top: -#{rem(10)};
      box-shadow: 0px rem(2) $spacer-sm rgba(9, 33, 55, 0.3);
      overflow-y: auto;
      z-index: 5;
      &__item {
        padding: $spacer-std;
        cursor: pointer;
        &:hover {
          background-color: get-colour("almost-light");
        }
      }
    }
  }
  &__footer {
    padding: $spacer-xl $spacing;
    p:last-child {
      margin-bottom: 0;
    }
  }
}
