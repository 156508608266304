@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";
@import "~mixins";

#offers {
  background-color: transparent;
}

.intro-partial {
  @extend %spaced-top-default;
  @extend %spaced-bottom-default;

  &__title {
    @extend %font-style-heading;
  }

  .panel {
    @extend %padded-default;

    p {
      @extend %spaced-none;
    }
  }

  hr {
    @include line-break(rem(-20));
  }

  @include on("tablet-down") {
    text-align: center;
  }
}
