@import "~wonderbly-css/base";

.MobileExpandedNav {
  @include on("desktop-up") {
    display: none;
  }

  border-right: solid rem(0.5) $color-sharpie-30;
  background-color: $color-bright-smile;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  z-index: 9;
  position: fixed;
  height: 100%;
  width: calc(100% - 5rem);
  max-width: rem(400);

  &__dropdown {
    list-style-type: none;
  }

  &__title {
    color: $color-racing-green;
    padding: $spacer-lg $spacer-md;
  }

  &__button {
    border: none;
    display: flex;
    align-items: center;
    padding: $spacer-lg $spacer-md;
    border-bottom: solid rem(0.5) $color-sharpie-30;
    background-color: $color-kraft-paper;
    z-index: 2;
    text-align: center;
    position: sticky;
    top: 0;
    width: 100%;
  }

  &__arrow {
    color: $color-racing-green;
  }

  &__button-text {
    flex: 1;
  }
}
