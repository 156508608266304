@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";
@import "~chameleon/helpers/visibility";

.canvas-drawer-content {
  @extend %padded-top-on-desktop-up;
  @extend %padded-bottom-on-desktop-up;
  @extend %padded-bottom-big;
  display: none;
  background: get-colour("stone-light");
  visibility: hidden;

  @include on("desktop-up") {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    height: auto;
    border-top: 1px solid get-colour("stone");
  }
}

.canvas-drawer-content--open {
  visibility: visible;
  display: block;
}

.canvas-drawer-content--modal {
  background: #f5f5f0;
  overflow: auto;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.canvas-drawer-title-description {
  .rich-text {
    margin-top: 0;
  }
}
@include on("tablet-down") {
  .canvas-drawer-content--modal-fallback {
    left: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .canvas-drawer-field {
    margin-bottom: rem(50);
  }
}

.canvas-drawer-close {
  @extend %padded;
  @extend %block-on-tablet-down;
  @extend %hidden-on-desktop-up;

  background: get-colour("white");
  border-bottom: 1px solid get-colour("stone");

  .button {
    padding: 0;
  }
}

.button-cancel {
  @extend %spaced-right-small;
  @extend %spaced-bottom-small-on-tablet-down;
}

.canvas-drawer-content--bears {
  @include on("tablet-down") {
    overflow: hidden;
  }
}

.canvas-drawer-title-main,
.canvas-drawer-title-main > .prismic-rich-text {
  & > p:first-child {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
}

/**
 * Specific Tabs
 */

.canvas-drawer-content--bears {
  @extend %padded-left-none;
  @extend %padded-right-none;

  .canvas-drawer-title {
    @extend %padded-right-on-desktop-up;
    @extend %padded-left-on-desktop-up;
  }
}

.canvas-drawer-content .hero-form {
  @extend %padded-top;
}

.modal-canvas-drawer-content {
  height: 100vh;
}
