@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/units";
@import "~chameleon/helpers/box";

.trustpilot {
  padding: rem(50px) 0;
}

.trustpilot-page-section {
  padding: $spacer-lg 0;
  border-bottom: 1px solid $color-sharpie-10;
}

.trustpilot--flush {
  padding: 0;
}

.trustpilot-title {
  @extend %spaced-bottom-big-on-tablet-up;
}

.trustpilot-widget-centered {
  display: flex;
  justify-content: center;
}
