@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";

.country-prompt {
  background-color: get-colour("almost-black");
  padding: get-size("default") get-size("less");
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: rem(16);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
  @media only screen and (max-width: 830px) {
    flex-direction: column;
  }
}
.country-prompt-loading.loading.loading--relative {
  min-height: rem(22);
  .loading-indicator {
    width: rem(22);
    height: rem(22);
    border-width: rem(2);
    margin: 0 auto;
  }
}
.country-prompt-link {
  color: get-colour("almost-light");
  display: inline-flex;
  align-items: center;
  span {
    float: left;
  }
  &:hover {
    color: get-colour("green");
  }
  @media only screen and (max-width: 830px) {
    margin-right: 0;
  }
}
.country-prompt-text {
  margin: -8px 0 0;
  p {
    margin: 0;
  }
  @media only screen and (max-width: 830px) {
    width: 100%;
    margin-bottom: get-size("small");
    text-align: center;
  }
}
.country-prompt-flag {
  margin: 0 get-size("tiny");
  width: rem(22);
  height: rem(22);
  position: relative;
  top: rem(6);
}
.country-prompt-spacer {
  margin: 0 get-size("default");
  @media only screen and (max-width: 830px) {
    display: none;
  }
}
.country-prompt-close {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: get-colour("red");
  }
  @media only screen and (max-width: 830px) {
    margin-left: 0;
  }
}
