@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/config/animations";

$modifier: 1.5;
$sprite-height: (720px / $modifier);
$sprite-width: (305px / $modifier);

.product-hero.product-hero--my-little-monster-name {
  background: #e6efef;
  background-image: url("img/mlmn-hero-decoration-middle.jpg");
  background-repeat: repeat-x;
  [class*="product-hero-decoration-"] {
    background-repeat: no-repeat;
    background-size: $sprite-width $sprite-height;
    display: none;
    position: absolute;
    pointer-events: none;

    @include on("wide-up") {
      display: block;
    }
  }
  .product-hero-decoration-left {
    background-image: url("img/mlmn-hero-decoration-left.jpg");
    left: 15px;
    bottom: 0;
    height: ($sprite-height);
    width: ($sprite-width);
  }

  .product-hero-decoration-right {
    background-image: url("img/mlmn-hero-decoration-right.jpg");
    right: 15px;
    top: 0;
    height: ($sprite-height);
    width: ($sprite-width);
  }
  .product-hero-price {
    color: get-colour("red");
  }
}
