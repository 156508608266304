@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";

/*
 * ProductGrid
 *
 * 1. Lock to 16:9
 * 2. Use alpha to fade to 50% opacity
 */

.product-grid {
  margin-bottom: get-size("big");
  margin-top: get-size("big");
  &#{&}--no-margin-top {
    margin-top: 0;
  }
  &--showcase,
  &--marketing-only {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: $spacer-md;
    row-gap: $spacer-md;
    .product-grid-card {
      grid-column: span 12;
      &--extra-small {
        .card .card__main .card__images .card__image {
          padding-top: 56.25%;
        }
        @include on("tablet-up") {
          grid-column: span 6;
        }
        @include on("desktop-up") {
          grid-column: span 3;
        }
      }
      &--small {
        .card .card__main .card__images .card__image {
          padding-top: 56.25%;
        }
        @include on("tablet-up") {
          grid-column: span 6;
        }
        @include on("desktop-up") {
          grid-column: span 4;
        }
      }
      &--medium {
        .card .card__main .card__images .card__image {
          padding-top: 56.25%;
        }
        @include on("tablet-up") {
          grid-column: span 6;
        }
      }
      &--large {
        .card .card__main .card__images .card__image {
          padding-top: 56.25%;
        }
      }
    }
  }

  &-card > *,
  &__marketing-card {
    height: 100%;
  }

  &--condensed {
    display: grid;
    grid-auto-flow: dense;
    grid-template-rows: auto;
    column-gap: $spacer-md;
    row-gap: $spacer-md;
    margin-bottom: $spacer-xxl;
    margin-top: $spacer-xxl;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: $spacer-md;
  }
}

.product-grid__header {
  border-bottom: rem(2) solid rgba(get-colour("stone"), 0.5); /* 2 */
  margin-bottom: get-size("default");
  padding: get-size("less");
  text-align: center;
}

.product-grid__description {
  margin: 0 auto $spacer-xl;
  max-width: 400px;
  text-align: center;
  grid-column-start: span 2;
}

.product-grid-card--marketing {
  height: 100%;
  grid-column: span 2;
  @include on("tablet-up") {
    grid-column: auto;
  }

  .promo-code {
    display: inline-block;
    padding: 0.25rem 0.5rem 0.125rem;
    border-radius: rem(3);
  }
}
