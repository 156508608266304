@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";

.tab-nav {
  overflow: hidden;
  margin-bottom: get-size("default");
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      float: left;
      & + li {
        margin-left: get-size("default");
      }
    }
  }
}
.tab-nav-item {
  button {
    color: $color-racing-green;
    padding: get-size("small") 0;
    border: none;
    background-color: transparent;
    background-image: url("img/tab-nav-active.png");
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 0;
    outline: none;
    font-weight: 600;

    &:hover {
      background-size: 100% 2px;
    }
  }
  &.active {
    button {
      color: $color-sharpie;
      background-size: 100% 2px;
    }
  }
}
