@import "~wonderbly-css/base";

.PaypalCheckout {
  section + & {
    margin-top: $spacer-md;
  }
  .Button {
    margin-top: $spacer-md;

    &--isSubmitting {
      pointer-events: none;
    }
  }
  &__methods--disabled {
    pointer-events: none;
  }
  .MarketingOptIn__wrapper {
    margin-top: $spacer-md;
  }
}
