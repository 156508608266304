@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.order-status-card {
  @extend %spaced-bottom-default;
}

.order-status-card__inner {
  padding: get-size("default");
}

.order-status-card__buttons {
  display: flex;
  padding-bottom: get-size("default");
  padding-top: get-size("default");
}

.order-status-card__button {
  margin-left: get-size("small");
  margin-right: get-size("small");
  width: 100%;
  max-width: 33.3333%;
}

.order-status-card__button:first-of-type {
  margin-left: get-size("default");
}

.order-status-card__button:last-of-type {
  margin-left: get-size("default");
}

.order-status-card__status {
  margin-left: get-size("small");
}

.order-status-card__delivery {
  line-height: 1;
  margin-bottom: get-size("tiny");
}
