@import "heidelberg-plugin";

.Heidelberg-Book {
  position: absolute;
  left: 0;
  transition: left 0.7s;
  top: 0;
  width: 100%;
  height: 100%;

  &.at-front-cover {
    left: -25%;
  }

  &.at-rear-cover {
    left: 25%;
  }
}

.Heidelberg-Page {
  user-select: none;
  background-color: white;
  transition: none;
  height: 100%;
  overflow: visible;

  @supports (transition: transform 0.9s ease) and (not (-ms-ime-align: auto)) {
    transition: transform 0.9s ease;
  }

  &:not(:first-child):not(:last-child)::before {
    content: "";
    position: absolute;
    z-index: 3;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }

  &.with-Spread {
    &::before {
      display: none;
    }
  }

  &:nth-child(even) {
    &.is-active:hover {
      transform: rotateY(5deg);
    }

    &::before {
      background-image: url("img/heidelberg-book-nth-child-even.png");
    }
  }

  &:nth-child(odd) {
    &.is-active:hover {
      transform: rotateY(-5deg);
    }

    &::before {
      background-image: url("img/heidelberg-book-nth-child-odd.png");
    }
  }

  &.is-active:not(:hover) {
    transform: rotateY(0deg);
  }
}

.Heidelberg-Spread {
  height: 100%;
}

.heidelberg-book-image {
  position: relative;
  z-index: 2;
  height: auto;
  width: 100%;
  display: block;
  pointer-events: none;
}

.ss-loading {
  .Heidelberg-Page & {
    font-size: 2rem;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    animation: spin 2s linear infinite;
  }

  .with-Spread & {
    width: 50%;
  }

  .with-Spread:nth-child(odd) & {
    right: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
