@import "~wonderbly-css/base";

.Characters__List {
  &__Error {
    @at-root .js .tooltip--alert:not(.tooltip--absolute)#{&} {
      margin: $spacer-md 0 0;
    }
  }

  &__Item {
    display: flex;
    position: relative;
    width: 100%;
    padding: $spacer-md;
    flex-shrink: 0;
    background-color: $color-bright-smile;
    box-shadow: 0 2px 2px rgba(107, 122, 135, 0.25);

    &__Image {
      width: 127px;
      margin: -#{$spacer-md};
      margin-right: $spacer-md;
      background-position: center;
      background-size: cover;
    }

    &__Content {
      width: 100%;

      label {
        display: inline-block;
        margin-bottom: $spacer-sm;
      }
    }

    &__Delete {
      &.UnstyledButton {
        background-color: $color-sharpie;
        color: $color-bright-smile;
        border-radius: 50%;
      }

      &,
      &:hover,
      &:focus {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ss-delete {
          width: 12px;
          height: 12px;
          color: $color-bright-smile;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &--disabled {
        &,
        &:hover,
        &:focus {
          background-color: $color-sharpie-60;
        }
      }
    }

    &__Error {
      padding: $spacer-std $spacer-md;
      background-color: $color-ketchup;
      color: $color-bright-smile;
      text-align: center;
    }

    &--add-new {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      height: 100%;
      max-height: 210px;
      border: 2px dashed $color-sharpie-10;
      cursor: pointer;
      background: transparent;
      box-shadow: none;
      color: $color-sharpie;

      .icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid;
        margin-right: $spacer-std;

        &:before,
        &:after {
          content: "";
          width: 12px;
          height: 2px;
          background-color: $color-sharpie;
          border-radius: 1px;
          position: absolute;
          // Position halfway down from parent minus half height
          top: calc(50% - 1px);
          // Position halfway left from parent minus half width
          left: calc(50% - 6px);
        }

        &:after {
          transform: rotate(90deg);
        }
      }
    }

    &__Wrapper {
      &:not(:last-child) {
        margin-bottom: $spacer-md;
      }
    }
  }

  @include on("tablet-up") {
    overflow: auto;
    padding: $spacer-md;

    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    &__Wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      width: $page-max-width;
      margin: 0 auto;
    }

    &__Item {
      width: 240px;
      flex-shrink: 0;
      flex-direction: column;

      &__Image {
        width: calc(100% + #{$spacer-xl});
        height: 104px;
        margin: -#{$spacer-md};
        margin-bottom: $spacer-md;
      }

      &--add-new {
        flex-direction: column;

        .icon {
          margin-right: 0;
          margin-bottom: $spacer-std;
        }
      }

      &__Wrapper {
        padding-right: $spacer-md;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }

  @include on("desktop-up") {
    padding: $spacer-md;
  }
}
