@import "~wonderbly-css/mixins/responsive";
@import "~chameleon/helpers/box";

.offers-carousel {
  @extend %spaced-top-default;
  @extend %spaced-bottom-default;

  .offer-panel {
    box-shadow: none;

    @include on("fablet-down") {
      display: flex;
    }
  }
}
