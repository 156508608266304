@import "~wonderbly-css/functions/units";
@import "~wonderbly-css/functions/palette";

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(get-colour("almost-black"), 0.02);
  z-index: 4;
  pointer-events: none;

  &.loading--relative {
    position: relative;
    min-width: rem(96);
    min-height: rem(96);
  }

  &.loading--absolute {
    position: absolute;
  }

  &.loading--relative.loading--small {
    min-width: rem(48);
    min-height: rem(48);
  }

  &.loading--relative.loading--xsmall {
    min-width: rem(24);
    min-height: rem(24);
  }

  &.loading--dark {
    background: transparent;
  }

  &.loading--fade {
    background: rgba(255, 255, 255, 0.7);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: rem(-48) 0 0 rem(-48);
  border-radius: 50%;
  height: rem(96);
  width: rem(96);
  border-top: rem(10) solid rgba(white, 0.2);
  border-left: rem(10) solid rgba(white, 0.2);
  border-bottom: rem(10) solid rgba(white, 0.2);
  border-right: rem(10) solid rgba(white, 1);
  transform: translateZ(0);
  //will-change: transform;
  animation: spin 1s infinite linear;

  .loading--xsmall & {
    height: rem(24);
    width: rem(25);
    border-width: rem(2.5);
  }

  .loading--small & {
    height: rem(48);
    width: rem(48);
    border-width: rem(5);
  }

  .loading--dark & {
    border-top-color: rgba(get-colour("grey-light"), 0.2);
    border-left-color: rgba(get-colour("grey-light"), 0.2);
    border-bottom-color: rgba(get-colour("grey-light"), 0.2);
    border-right-color: rgba(get-colour("grey-light"), 1);
  }

  .loading--black & {
    border-top-color: rgba(get-colour("blue-black"), 0.2);
    border-left-color: rgba(get-colour("blue-black"), 0.2);
    border-bottom-color: rgba(get-colour("blue-black"), 0.2);
    border-right-color: rgba(get-colour("blue-black"), 1);
  }

  .loading--green & {
    border-top-color: rgba(get-colour("green"), 0.2);
    border-left-color: rgba(get-colour("green"), 0.2);
    border-bottom-color: rgba(get-colour("green"), 0.2);
    border-right-color: rgba(get-colour("green"), 1);
  }

  .loading--relative &,
  .loading--absolute & {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}
