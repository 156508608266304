@import "~wonderbly-css/base";

.MultiAdventurerII {
  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: $spacer-std;
    grid-column-gap: $spacer-std;

    @include on("fablet-up") {
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: initial;
      grid-row-gap: initial;
      grid-column-gap: initial;

      .CharacterCard {
        margin-right: $spacer-std;
      }
    }
  }

  &__cta {
    width: 100%;

    @include on("fablet-up") {
      width: auto;
    }
  }

  &__header {
    margin-bottom: $spacer-md;
  }

  &__footer {
    margin-top: $spacer-md;
  }
}
