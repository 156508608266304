@import "~wonderbly-css/base";

.order-images {
  display: flex;
  margin: 0 -1px;
  .order-images__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 1px;
    padding: $spacer-std 0;
    img {
      max-width: 100%;
      max-height: 80%;
      &:not([alt]) {
        outline: 0 !important;
      }
    }
    &--label {
      color: get-colour("stone-dark");
    }
  }
  &.order-images--cancelled {
    opacity: 0.4;
  }
}
