@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

#cart {
  padding: rem(37) 0;
}

.cart-summary {
  @extend %padded-top-less-on-mobile;
  @extend %padded-top-default-on-fablet-up;
  background-color: white;
  .cart-summary-title {
    @extend %spaced-bottom-tiny;
    @extend %text-mega;
    text-align: left;
  }
  .cart-summary-container {
    display: flex;
    .panel {
      width: calc(100% - 20px);
      margin: 0;

      &:not(:last-child) {
        @extend %spaced-bottom-small-on-tablet-up;
      }
    }
    @include on("fablet-down") {
      flex-direction: column;
    }
  }
  .cart-summary-payment {
    @extend %spaced-bottom;
    .payment-icons {
      display: flex;
      flex-wrap: wrap;
      margin: -#{get-size("tiny")};
      margin-bottom: 0;
      .payment-icon {
        @extend %spaced-right-tiny;
        border: rem(1) solid get-colour("grey-light");
        height: rem(30);
        margin-bottom: 0;
        margin-top: get-size("tiny");
        margin-left: get-size("tiny");
        margin-right: 0;
        width: rem(50);
      }
      @include on("tablet-up") {
        text-align: left;
      }
    }
    .cart-summary-payment-icons {
      @extend %padded-left;
      @extend %padded-right;
      @extend %padded-bottom-small;
      @extend %padded-top-small;
      @extend %spaced-bottom;
      @extend %spaced-top-tiny;
      background-color: get-colour("stone-light");
      border-bottom: rem(1) solid get-colour("stone");
      border-top: rem(1) solid get-colour("stone");
      display: flex;
    }

    .cart-summary-payment-icons__label {
      align-items: center;
      display: flex;
      font-size: rem(16);
      padding-top: rem(4);
      padding-right: get-size("less");
      white-space: nowrap;
    }
  }
  @include on("tablet-up") {
    .payment-icons-hidden {
      display: flex;
      justify-content: flex-end;
    }
  }
  @include on("desktop-up") {
    // For both two column layout and new grid system
    div[class*="column"] &.cart-summary--margin-top {
      margin-top: calc(#{rem(27) + rem(16)}); // h1 height + margin-bottom
    }
  }
}

.mention-me {
  font-size: rem(16);
  line-height: 1;
}

.mention-me--loaded {
  padding-bottom: get-size("less");
  padding-left: get-size("default");
  padding-right: get-size("default");
  padding-top: get-size("less");
  border-top: rem(1) solid get-colour("stone");
  text-align: center;
}

.padded-wrapper {
  @extend %padded-right-less-on-mobile;
  @extend %padded-right-default-on-fablet-up;
  @extend %padded-left-less-on-mobile;
  @extend %padded-left-default-on-fablet-up;
}

.cart-summary-checkout {
  &__ctas {
    & > .padded-wrapper {
      & > * {
        margin-bottom: $spacer-std;
        &:last-child {
          margin-bottom: $spacer-md;
        }
      }
    }
    &--order-reverse {
      display: flex;
      flex-flow: column-reverse;
      .accordion .discount-form {
        margin-bottom: $spacer-md;
      }
    }
  }

  .discount-cta-button {
    text-align: right;
  }

  .accordion-label span {
    color: $color-sharpie;
  }
}

.cart-order-summary {
  .cart-order-summary-price {
    font-size: rem(16);
  }
  .cart-order-summary-row--total {
    padding-block: 1rem;
    .cart-order-summary-price {
      font-size: rem(18);
      font-weight: 600;
    }
  }
  &.panel {
    @extend %spaced-none;
    @extend %padded-none;
    width: 100%;
  }
}

.cart-order-summary-list {
  flex: auto; // Overwriting flex: 1, as it was breaking in IE
  .cart-order-summary-row {
    &:not(:last-child) {
      @extend %spaced-top-tiny;
    }
  }
}

.cart-order-summary-row--discount {
  background-color: #e9f7f4;

  .cart-order-summary-price {
    color: get-colour("green");
    font-weight: $font-weight-bold;
  }
}

.cart-order-summary-row--discount-code {
  width: calc(100% - #{get-size("default")});
  margin: 0 auto;
}

.cart-summary-payment {
  @extend %spaced-bottom-small;
  display: flex;
  flex-direction: column;

  .payment-icons {
    text-align: center;
    margin-bottom: 0;
  }

  .payment-icon {
    border: rem(1) solid get-colour("stone-light");
  }

  .cart-summary-checkout {
    width: 100%;
  }
}

.discount-form {
  @extend %spaced-none;
}

.quick-checkout {
  overflow: hidden;
  .button {
    &.button--paypal {
      margin: 0;
    }
  }
}
