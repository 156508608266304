.order-total {
  display: flex;
  & > * {
    width: 100%;
    justify-content: space-between;
  }
  .order-total__price {
    text-align: right;
  }
}
