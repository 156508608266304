@import "~wonderbly-css/base";

.LineItemImage__wrapper {
  position: relative;
  width: rem(120);
  align-self: start;

  @include on("fablet-up") {
    flex: 0 0 7.5rem;
    margin-right: get-size("more");
  }

  &.LineItemImage__wrapper--has-addon {
    padding: 0 rem(20) rem(20) 0;

    .LineItemImage__image--default {
      position: relative;
      z-index: 2;
      bottom: 0;
      right: rem(10);
      width: calc(100% + #{rem(20)});
    }
  }
}

button.LineItemImage__cta {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.LineItemImage__cta {
  display: block;

  .LineItemImage__zoom {
    position: absolute;
    z-index: 3;
    bottom: rem(5);
    right: rem(5);
    height: $spacer-xl;
    width: $spacer-xl;
    border-radius: 50%;
    background: get-colour("blue-black");
    color: get-colour("white");
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.LineItemImage__image {
  width: 100%;
  &.LineItemImage__image--muse {
    max-width: rem(94);
    position: relative;
    z-index: 2;
  }
  &.LineItemImage__image--default {
    position: relative;
    z-index: 2;
  }
  &.LineItemImage__image--addon {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
