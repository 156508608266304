@import "~wonderbly-css/base";

.HeroField__Gender {
  // Have to do this to force the style without using !important
  input[type="radio"].HeroField__Gender__option--hidden {
    display: none;
  }
  &__options {
    display: flex;
    align-self: flex-end;
    margin: 0 -#{$spacer-sm};
    width: calc(100% + #{$spacer-std});
    & > .HeroField__Gender__option {
      width: 100%;
      label {
        margin: 0 $spacer-sm;
      }
    }
  }
  @include on("tablet-up") {
    display: flex;
    &__options {
      display: flex;
      align-self: flex-end;
    }
  }
}
