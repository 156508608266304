@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.product-form {
  font-family: $font-family-display;
  padding: get-size("big") 0 0;
  background-color: get-colour("stone-light");
  border-bottom: rem(1px) solid get-colour("stone");
  border-top: rem(1px) solid get-colour("stone");
  display: inline-block;
  width: 100%;
  @include on("tablet-down") {
    padding-bottom: get-size("default");
  }
  .product-form-title {
    font-weight: $font-weight-base;
    margin: rem(-20px) auto get-size("default");
    @include on("desktop-up") {
      margin-bottom: get-size("big");
    }
  }
  &.product-form--multi-adventurer {
    @extend %padded-top;
    @extend %padded-bottom;

    label.adventurer-gender-input {
      padding-block: 0.6rem;
    }

    .hero-form-cta-button {
      .Button {
        padding-block: 0.6rem;
        white-space: nowrap;
      }
    }

    .product-form-wrapper {
      & > .product-form-title {
        margin: 0;
      }
      & > .Button {
        @extend %spaced-top-small;
      }
      .hero-form {
        margin-top: 30px;
      }
      .hero-form-row {
        padding: 0;
      }
    }
    @include on("fablet-up") {
      .product-form-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        & > .product-form-title {
          font-size: rem(22px);
          max-width: 50%;
        }
        & > .Button {
          margin-top: 0;
          margin-left: get-size("small");
          max-width: calc(50% - 10px);
        }
      }
    }
    @include on("tablet-up") {
      .product-form-wrapper {
        .product-form-title {
          max-width: calc(50% - 155px);
        }
        & > .Button {
          margin-left: get-size("small");
          max-width: calc(50% - 155px);
        }
      }
    }
    @include on("desktop-up") {
      .product-form-title {
        font-size: rem(24px);
      }
    }
  }
}

.product-form-wrapper {
  @include on("desktop-up") {
    &.guttered {
      width: rem(1000px);
      margin: 0 auto;
    }
  }
}
