@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.youtube-wrapper {
  @include aspect-ratio(646, 364);
  overflow: hidden;

  &.youtube-wrapper-full {
    width: 100%;
    height: 100%;
  }

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.youtube-play-icon {
  background: rgba(get-colour("grey-dark"), 0.85);
  color: get-colour("stone-light");
  left: 50%;
  top: 50%;
  margin: rem(-32) 0 0 rem(-48);
  position: absolute;
  transition: 0.25s $bezier-out;
  width: rem(96);
  height: rem(64);

  &:hover,
  &:hover {
    background: get-colour("red");
    opacity: 1;
  }

  &:active {
    background: get-colour("red-dark");
    opacity: 1;
  }

  .ss-play {
    line-height: rem(50);
    font-size: rem(32);
    left: 50%;
    top: 50%;
    margin: rem(-22) 0 0 rem(-15);
    position: absolute;
  }
}

.youtube-placeholder {
  left: 0;
  top: 0;
  height: 100%;
  width: 101%;
  position: absolute;
}
