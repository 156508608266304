@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.main-navigation {
  margin: 0 0 get-size("default");
  padding: 0 0 get-size("default");
  position: relative;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    transform: translateX(-60px);
    transition: 0.5s $bezier-out 0.1s;
    will-change: transform;

    .app-nav-open & {
      transform: translateX(0);
    }

    @include on("desktop-up") {
      transform: none;
    }
  }

  &:after {
    height: 1px;
    width: calc(100% + #{rem(60)});
    margin-left: rem(-30);
    position: absolute;
    content: "";
    left: 0;
    top: 100%;
    background: get-colour("grey-light");
  }

  .main-navigation-link {
    display: inline-block;
    @extend %spaced-right-on-desktop-up;

    &.navigation-link-active {
      background: get-colour("green");
    }
  }

  @include on("desktop-up") {
    margin: 0;
    padding: 0;
    flex: 2;

    &:after {
      display: none;
    }

    li {
      display: inline-block;
    }
  }

  .mention-me--preload {
    color: get-colour("grey-medium");
  }
}

.main-navigation-link {
  @extend %text-mega;
  @extend %padded-top-tiny-on-tablet-down;
  @extend %padded-bottom-tiny-on-tablet-down;

  font-family: $font-family-display;
  font-weight: 400;
  display: block;

  @include on("desktop-up") {
    font-size: rem(20);
    line-height: rem(30);
  }
}

.main-navigation-link-text {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.main-navigation-link-label {
  color: get-colour("green");
  position: absolute;
  right: rem(0);
  top: rem(-6);
  font-size: rem(10);

  @include on("desktop-up") {
    right: rem(0);
    top: rem(-13);
  }
}
