@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.cross-sell {
  margin: rem(13px) 0;

  .container-guttered {
    background-color: rgba(221, 220, 212, 0.2);
    padding: get-size("small");
  }

  .cross-sell-column {
    &:not(:last-child) {
      @include on("desktop-up") {
        .product-item-content {
          border-right: rem(2px) solid get-colour("stone");
        }
      }
    }
  }

  .cross-sell-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cross-sell-title {
  }

  .cross-sell-cta {
    @include on("tablet-down") {
      font-size: rem(14px);
    }
    font-weight: 400;
  }

  .product-item {
    @include on("tablet-down") {
      flex-direction: column;
    }
    margin: 0;
    flex-direction: row;
    align-items: center;

    &.product-item-single-product {
      flex-direction: row;
      border: none;

      .product-item-content {
        text-align: left;
      }

      .pricing {
        justify-content: flex-start;
      }
    }

    .product-item-content-items-2 {
      text-align: center;
    }
  }

  .product-item-image {
    @extend %spaced-left-small;
    width: 100%;
    max-width: rem(105px);
    margin: 0;
  }

  .product-item-content {
    @include on("tablet-down") {
      width: 100%;
      align-items: center;
    }
    padding: 0 get-size("small") 0;
  }

  .pricing {
    @include on("tablet-down") {
      justify-content: center;
    }
    &.pricing--slash {
      flex-direction: initial;
      flex-wrap: wrap;
    }

    .saving-price--slash {
      @extend %spaced-none;
    }
  }

  p.current {
    @extend %spaced-right-tiny;
    color: get-colour("green");
    font-size: rem(14px);
  }

  .age-range {
    font-size: rem(14px);
  }
}
