@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";

.payment-methods-ii {
  .payment-methods-ii__header {
    padding: get-size("less");
    display: flex;
    flex-direction: column;
    border-bottom: rem(1) solid get-colour("stone");

    .payment-methods-ii__title {
      margin-bottom: rem(8);
      h5 {
        margin: 0;
        font-weight: 600;
        line-height: rem(22);
      }
    }

    .payment-methods-ii__badge {
      font-family: $font-family-sans-serif;
      color: $color-sharpie;
      background: transparent;
      padding: rem(4) rem(8);
      font-size: rem(16);
      display: flex;
      align-items: center;
      align-self: flex-start;
      line-height: rem(18);

      > .Icon {
        margin-right: rem(8);
      }
    }

    @include on("fablet-up") {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      .payment-methods-ii__title {
        width: 50%;
        margin-bottom: 0;
      }
    }
  }
  .payment-methods-ii__methods {
    .payment-method-ii:first-child .collapsible-ii__header {
      border-top: none;
    }
  }
  .panel {
    display: inline-block;
    width: 100%;
    & > .loading {
      margin: get-size("less") 0;
      float: left;
    }
  }
}
