@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

$text-color: $color-sharpie;
$highlight-color: $color-racing-green;

.navigation__item {
  background-color: #fff;
  .navigation__item__title,
  .navigation__item__link,
  .navigation__item__button {
    @extend %padded-top-small;
    @extend %padded-bottom-small;
    padding-left: get-size("less");
    padding-right: get-size("less");
    display: block;
    color: $text-color;
    .navigation__list--child & {
      padding-left: get-size("more");
    }
  }

  .navigation__item__link,
  .navigation__item__button {
    cursor: pointer;

    > .Icon--legacy {
      margin-right: 0.5rem;
    }

    .ss-icon:not(.ss-dropdown):not(.ss-directup) {
      @extend %spaced-right-small;
    }
    .ss-dropdown,
    .ss-directup {
      @extend %spaced-left-small;
      float: right;
    }
    &:hover {
      color: $highlight-color;
    }
  }

  .navigation__item__button {
    outline: none;
    border: none;
    width: 100%;
    text-align: left;
  }
  .navigation__item__title {
    @extend %spaced-bottom-tiny;
    line-height: rem(25);
  }

  .navigation__item__link--active {
    color: $highlight-color;
  }

  .navigation__list--root & {
    &.navigation__item--active .navigation__item__button,
    .navigation__item__link--active {
      padding-left: rem(11);
      color: $text-color;
      border-left: rem(4) solid $highlight-color;
    }
  }

  &.navigation__item--open:not(.navigation__item--active)
    .navigation__item__button {
    color: $highlight-color;
  }

  .navigation__list--child > & .navigation__item__link--active {
    color: $highlight-color;
    border: none;
    padding-left: get-size("more");
  }

  &:not(:last-child) {
    border-bottom: rem(1) solid #eeece7;
  }
}
