@import "~wonderbly-css/base";

.SocialNetworkSet {
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  &__item {
    color: $color-bright-smile;

    &:hover {
      background-color: $color-bright-smile;
      border-color: $color-bright-smile;
      color: $color-racing-green;
    }
  }
}
