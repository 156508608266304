@import "~wonderbly-css/base";

.OffersOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.3s;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(get-colour("almost-black"), 0.8);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    cursor: pointer;
  }

  &__wrapper {
    background: white;
    box-shadow: 0px 0px rem(5) rgba(get-colour("grey-dark"), 0.5);
    padding: $spacer-lg 0 $spacer-xl;
    transition: transform 0.3s ease-in-out;
  }

  &--is-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &--is-overlay > &__cta {
    display: none;
  }

  &--is-overlay &__bg {
    visibility: visible;
    opacity: 1;
  }

  &__cta {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__cta &__btn {
    @extend %text-kilo;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: rem(16);
  }

  .ScrollableCards .Carousel__slide {
    max-width: rem(320);
    width: 100%;

    @include on("wide-up") {
      max-width: 25%;
    }
  }

  &__code {
    display: inline-block;
    padding: 0.25rem 0.5rem 0.125rem;
    border-radius: rem(3);
  }
}
