@import "~wonderbly-css/base";

.child-reviews.hidden {
  display: none;
}
.child-review {
  img {
    max-width: 100%;
  }
  .child-review-meta {
    display: flex;
    margin-bottom: 20px;
    .reviewer-rating {
      img {
        width: 140px;
      }
    }
    @include on("fablet-down") {
      flex-direction: row-reverse;
      & > div {
        width: 50%;
      }
      .reviewer-rating {
        text-align: right;
      }
    }
  }
  .review-name,
  .review-age {
    @extend %text-kilo;
    font-family: $font-family-display;
    font-weight: $font-weight-base;
  }
  .review-name {
    margin-top: $spacer-std;
  }
  .rule--medium {
    margin: 40px 0;
    opacity: 0.25;
  }
  @include on("tablet-up") {
    display: flex;
    flex-wrap: wrap;
    .child-review-meta {
      flex-direction: column;
      width: 20%;
    }
    .child-review-content {
      width: 80%;
      padding-left: 20px;
    }
  }
}
