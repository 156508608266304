@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.order-buttons {
  .order-buttons__wrapper {
    margin-right: -#{get-size("tiny")};
    margin-left: -#{get-size("tiny")};
    width: calc(100% + #{get-size("small")});
    display: flex;
    flex-wrap: wrap;
  }
  .Button.order-button {
    @extend %spaced-left-tiny;
    @extend %spaced-right-tiny;
    @extend %padded-left-tiny;
    @extend %padded-right-tiny;
    width: calc(100% - #{get-size("small")});
    &:not(:last-child) {
      @extend %spaced-bottom-small-on-mobile;
    }
  }
  @include on("fablet-up") {
    .order-buttons__wrapper {
      flex-wrap: nowrap;
    }
  }
  @include on("tablet-up") {
    &:not(.order-buttons--1-visible) {
      .Button.order-button {
        width: calc(100% / 3 - #{get-size("small")});
      }
    }
  }
}
