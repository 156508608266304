@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/functions/palette";

.product-information-wrapper {
  @include on("desktop-up") {
    width: 1000px;
    margin: 0 auto;
  }
}
.product-information-stacked {
  .product-information {
    padding: get-size("default") 0;
    border-bottom: 1px solid get-colour("stone");
  }
  .product-information-image {
    .magnifier {
      overflow: visible;
    }
    img {
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
  .product-information-content {
    .rich-text {
      margin-top: 0;
    }
  }
  @include on("fablet-down") {
    .product-information-content {
      button {
        width: 100%;
      }
    }
  }
  @include on("tablet-up") {
    .product-information {
      padding: get-size("big") 0;
      .product-information-wrapper {
        display: flex;
        & > div {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .product-information-image {
        img {
          max-width: 50vw;
        }
      }
      .product-information-content {
        padding-left: get-size("default");
        button {
          align-self: flex-start;
        }
      }
      &:nth-child(odd) {
        .product-information-image {
          direction: rtl;
          img {
            float: right;
          }
        }
      }
      &:nth-child(even) {
        .product-information-wrapper {
          flex-flow: row-reverse;
        }
        .product-information-content {
          padding-left: 0;
          padding-right: get-size("default");
        }
      }
    }
  }
  @include on("desktop-up") {
    .product-information {
      .product-information-content {
        padding-left: get-size("big");
      }
      &:nth-child(even) {
        .product-information-content {
          padding-left: 0;
          padding-right: get-size("big");
        }
      }
    }
  }
}
