@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.user-orders {
  @extend %padded-bottom;
  @extend %spaced-top-big-on-tablet-up;

  .user-orders-title {
    @extend %spaced-bottom;
    @extend %spaced-bottom-huge-on-tablet-up;
    @extend %spaced-top-big-on-fablet-down;
    text-align: center;
  }

  .user-orders-empty {
    text-align: center;
  }

  .loading {
    @extend %spaced-bottom;
    @extend %spaced-bottom-huge-on-tablet-up;
  }
}

.user-order {
  @extend %spaced-bottom-big;

  .button {
    width: 100%;

    @include on("tablet-up") {
      width: auto;
    }
  }

  .user-order-header {
    @extend %spaced-bottom-on-tablet-up;
    @extend %spaced-top;
    text-align: center;

    @include on("tablet-up") {
      text-align: initial;
    }

    [class*=" col-"],
    [class^="col-"] {
      vertical-align: bottom;
    }
  }

  .user-order-countdown {
    text-align: right;
  }

  .user-order-info {
    @include on("fablet-down") {
      text-align: center;
    }
  }

  .user-order-date {
    @extend %spaced-right-small;
  }

  .user-order-footer {
    text-align: center;
  }

  .user-order-item {
    @extend %padded;
    @extend %spaced-bottom;
  }

  &.user-order--list {
    .user-order-items {
      display: none;

      @include on("tablet-up") {
        display: block;
      }
    }
  }
}

.user-order--cancelled {
  .user-order-status {
    @extend %padded-small;
    @extend %spaced-bottom-small-on-fablet-down;
    background-color: get-colour("red");
    color: white;
  }
}

.user-order--list.user-order--cancelled {
  .user-order-info {
    text-decoration: line-through;
  }
}
