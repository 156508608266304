@import "~wonderbly-css/base";

.SublimatorProductGrid {
  margin-bottom: $spacer-xxl;
  margin-top: $spacer-xxl;

  &__grid {
    display: grid;
    grid-auto-flow: dense;
    grid-template-rows: auto;
    column-gap: $spacer-md;
    row-gap: $spacer-md;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  &__sm-product-grid {
    grid-column-gap: $spacer-xl;
    grid-row-gap: $spacer-xl;

    @include on("tablet-up") {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__marketing-card {
    grid-column: span 2;
    @include on("tablet-up") {
      grid-column: auto;
    }
  }

  &__subtitle {
    margin-bottom: $spacer-lg;
  }
}
