@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.carousel {
  height: 100%;
  position: relative;
  touch-action: pan-y;
  &:not(.carousel-disable-drag) {
    .carousel-item-wrap {
      cursor: grab;
    }
  }

  &.carousel--external-nav {
    padding-bottom: rem(40);
  }
}

.carousel-item-wrap {
  height: 100%;
  overflow: hidden;
}

.carousel-items {
  height: 100%;
  white-space: nowrap;
  will-change: transform;
  transition: transform 0.4s $bezier-out;
  .drag > * {
    float: left;
  }
  .carousel-is-dragging & {
    transition: none;
  }
}

.carousel-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.carousel-pagination-dot {
  @extend %circled;
  @extend %spaced-right-small;
  border: 2px solid white;
  background: white;
  height: rem(16);
  width: rem(16);
  padding: 0;
  text-indent: -999em;
  display: inline-block;
  transition: 0.25s ease;

  &:active,
  &:focus {
    outline: none;
  }

  &:last-child {
    @extend %spaced-right-none;
  }
}
.carousel-pagination-dot-active {
  background: get-colour("yellow");
}

.carousel-thumbnail {
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
}
.carousel-thumbnail-item-wrapper {
  width: 100%;
  flex-basis: calc(100% / 3);
  padding: 0 10px 10px 0;
  @include on("desktop-up") {
    flex-basis: calc(100% / 6);
  }
}
.carousel-thumbnail-item {
  border: 4px solid #fff;
  border-radius: 3px;
  float: left;
  transition: all 0.25s cubic-bezier(0.17, 0.67, 0.52, 0.97);
  &.carousel-thumbnail-item-active {
    border-color: get-colour("green");
  }
  &:hover {
    transform: translateY(-3px);
  }
  img {
    width: 100%;
    float: left;
  }
}

.carousel-navigation-previous,
.carousel-navigation-next {
  @extend %circled;
  background: rgba(get-colour("green"), 0.6);
  color: white;
  cursor: pointer;
  border: 0;
  margin: 0;
  padding: 0;
  height: rem(48);
  width: rem(48);
  top: 50%;
  margin-top: rem(-24);
  position: absolute;
  text-align: center;
  line-height: rem(48);
  transition: 0.25s ease;

  &:hover,
  &:focus {
    outline: none;

    @include on("tablet-up") {
      background-color: get-colour("green-light");
    }
  }

  &:active {
    background-color: get-colour("green-dark");
    outline: none;
  }

  .carousel--external-nav & {
    display: none;

    @include on("tablet-up") {
      display: block;
    }
  }
}

.carousel-navigation-previous {
  left: rem(20);

  .carousel--external-nav & {
    left: rem(-64);
  }
}

.carousel-navigation-next {
  right: rem(20);

  .carousel--external-nav & {
    right: rem(-64);
  }
}
