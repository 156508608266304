@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.hero-ii {
  overflow: hidden;
  position: relative;
  &:not(:first-child):not(:last-child) {
    @extend %spaced-top-big;
    @extend %spaced-bottom-big;
    @at-root {
      h1 + & {
        margin-top: 0;
      }
    }
  }
  @include on("mobile") {
    text-align: center;
  }
}

.hero-ii-image {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  img {
    float: left;
  }
  @include on("fablet-up") {
    left: 50%;
    width: auto;
    z-index: 1;
    height: 100%;
    transform: translateX(-50%);
    img {
      width: auto;
      height: 100%;
      margin: 0 auto;
    }
  }
}

.hero-ii-wrapper {
  &,
  &--bottom-space {
    position: relative;
    padding: 40px 0;
    z-index: 2;
    & > div {
      display: flex;
      align-items: center;
      @include on("tablet-up") {
        height: rem(250);
      }
    }
  }
  &--bottom-space {
    @include on("mobile") {
      padding-bottom: rem(130);
    }
  }
}

.hero-ii-content {
  margin-top: 0;
  h1,
  h2,
  h3 {
    margin: 0;
  }
}

.hero-ii-content-column {
  @include on("fablet-up") {
    max-width: 50%;
  }
}

.hero-ii-value-prop {
  i {
    margin-right: 10px;
  }
}

.hero-ii-rating {
  margin-top: 10px;
}
