@import "~wonderbly-css/base";

.DynamicForm {
  margin: $spacer-xxl 0;

  &__form {
    max-width: rem(600);
    margin: 0 auto;
  }
}
