@import "~chameleon/helpers/box";

div.mention-me {
  &:not(.mention-me--loaded) {
    @extend %spaced-none;
    @extend %padded-none;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

.mention-me--referred {
  @extend %spaced-top;
}
