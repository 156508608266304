@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.product-wrapper--has-badge {
  .product {
    border: 2px solid transparent;
  }
}
.product-link {
  display: block;
  &,
  &:hover,
  &:active,
  &:focus {
    color: initial;
  }
  &:hover {
    .product {
      transform: scale(1.01);
      z-index: 4;
    }
  }
  .arrow-right > * {
    height: rem(16px);
    width: rem(16px);
  }
}
.product {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  margin: 0 0 get-size("small");
  z-index: 3;
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  transform-style: preserve-3d;
  text-align: left;
  @media (min-width: 640px) {
    margin: 0 get-size("tiny") get-size("small");
    .product-card-list-row--has-badge
      .product-wrapper:not(.product-wrapper--has-badge)
      & {
      margin-top: rem(35px);
    }
  }
  @include on("tablet-up") {
    margin: 0 get-size("small") get-size("default");
  }
  @include on("desktop-up") {
    margin: 0 rem(13px) get-size("more");
  }
}

.product-badge {
  @extend %padded-top-tiny;
  @extend %padded-right;
  @extend %padded-left;
  padding-bottom: rem(7px);
  text-align: center;
  font-size: rem(16px);
}

.product-image-wrapper {
  @extend %padded-small;
  height: 193px;
  display: flex;
  align-content: center;
  justify-content: center;
  .product-image {
    width: auto;
    height: 100%;
  }
}

.product-content {
  padding-top: get-size("less");
  padding-bottom: get-size("less");
  @extend %padded-right;
  @extend %padded-left;

  &.product-content--show-product-tag {
    padding-bottom: 0;

    .product-content-text {
      p {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.product-content-text {
  color: get-colour("grey-medium");
}

.product-info {
  @extend %padded-top-small;
  @extend %padded-bottom-small;
  @extend %spaced-left;
  @extend %spaced-right;
  border-top: 1px solid get-colour("stone-light");
  display: flex;
  font-size: rem(16px);
  .pricing {
    width: 100%;
    text-align: right;
    justify-content: flex-end;
    .saving-price {
      @extend %spaced-left-none;
      @extend %spaced-right-small;
      top: 0;
    }
    &.pricing--slash {
      justify-content: flex-start;
      flex-direction: row-reverse;
      align-items: flex-end;
    }
  }
  p {
    @extend %spaced-none;
    display: inline-block;
    width: 100%;
    &.age {
      color: get-colour("grey-medium");
      width: 100px;
    }
    &.current {
      color: get-colour("green");
    }
  }
}

.product-tags {
  @extend %padded-right-small;
  @extend %padded-left-small;
  @extend %spaced-right-small;
  @extend %spaced-left-small;
  margin-bottom: rem(13px);
  span {
    @extend %padded-top-tiny;
    @extend %padded-bottom-tiny;
    @extend %padded-right-small;
    @extend %padded-left-small;
    padding-bottom: rem(2px);
    font-size: rem(14px);
    display: inline-block;
    &:not(:last-child) {
      @extend %spaced-right-tiny;
    }
  }
  @include on("tablet-up") {
    span {
      font-size: rem(15px);
    }
  }
}
