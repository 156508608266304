@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.cart-items {
  @extend %spaced-top;
  @extend %spaced-bottom;

  h1 {
    @extend %spaced-bottom-more;
    @extend %spaced-top-more;
    text-align: center;
  }

  .panel {
    @extend %spaced-top;

    &:first-child {
      @extend %spaced-top-none;
    }
  }
}

.cart-items-inner {
  position: relative;
  min-height: rem(176); // Otherwise the loader overlaps

  .loading {
    background: rgba(get-colour("stone-light"), 0.5);
    z-index: 1;
    position: absolute;
  }
}

.cart-items--loading {
  @extend %spaced-top-big;
}
