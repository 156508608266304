@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.collapsible-ii__item {
  .collapsible-ii__header {
    background: get-colour("white");
    outline: 0;
    border: none;
    width: 100%;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(16) get-size("less");
    cursor: pointer;
    border-top: rem(1) solid get-colour("stone");
    transition: box-shadow 200ms ease-in-out;

    z-index: 0;
    position: relative;

    .collapsible-ii__header__content {
      display: block;
      width: calc(100% - #{rem(26)});
    }
    .collapsible-ii__header__icon {
      transition: transform 0.3s linear;
    }
  }
  .collapsible-ii__content {
    background: get-colour("white");
    padding: rem(16) get-size("less");
    border-top: rem(1) solid get-colour("stone");
  }
  &--active {
    .collapsible-ii__header {
      box-shadow: 0 0 rem(4) rgba(140, 140, 140, 0.6);
      .collapsible-ii__header__icon {
        transform: rotate(180deg);
      }
    }
  }
  &--disabled {
    .collapsible-ii__header {
      color: get-colour("grey-medium");
      background-color: rgba(get-colour("stone"), 0.3);
      cursor: not-allowed;
      img {
        filter: grayscale(100%);
        opacity: 0.5;
      }
    }
  }
  &--non-clickable {
    .collapsible-ii__header {
      cursor: default;
    }
  }
}
