@import "~wonderbly-css/base";

// Page styling - to move into its own css
#product-formats {
  padding-top: rem(6);

  @include on("tablet-up") {
    padding-top: $spacer-lg;
  }

  h1 {
    margin: rem(32) 0;
  }
}

.GenericFormatSelector {
  &__content {
    text-align: center;
    margin: rem(50) 0;
  }

  &__format-items {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    @include on("desktop-up") {
      margin: 0 auto;
      flex-direction: row;
    }

    &--item {
      margin: $spacer-xl $spacer-std $spacer-md;
      position: relative;
      flex: 1;
      flex-basis: 100%;
      max-width: 500px;
      float: left;
      cursor: pointer;

      @include on("fablet-up") {
        flex-basis: calc(50% - 20px);
        max-width: calc(1000px / 2);
        align-self: center;
      }

      @include on("desktop-up") {
        flex-basis: calc(33.33% - 20px);
        max-width: calc(1000px / 3);
        align-self: auto;
      }

      &.has-margin-top,
      &.has-no-badge {
        margin-top: 0;

        @include on("tablet-up") {
          margin-top: rem(34);
        }

        .GenericFormatSelector__format-savings {
          top: rem(30);
        }
      }
    }
  }

  &__format-savings {
    font-family: $font-family-display;
    color: get-colour("almost-black");
    height: rem(80);
    width: rem(80);
    position: absolute;
    right: rem(30);
    top: rem(54);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &-price--text {
      display: flex;
      flex-direction: column;
      position: relative;
      line-height: rem(18);
      max-width: rem(60);
    }

    &-was-price--text {
      display: inline-block;
      line-height: rem(9);
      font-size: rem(9);
      margin-bottom: rem(3);
      position: relative;
      text-decoration: line-through;
    }
  }

  &__actions {
    background: white;
    margin-top: $spacer-md;
    padding: $spacer-md 0 $spacer-xl;
    text-align: right;
  }

  &__card-picture {
    padding-top: 61.087%;
    display: block;
    position: relative;
  }

  &__card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
  }
}

// used in conjunction with <picture> and <source> to stop content jump for
// the card-picture
.ButtonCard .GenericFormatSelector__card-picture {
  @media (max-width: 777px) {
    padding-top: 100%;
  }
}
