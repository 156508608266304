@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

@keyframes logo-animation-in {
  from {
    height: rem(60);
    transform: translateY(0);
  }
  to {
    height: rem(25);
    transform: translateY(-92px);
  }
}

@keyframes logo-animation-out {
  from {
    height: rem(25);
    transform: translateY(-92px);
  }
  to {
    height: rem(60);
    transform: translateY(0);
  }
}

.header-logo {
  @extend %spaced-right-big-on-desktop-up;
  @extend %padded-top-on-desktop-up;
  @extend %padded-bottom-on-desktop-up;
  overflow: hidden;

  svg {
    display: block;
    width: auto;
    margin: 0 auto;
    height: rem(40);

    @include on("tablet") {
      height: rem(50);
    }
  }

  .wonderbly-icon {
    display: none;
  }

  @include on("desktop-up") {
    display: block;
    .header-logo-inner {
      height: rem(60);
    }
    .wonderbly-logo {
      height: rem(60);
      width: rem(122);
    }
    .wonderbly-icon {
      display: block;
      margin-top: rem(30);
    }
    .header-fixed & {
      .wonderbly-icon {
        svg {
          height: rem(27);
          width: auto;
        }
      }

      .header-logo-inner {
        animation: logo-animation-in 0.5s 1 ease both;
        position: relative;
      }
    }

    .header:not(.header-fixed) & {
      .wonderbly-icon {
        svg {
          height: rem(27);
          width: auto;
        }
      }

      .header-logo-inner {
        animation: logo-animation-out 0.5s 1 ease both;
      }
    }
  }
}
