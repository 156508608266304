@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";

.blog-articles {
  padding: get-size("more") 0;

  @include on("fablet-up") {
    padding: get-size("bigger") 0;
  }
}

.blog-articles-title {
  margin-bottom: get-size("default");
  text-align: center;

  @include on("fablet-up") {
    margin-bottom: get-size("big");
  }
}

.blog-articles-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;

  & > article {
    padding: 0 get-size("small");
    display: flex;
    flex-basis: 100%;
    flex-direction: column;

    @include on("fablet-up") {
      flex-basis: 50%;
      max-width: calc(50%);
    }

    @include on("tablet-up") {
      flex-basis: calc(100% / 3);
      max-width: calc(100% / 3);
    }
  }
}
