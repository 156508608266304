@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.cart-items-ii {
  h1 {
    text-align: left;
    margin: 0 0 rem(16);
    @extend %text-giga;
  }

  .cart-items-title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .panel {
    margin-top: get-size("less");

    &:first-child {
      @extend %spaced-top-none;
    }
  }
}
