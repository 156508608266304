@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.offer-panel {
  width: 100%;

  .offer-image {
    background-size: cover;
    background-position: center;

    @include on("tablet-up") {
      height: rem(145);
    }
  }

  .text-base {
    font-weight: lighter;
  }

  .offer-content {
    padding: get-size("less");

    @include on("tablet-up") {
      padding: get-size("default");
    }

    h5 {
      @extend %spaced-bottom-tiny;
    }
    p {
      @extend %spaced-none;
    }

    .button {
      @extend %spaced-top-small;
      width: 100%;
    }
  }
}

.offer-content {
  padding: get-size("less");

  @include on("tablet-up") {
    padding: get-size("default");
  }

  p {
    @extend %spaced-none;
  }
}

.offer-panel---horizontal {
  margin-right: get-size("small");

  .offer-image {
    flex-basis: 36%;
    max-width: rem(140);
  }

  .offer-content {
    flex-basis: 64%;
  }
}
