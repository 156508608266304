@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/config/animations";
@import "~wonderbly-css/mixins/responsive";

$modifier: 2;
$sprite-height: (476px / $modifier);
$sprite-width: (386px / $modifier);

.product-hero.product-hero--the-littlest-bear {
  background: #e6efef;
  [class*="product-hero-decoration-"] {
    background-image: url("img/product-hero-tlb.png");
    background-repeat: no-repeat;
    background-size: $sprite-width $sprite-height;
    display: none;
    position: absolute;
    pointer-events: none;

    @include on("wide-up") {
      display: block;
    }
  }

  .product-hero-decoration-left {
    left: 0;
    bottom: 15%;
    background-position: 0 0;
    height: (332px / $modifier);
    width: (168px / $modifier);
  }

  .product-hero-decoration-right {
    right: 0;
    top: 15%;
    background-position: (-168px / $modifier);
    height: (476px / $modifier);
    width: (218px / $modifier);
  }

  .product-hero-price {
    color: get-colour("red");
  }
}
