@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.adventurer-phototypes {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  .panel {
    height: 0;
    overflow: hidden;
  }
  &--visible {
    height: auto;
    opacity: 1;
    visibility: visible;
    @include on("tablet-down") {
      padding-top: 20px;
    }
    .panel {
      height: auto;
      overflow: visible;
      @include on("desktop-up") {
        padding: get-size("small") get-size("small") 0;
      }
    }
  }
  &--boy {
    .adventurer-phototype-column--girl {
      display: none;
    }
  }
  &--girl {
    .adventurer-phototype-column--boy {
      display: none;
    }
  }
  .tooltip--visible {
    margin-top: 0;
    .js &.phototype-error-tooltip {
      @extend %spaced-bottom-small;
    }
    @include on("desktop-up") {
      position: absolute;
      max-width: 320px;
      bottom: calc(100% + 3px);
      .js & {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
  @include on("tablet-down") {
    .tooltip {
      box-shadow: none;
      background-color: transparent;
      &:after {
        display: none;
      }
      .panel {
        background: none;
      }
    }
  }
  @include on("desktop-up") {
    &--boy {
      .tooltip--visible:after {
        right: calc((100% - 20px) / 100 * 75);
      }
    }
    &--girl {
      .tooltip--visible:after {
        right: calc((100% + 20px) / 100 * 25);
      }
    }
  }
}

.adventurer-phototype-column {
  margin-bottom: get-size("small");
}

.adventurer-phototype-input {
  label {
    display: inline-block;
    img {
      pointer-events: none;
      max-width: 100%;
    }
  }
}
