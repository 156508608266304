@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";

.PayPal {
  .loading {
    @extend %rounded;
    padding: rem(21.5) $spacer-md;
    background-color: #ffc439;
    border: solid rem(1) #ffaf00;
  }
  &__buttons-container {
    display: flex;
    justify-content: center;
    border-radius: $spacer-sm;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &--loading {
      display: none;
    }
  }
}
