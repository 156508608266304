@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";
@import "~chameleon/helpers/visibility";

.character-picker {
  @extend %spaced-top;
  @extend %spaced-bottom-more;
  @extend %spaced-bottom-small-on-mobile;
  z-index: 2;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  position: relative;
  transition: 0.4s;
  // Unfortunately with the current setup it's NOT possible to add overflow: scroll to
  // the character picker as it's hiding the "Change" button below it.

  &.character-picker--transformed {
    display: block;
    transform: translateX(rem(50));
  }
}

.character-picker-inner {
  transition: transform 0.25s $bezier-out;
  display: inline-block;
}

.character-picker-nav {
  display: inline-block;
  position: relative;
}

.character-picker-wrapper {
  @extend %padded;
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);

  .character-picker-modal & {
    background: rgba(get-colour("grey-dark"), 0.5);
    height: 100%;
    left: 0;
    transform: none;
    top: 0;
    width: 100%;
    transition: visibility 0.3s ease 0.3s, opacity 0.3s ease;
    position: absolute;
    z-index: 1;
  }
}

.character-picker-characters {
  @extend %padded-small;
  background: get-colour("white");
}

.character-picker-description {
  text-align: center;
}

.character-picker-cancel {
  @extend %spaced-top-small;
  @extend %hidden-on-tablet-up;
}

.character-picker-nav-item {
  position: relative;
}

.character-picker-nav-item.button-radio {
  label {
    background-color: transparent;
    color: get-colour("grey");
  }

  input:checked + label {
    background-color: transparent;
  }

  &:hover label {
    color: get-colour("grey");
    background: get-colour("stone-light");
  }

  &--disabled:hover label {
    background-color: transparent;
    cursor: not-allowed;
  }

  .button--green:hover,
  .button--green:active .button--green:focus {
    background-color: get-colour("green");
  }
}

.character-picker-nav-item-inner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  text-align: left;

  .button {
    @extend %spaced-left-big;
  }
}

.character-thumbnail {
  max-width: rem(40);

  .character-picker-label & {
    display: block;
    background-color: white;
    border: solid 2px transparent;
  }

  .character-picker-nav-item-inner & {
    @extend %spaced-right-small;
  }
}

.character-picker-label.button {
  @extend %padded-left-tiny;
  @extend %padded-right-tiny;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    & > .character-thumbnail {
      border-color: get-colour("green-light");
    }
  }

  &:active,
  &:focus {
    & > .character-thumbnail {
      border-color: get-colour("green");
    }
  }

  .character-picker-nav--page-active & {
    .character-thumbnail {
      border-color: get-colour("green");
    }
  }
}
