@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/units";
@import "~chameleon/helpers/box";

.order-line-items {
  color: get-colour("grey-medium");
  @extend %spaced-bottom-default;

  .order-line-items__line-item {
    @extend %spaced-bottom-default;
  }

  .order-line-items__line-item__title {
    color: get-colour("grey-dark");
    font-size: rem(22);
  }

  .order-line-items__line-item__customisation {
    @extend %spaced-top-tiny;
    p {
      color: get-colour("grey-medium");
      margin: 0;
      strong {
        color: get-colour("grey-dark");
      }
    }
  }

  .order-line-items__line-item__dedication-title {
    @extend %spaced-bottom-tiny;
    color: get-colour("grey-dark");
    font-size: rem(18);
  }

  .order-line-items__line-item__dedication-content {
    color: get-colour("grey-medium");
  }

  .order-line-items__line-item__details {
    display: flex;
  }
  .order-line-items__line-item__details__addons {
    @extend %spaced-left-tiny;
  }

  .order-line-items__line-item__details__price {
    color: get-colour("grey-dark");
    @extend %padded-left-small;
    margin-left: auto;
  }

  strong {
    color: get-colour("grey-dark");
  }
}
