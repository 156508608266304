@import "~wonderbly-css/base";

.CharacterCard {
  background: get-colour("white");
  border-radius: $spacer-sm;
  text-align: center;
  width: 100%;

  @include on("fablet-up") {
    width: auto;
    flex: 0 0 rem(150);
  }

  &__avatar {
    max-width: rem(120);
    display: block;
    margin: 0 auto;
  }

  &__name {
    margin: 0;
    padding: $spacer-std;
  }

  &__cta {
  }
}
