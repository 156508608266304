@import "~wonderbly-css/base";

.order-status {
  display: flex;
  align-items: baseline;
  .order-status__icon {
    display: block;
    margin-right: rem(6px);
    width: rem(10px);
    height: rem(10px);
    border-radius: 50%;
    margin-top: -#{rem(4)};
    background-color: get-colour("grey-medium");
    &--complete {
      background-color: get-colour("yellow");
    }
    &--making {
      background-color: get-colour("orange");
    }
    &--shipped {
      background-color: get-colour("green");
    }
    &--delivered {
      background-color: get-colour("green");
    }
    &--canceled {
      background-color: get-colour("red-dark");
    }
  }
  .order-status__title {
    font-size: rem(18px);
  }
  &.order-status--flush {
    .order-status__title {
      line-height: 1;
      margin-top: rem(4);
    }
  }
}
