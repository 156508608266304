@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.look-inside-modal {
  padding: get-size("more") get-size("less");
}
.look-inside {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  max-width: 450px;
  width: 100%;
  .look-inside-btn--close {
    color: #fff;
    padding: 0;
    align-self: flex-end;
    &:hover {
      color: #fff;
    }
  }
  .look-inside-panel {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    display: block;
    overflow: hidden;
  }
  .look-inside-slider {
    width: 100%;
    max-width: 450px;
  }
  .look-inside-container {
    padding: get-size("default") get-size("default") 0;
  }
  .look-inside-content {
    margin-bottom: get-size("less");
  }
  .look-inside-info {
    @extend %padded-top-small;
    @extend %padded-bottom-small;
    border-top: 1px solid get-colour("almost-light");
    border-bottom: 1px solid get-colour("almost-light");
  }
  .look-inside-tagline {
    font-weight: 600;
    line-height: rem(25px);
  }
  .look-inside-price {
    color: get-colour("green");
    font-weight: 600;
    .saving-price {
      top: 3px;
    }
  }
  .look-inside-description {
    @extend %spaced-top;
  }
  .look-inside-review-content {
    color: $color-sharpie;
    position: relative;
    background-color: $color-kraft-paper;
    padding: get-size("small");
    font-size: rem(16px);
    margin-bottom: rem(12px);
    p {
      @extend %spaced-none;
    }
    &:after {
      content: "";
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $color-kraft-paper;
      position: absolute;
      bottom: -6px;
      right: 10px;
      transform: rotate(-135deg);
    }
  }
  .look-inside-reviewer {
    color: $color-sharpie;
    font-size: rem(15px);
    text-align: right;
  }
  @media (min-width: 375px) {
    .look-inside-info {
      overflow: hidden;
    }
    .look-inside-tagline {
      float: left;
    }
    .look-inside-price {
      float: right;
    }
  }
  @media (min-width: 1024px) {
    max-width: rem(900px);
    align-self: center;
    .look-inside-panel {
      display: flex;
      & > div {
        width: 50%;
      }
    }
    .look-inside-content {
      max-height: calc(450px - 140px);
      overflow: auto;
    }
    &.look-inside--no-slider {
      max-width: rem(450px);
      .look-inside-panel > div {
        width: 100%;
      }
    }
  }
}

.look-inside-buttons > * + * {
  margin-top: 0.5rem;
}
