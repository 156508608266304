@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.app {
  background-color: get-colour("stone-light");
  transition: transform 200ms $bezier-out;

  #creation-form-widget {
    background-color: white;
  }
}

@include on("tablet-down") {
  .app-wrapper {
    transition: 0.4s;
  }
}

.app-nav-open {
  /*
  this is shonk, and it's here because of this:
  https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform
  the correct thing to do is to take the mobile nav outside of the scope of app
   */
  position: fixed;
  left: 0;
  right: 0;
  transform: translateX(calc(100vw - #{rem(50)}));

  @include on("fablet") {
    transform: translateX(calc(100vw - #{rem(60)}));
  }

  @include on("tablet") {
    transform: translateX(50vw);
  }
}

.app-overlay-active {
  position: fixed;
  left: 0;
  width: 100vw;
}

.wrapper {
  margin: 0 auto;
  max-width: rem(1780);
  display: block;
  width: 100%;

  section {
    clear: both;
  }
  // Page Overrides
  &.full-width {
    max-width: 100%;
  }
  &#cart,
  &#canvas,
  &#orders,
  &#order-details {
    background: transparent;
  }

  &:not(.wrapper--checkout) {
    background: white;
    overflow: hidden;
  }
}

.grecaptcha-badge {
  // Unable to use display: none; without using !important as the badge has display: block; inline
  margin-right: -100%;
}
