@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.basket-link {
  display: block;
  color: $color-sharpie;

  &:hover {
    color: $color-racing-green;
  }

  .ss-icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.basket-link-solo {
  @extend %padded-small-on-mobile;
  @extend %padded-less-on-fablet;
  @extend %padded-on-tablet;
  line-height: rem(30);

  .ss-icon {
    height: rem(30);
    width: rem(30);
    text-align: center;
    line-height: rem(30);
  }

  .basket-link-text {
    font-size: 1rem;
    transform: translateY(1px);

    @include on("fablet-down") {
      display: none;
    }

    @include on("desktop") {
      display: inline-block;
    }
  }

  &.basket-link--flush {
    align-items: center;
    display: flex;
    min-height: 36px;
    min-width: 1.5rem;
    padding: $spacer-sm 0;

    .basket-link-text {
      transform: none;
    }
  }
}

.basket-link-text {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;

  @include on("desktop") {
    display: none;
  }
}

.basket-link-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: $color-racing-green;
  margin-left: 0.25rem;
}

.basket-link-number {
  @extend %circled;
  background: $color-racing-green;
  color: $color-bright-smile;
  font-size: rem(10);
  line-height: 1.5;
  height: rem(16);
  width: rem(16);
  text-align: center;
  position: absolute;
  right: rem(-4);
  top: rem(-2);
}
