@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~mixins";
@import "~chameleon/helpers/box";

.user-form {
  background-color: $color-kraft-paper;
  padding: get-size("bigger") 0;
  text-align: center;
  .js & .tooltip--alert:not(.tooltip--absolute) {
    @extend %spaced-bottom-small;
  }
}

.user-form-alert {
  max-width: rem(540);
  margin: 0 auto get-size("default");
  padding: get-size("small");
  .rich-text {
    &,
    p:first-child {
      @extend %spaced-top-none;
    }
    p:last-child {
      @extend %spaced-bottom-none;
    }
  }
}

.user-form-panel {
  max-width: rem(540);
  margin: 0 auto;
  padding: get-size("big");
  display: inline-block;
  vertical-align: top;
  text-align: left;
  .rich-text {
    @extend %spaced-top-none;
    @extend %spaced-bottom-default;
    h1,
    h2,
    h3 {
      margin-top: 0;
      text-align: center;
    }
    p {
      margin: 0;
    }
    img {
      margin: 0 auto;
      display: block;
    }
  }
  input + .button {
    margin-top: -10px;
  }
  .button + a {
    @extend %spaced-top-small;
    display: inline-block;
  }
}

.user-form__fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -#{$spacer-sm};
  & > .field-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .field-description {
      margin-top: $spacer-sm;
      .ss-icon {
        margin-right: $spacer-std;
      }
      .rich-text {
        margin: 0;
      }
    }
    &:not(:empty) {
      margin: rem(10) $spacer-sm;

      &.fieldset-title {
        margin-top: $spacer-md;
        margin-bottom: 0;
      }
    }
    @include on("tablet-up") {
      margin: $spacer-std $spacer-sm;
      .inline-label__tag {
        display: inline-block;
      }
      &.initial {
        flex-grow: 0;
      }
      &.auto {
        flex-grow: 1;
      }
    }
  }
  select {
    cursor: pointer;
  }
  .field-container.radio_input,
  .field-container.checkbox_input {
    .field-container__wrapper {
      display: flex;
      margin: $spacer-std 0;

      input {
        margin: 0 $spacer-std 0 0;
        width: 14px;
        flex-shrink: 0;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }

      label {
        .rich-text {
          &,
          & p {
            margin: 0;
          }
        }
        strong {
          font-weight: 500;
        }
      }
    }
  }

  .field-container.radio_input {
    .field-container__wrapper {
      display: flex;
      flex-direction: column;
    }
    .radio_input__option {
      &:not(.radio-input--custom-label) {
        align-items: flex-start;
      }

      .radio-input__label * {
        float: none;
      }
      .radio_input__option__label {
        .rich-text {
          @extend %spaced-bottom-none;
          font-size: rem(16);
        }
      }
      .radio_input__option__description .field-description {
        @extend %spaced-top-tiny;
        @extend %padded-top-tiny;
        @extend %padded-right-small;
        @extend %padded-bottom-tiny;
        @extend %padded-left-small;
        background: get-colour("almost-light");
        .rich-text {
          margin: 0;
        }
      }

      &:not(:last-child) {
        @extend %spaced-bottom-small;
      }
    }
  }

  .field-container.line_break {
    @extend %spaced-bottom;
    @include line-break(-#{$spacer-md});
    width: calc(100% + #{$spacer-xl});
    &:first-child {
      margin-top: get-size("less");
      margin-bottom: $spacer-md;
    }
  }
  .field-container.empty_break {
    flex-basis: 100%;
    height: 0;
    margin: 0;
  }

  .field-container.fieldset-title {
    width: 100%;
    @extend %font-style-heading;
    @extend %spaced-bottom-none;

    font-size: rem(20);
    @include on("tablet-up") {
      font-size: rem(22);
    }
  }

  .button--unstyled {
    margin: 0;
    padding: 0;
  }

  &--one-line {
    .field-container.submit {
      .inline-label__tag {
        width: 1px;
      }
    }
  }
  &--old-form {
    flex-direction: column;
    .marketing_opt_in {
      flex-direction: row;
      input {
        width: 14px;
        margin-right: $spacer-std;
      }
    }
  }

  .js & .tooltip--alert:not(.tooltip--absolute) {
    margin: $spacer-std 0;
    &.error-tooltip--base {
      margin: $spacer-std $spacer-sm;
    }
  }
}

.user-form.user-form--shipping-details,
.user-form.user-form--edit-shipping-details,
.user-form.user-form--shipping-methods,
.user-form.user-form--add-contact-details {
  @extend %padded-none;
  @extend %padded-none-on-tablet-down;
  text-align: left;
  background-color: transparent;

  .user-form-panel {
    max-width: none;
    width: 100%;
    padding: get-size("default");
  }

  .panel-form-title {
    @extend %font-style-heading;
    @extend %spaced-bottom-none;
  }

  form {
    .rich-text p {
      @extend %spaced-none;
    }

    .field-description {
      display: flex;
      align-items: baseline;
      font-size: rem(14);

      .ss-icon {
        @extend %spaced-right-tiny;
        color: get-colour("stone-dark");
      }
    }
  }
}

.user-form.user-form--shipping-details,
.user-form.user-form--shipping-methods,
.user-form.user-form--add-contact-details {
  .panel-form-title,
  .button {
    font-size: rem(20);
    @include on("tablet-up") {
      font-size: rem(22);
    }
  }

  .field-container.submit {
    &:not(:last-child) {
      margin-top: get-size("small");
      margin-bottom: get-size("default");
    }

    .other-payment-methods {
      margin-top: get-size("less");
    }

    .items-container {
      .items {
        margin: get-size("less") 0;
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);
        justify-content: center;

        img {
          height: rem(22);
        }
      }
    }
  }
}

.user-form.user-form--add-contact-details.user-form--add-contact-details {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: get-size("default");
  }
}

.user-form--shipping-methods {
  .radio_input__option__content--shipping-method {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .radio_input__option__description--shipping-method {
    flex: 1 100%;
  }

  .radio_input__option__label--shipping-method,
  .price--shipping-method,
  .radio_input__option__description--shipping-method strong:first-child {
    font-weight: 600;
    color: $color-sharpie;
  }

  .price--shipping-method {
    .price--shipping-method-crossed {
      @extend %font-style-base;
      @extend %text-base;
      text-decoration: line-through;
      margin-right: $spacer-std;
    }
  }

  .radio_input__option__content--is-checked {
    .radio_input__option__label--shipping-method,
    .price--shipping-method,
    .radio_input__option__description--shipping-method strong:first-child {
      color: get-colour("green");
    }
  }
}
