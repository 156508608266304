@import "~wonderbly-css/mixins/responsive";

.choose-your-country {
  margin: 50px 0;
  .row > div {
    margin: 0 auto;
    display: block;
    @include on("fablet-down") {
      width: 100%;
    }
  }
  .align {
    &-center {
      text-align: center;
      display: block;
    }
  }
  .country-list {
    display: flex;
    flex-flow: wrap;
    .country-option {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
      img {
        width: 100%;
        max-width: 80px;
      }
      @include on("fablet-up") {
        width: 25%;
      }
      @include on("wide-up") {
        width: 20%;
      }
    }
    .country-option-text {
      text-align: center;
    }
  }
}
