@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.flash-messages {
  font-family: $font-family-display;
  width: 100%;
  position: relative;
  z-index: 5;
  transition: 0.5s $bezier-out 0.05s;
  will-change: top;
}

.flash-messages-container--error {
  @extend %padded-small;
  background: get-colour("red");
  color: white;
}

.flash-messages-container--notice {
  @extend %padded-small;
  background: get-colour("yellow");
  color: get-colour("grey");
}
