@import "~wonderbly-css/base";

.MultiLevelForm {
  &__active-form {
    display: flex;
    flex-direction: column;
    &__content {
      margin-bottom: $spacer-sm;
      h3 {
        margin: 0;
      }
    }
    &__image {
      min-width: 100%;
      margin: 0 -#{$spacer-md} $spacer-sm;
      img {
        width: 100%;
        float: left;
      }
    }
    &__container {
      flex-basis: 100%;
      .ScrollableCards {
        margin-top: $spacer-md;
      }
    }
    &__back.button {
      margin-top: $spacer-lg;
      padding: 0;
      color: get-colour("green");
      .Arrow {
        margin-right: $spacer-sm;
      }
    }
    @include on("tablet-up") {
      margin-top: $spacer-lg;
      flex-direction: row;
      &__image {
        min-width: 50%;
        padding-right: $spacer-lg;
        margin: 0;
      }
      &__back.button {
        margin-top: 0;
        margin-right: $spacer-lg;
      }
    }
  }
  &__list {
    display: grid;
    gap: $spacer-md;
    &__item {
      cursor: pointer;
      padding: $spacer-md;
      background-color: #fff;
      border-radius: rem(5);
      box-shadow: 0px rem(2) rem(2) rgba(172, 169, 157, 0.15),
        0px rem(8) rem(20) rgba(172, 169, 157, 0.2);
      &__title {
        margin-bottom: $spacer-sm;
      }
      &__content {
        display: flex;
        align-items: flex-start;
        p {
          margin: rem(2) 0 0;
        }
      }
      .complete-tick {
        margin-right: rem(12);
        &__icon {
          margin-top: -#{rem(2)};
        }
      }
    }
    @include on("tablet-up") {
      grid-template-columns: repeat(3, 1fr);
    }
    @include on("desktop-up") {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__controls {
    display: flex;
    flex-direction: column;
    margin-top: $spacer-md;
    @include on("tablet-up") {
      flex-direction: row-reverse;
      margin-top: $spacer-lg;
    }
  }
}
