@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.bear-card .bear-card-inner.button--unstyled {
  @extend %shadowed;
  @extend %rounded;
  @extend %spaced-right;
  background: white;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  img {
    max-width: 5rem;
  }

  .inline-label {
    @extend %spaced-top;
  }
}

.bear-fam-active .bear-card {
  @extend %padded-on-tablet-up;
  @extend %padded-right-on-fablet-down;
  @extend %spaced-bottom-small;
  min-width: rem(270);
  position: relative;

  &:last-child {
    @extend %padded-right-none-on-fablet-down;

    &:before {
      @extend %spaced-right-huge-on-tablet-up;
      left: 100%;
      top: 0;
      content: "";
      visibility: hidden;
      display: block;
      height: 1em;
      position: absolute;
      width: rem(20);
    }
  }

  &:first-child {
    @extend %spaced-left-huge-on-tablet-up;
  }

  .prismic-rich-text > p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bear-card-input-wrapper {
  position: relative;
}

.bear-fam-subs .bear-card {
  @extend %spaced-small-on-tablet-up;
  @extend %padded-right-small-on-fablet-down;
  @extend %padded-top-small-on-fablet-down;
  width: 33%;
  align-self: stretch;

  img {
    max-height: 4rem;
    display: block;
    margin: 0 auto;
  }

  @include on("tablet-up") {
    width: 4.5rem;
    height: 4.5rem;

    img {
      height: 100%;
    }
  }
}

.bear-card-add {
  @extend %circled;
  background: get-colour("green");
  height: 20px;
  width: 20px;
  position: absolute;
  right: rem(-5);
  top: rem(-5);
  text-indent: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:before,
  &:after {
    background: white;
    height: 2px;
    width: 10px;
    content: "";
    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 1px);
  }

  &:after {
    transform: rotate(90deg);
  }
}

.bear-card-inner--disabled {
  pointer-events: none;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
  }
}
.bear-card-inner--littlest {
  pointer-events: none;
}
.bear-card-inner--active {
  pointer-events: none;
  button,
  input {
    pointer-events: initial;
  }
}

.bear-card-remove.button--unstyled {
  color: get-colour("white");
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  height: rem(32);
  line-height: rem(32);
  text-align: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: rem(36);
  cursor: pointer;
  transition: 0.3s ease;
  opacity: 0;
  z-index: 1;

  &:before {
    @extend %circled;
    background-color: get-colour("red");
    content: "";
    height: rem(32);
    width: rem(32);
    position: absolute;
    left: rem(2);
    top: rem(0);
    transition: 0.25s ease;
  }

  &:hover,
  &:focus {
    outline: none;
    color: get-colour("white");

    &:before {
      background-color: get-colour("red-dark");
    }
  }

  &:active {
    outline: none;
    color: get-colour("white");

    &:before {
      background: get-colour("almost-black");
    }
  }

  .bear-card:hover .button:not(.bear-card-inner--disabled) &,
  .bear-card:focus .button:not(.bear-card-inner--disabled) & {
    opacity: 1;
  }
}
