@import "~wonderbly-css/mixins/responsive";
@import "~chameleon/helpers/box";

.grid__column {
  section {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:not(:first-child) {
    @extend %spaced-top-default-on-tablet-down;
  }
  &:not(:last-child) {
    @extend %spaced-bottom-default-on-tablet-down;
  }

  @include on("desktop-up") {
    &.grid__column--desktop-width-auto {
      width: auto;
    }

    &.grid__column--desktop-width-70-percent {
      width: 70%;
    }

    &.grid__column--desktop-width-50-percent {
      width: 50%;
    }

    &.grid__column--desktop-width-30-percent {
      width: 30%;
    }
  }
}
