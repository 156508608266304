@import "~wonderbly-css/base";

.SiteNavHeader {
  position: sticky;
  top: 0;
  // Promo banner is 6;
  z-index: 7;
  background-color: $color-bright-smile;

  &__links-header {
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include on("desktop-up") {
      border-bottom: solid rem(1.5) $color-kraft-paper;
    }
  }

  &__additional-links {
    display: none;
    max-width: rem(1400);
    width: 100%;
    margin: 0 auto;

    @include on("desktop-up") {
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__button {
    position: relative;
    appearance: none;
    background: none;
    border: 0;
    color: $color-sharpie-60;
    display: flex;
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      inset: -1.5rem;
    }
  }

  &__navigation-header {
    position: relative;
    box-shadow: rem(0) rem(1) rem(3) rem(0) rgba($color-sharpie, 0.2);
  }

  &__container-content {
    display: flex;
    max-width: rem(1400);
    margin: 0 auto;
  }

  &__primary-content {
    width: 100%;
    display: flex;
    align-items: center;

    @include on("desktop-up") {
      display: none;
    }
  }

  &__hamburger {
    align-items: center;
    display: flex;
    color: $color-racing-green;
  }

  &__logo {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 4rem;

    @include on("desktop-up") {
      padding-top: get-size("small");
      padding-bottom: get-size("small");
      max-width: rem(96);
    }

    > svg {
      height: auto;
      width: auto;
      max-height: 40px;
    }
  }

  &__secondary-content {
    display: flex;
    font-family: $font-family-sans-serif;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    @include on("desktop-up") {
      width: auto;
    }
  }

  &__content-action {
    white-space: nowrap;

    & + & {
      margin-left: $spacer-std;
    }
  }

  &__choose-country-modal {
    display: none;
    // This is needed to keep
    // the open country toggle
    // positioned to the bottom
    // of the nav header, whether
    // the page is scrolled or not
    position: relative;

    @include on("desktop-up") {
      display: block;
    }
  }

  &__desktop-menu {
    display: none;

    @include on("desktop-up") {
      display: block;
    }
  }

  &__desktop-overlay {
    display: none;

    @include on("desktop-up") {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.6;
      background-color: $color-sharpie;
      z-index: 5;
    }
  }
}
