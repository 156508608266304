@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.text-over-background {
  overflow: hidden;
  position: relative;

  @include on("tablet-down") {
    padding: get-size("default");
  }

  @media (min-width: 1440px) {
    margin-left: get-size("default");
    margin-right: get-size("default");
    margin-bottom: get-size("default");
    padding-bottom: get-size("default");
  }
}

.text-over-background-image {
  .responsive-image & {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: none;
  }
}

.text-over-background-text {
  @extend %shadowed;

  background: get-colour("white");
  padding: get-size("huge") get-size("big");
  margin: rem(120) auto;
  max-width: rem(1020);
  position: relative;
  text-align: center;

  @include on("tablet-down") {
    padding: get-size("default");
  }
}

.text-over-background-title {
  @extend %text-giga;
  @extend %spaced-bottom-small;
}

.text-over-background-description {
  @extend %text-mega;
  @extend %spaced-top-none;
  @extend %spaced-bottom;
  color: get-colour("grey-medium");
  max-width: rem(820);
  margin-left: auto;
  margin-right: auto;
  .rich-text {
    margin-top: 0;
  }
}

.text-over-background-button {
  max-width: 100%;
  width: rem(400);
}
