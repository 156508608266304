@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.addon {
  @extend %spaced-bottom;
  @extend %spaced-left-small;
  @extend %spaced-right-small;
  flex: 1;
  flex-basis: 100%;
  max-width: 500px;
  float: left;
  cursor: pointer;
  .panel {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  input {
    display: none;
  }
  h4,
  p {
    user-select: none;
    width: 100%;
  }
  @include on("tablet-up") {
    flex-basis: calc(50% - 20px);
    max-width: calc(1000px / 2);
    .addon-items--has-look-inside &:not(.addon--has-look-inside) {
      .panel {
        height: calc(100% - 36px);
      }
    }
  }
  @include on("desktop-up") {
    &:not(.addon--large) {
      flex-basis: calc(33.33% - 20px);
      max-width: calc(1000px / 3);
    }
  }
}

.addon-image {
  overflow: hidden;
  border-bottom: 1px solid get-colour("stone-light");

  img {
    width: 100%;
    margin: 0 auto;
    float: none;
    display: block;
  }
}

.addon-wrapper {
  @extend %padded;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
}

.addon-checkbox {
  @extend %rounded;
  display: flex;
  align-items: center;
  justify-content: center;
  width: get-size("big");
  height: get-size("big");
  border: 1px solid get-colour("grey-light");
  background: get-colour("almost-light");
  position: relative;
  color: $color-bright-smile;
  transition: 0.25s ease;

  .addon-checkbox-tick {
    transform: scale(0);
    transition: 0.25s transform ease;
  }

  .addon--selected & {
    background-color: get-colour("green");
    border-color: get-colour("grey-medium");

    .addon-checkbox-tick {
      transform: scale(1);
    }
  }
}

.addon-content {
  @extend %spaced-left;
  width: calc(100% - 60px);
}

.addon-price {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  color: get-colour("red");

  &,
  p {
    display: inline;
  }
  p.current {
    color: $text-color-base;
  }
}

.addon--has-look-inside {
  position: relative;
  margin-bottom: rem(56px);
}

button.addon-look-inside-btn--open {
  margin: 1rem;
  font-size: rem(16px);
  background-color: transparent;
  color: $color-sharpie;

  &:active {
    color: $color-sharpie;
  }

  &:is(:hover, :focus):not(:active, [disabled]) {
    background-color: $color-sharpie;
    color: $color-bright-smile;
  }
}
