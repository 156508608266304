@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.formats {
  @include on("fablet-up") {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
  }

  & > [class*=" col-"],
  & > [class^="col-"] {
    display: flex;
    @extend %spaced-top-big-on-fablet-up;
    @extend %spaced-top-on-mobile;
    &.col-1-3,
    &.col-1-4 {
      width: calc(25% - 1px); // I hate Safari
      @include on("tablet-down") {
        width: calc(50% - 1px); // I hate Safari
      }
      @include on("mobile") {
        width: calc(100% - 1px); // I hate Safari
      }
    }
    &.col-1-2 {
      width: calc(50% - 1px); // I hate Safari
      @include on("mobile") {
        width: calc(100% - 1px); // I hate Safari
      }
    }
  }
}
