@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.addons {
  overflow: hidden;
  background-color: get-colour("stone-light");
}
.addons-content {
  text-align: center;
  margin: rem(50px) 0;
}
.addon-items {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
  justify-content: center;
  flex-direction: column;
  flex-flow: row wrap;
  @include on("tablet-up") {
    flex-direction: row;
  }
  @include on("desktop-up") {
    max-width: 1000px;
    margin: 0 auto;
  }
}
.addons-actions {
  padding-top: $spacer-md;
  padding-bottom: $spacer-xl;

  & > .container-guttered {
    overflow: hidden;
  }

  .button {
    width: 100%;
    @include on("desktop-up") {
      width: auto;
    }
  }
}
.addons-next {
  float: right;
}
