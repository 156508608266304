@import "~chameleon/helpers/box";

.companion-form-row {
  @extend %spaced-top;
  display: flex;
}

.companion-form-field--name {
  flex: 1.5;
}
.companion-form-field-container {
  position: relative;
}
.js .tooltip--alert:not(.tooltip--absolute).companion-form-gender-tooltip {
  margin-bottom: 10px;
}
.companion-form-cta {
  flex: 0.5;
}
