@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.line-item-ii {
  @extend %padded-small-on-fablet-down;
  @extend %padded-on-tablet-up;
  position: relative;
  min-height: rem(135);
  display: flex;

  @include on("mobile") {
    flex-wrap: wrap;
    padding: get-size("less");
  }

  .line-item-ii-title {
    @extend %font-style-display;

    span {
      font-weight: $font-weight-base;
    }
  }

  .line-item-ii-links {
    @extend %spaced-top-small;

    @include on("mobile") {
      width: 100%;
      padding-top: rem(13px);
      border-top: 1px solid get-colour("stone");
    }

    .Icon {
      display: inline-block;
    }

    .ss-icon {
      padding-right: rem(6px);
    }
  }

  .line-item-ii-edit {
    padding: 0.25rem 0;
    color: get-colour("green");
    border: none;
    display: block;
    width: 100%;
    &:hover,
    &:focus,
    &:active {
      color: get-colour("green-dark");
    }

    & > .Icon {
      position: relative;
      top: -0.125rem;
    }

    @include on("mobile") {
      &:not(.line-item-ii-edit--preview) {
        margin-top: rem(8px);
      }
    }

    .ss-icon {
      font-size: rem(12px);
      top: rem(-1);
    }
  }
}

.line-item-ii-content-wrapper {
  flex: 1;
  display: flex;

  .line-item-ii-content {
    flex-grow: 1;
    margin-top: rem(5px);
    padding-left: get-size("small");

    @include on("mobile") {
      padding-right: rem(20px);
    }

    @include on("tablet-up") {
      display: flex;
      justify-content: space-between;
    }
    @include on("fablet-up") {
      border-left: 1px solid get-colour("stone");
      padding-left: get-size("less");
    }
  }

  .line-item-option {
    &.line-item-option--empty {
      @extend %spaced-top-none;
    }
  }
}

.line-item-ii-customisation,
.line-item-ii-option {
  display: block;
}

.line-item-ii-price {
  @extend %spaced-top-tiny-on-fablet-down;
  font-family: $font-family-sans-serif;

  .saving-price {
    margin-left: 0;
    margin-top: $spacer-sm;

    &--saved {
      color: get-colour("green");
    }
  }
  @include on("tablet-up") {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: rem(220);
    .saving-price {
      text-align: right;
    }
  }
}

.line-item-ii-delete {
  color: $color-ketchup;
  @extend %spaced-left-small;
  background: none;
  padding: 0;
  border: 0;
  height: 1.5rem;
  width: 1.5rem;
  line-height: rem(20);
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  top: -0.5rem;
  right: -0.5rem;

  @include on("fablet-down") {
    position: absolute;
    margin: 0;
    top: 0.5rem;
    right: 0.5rem;
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover,
  &:focus {
    outline: none;
    &:before {
      background-color: get-colour("red-dark");
    }
  }

  &:active {
    outline: none;
    &:before {
      background: get-colour("almost-black");
    }
  }
}
