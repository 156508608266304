@import "~wonderbly-css/base";

$break-to-single-stack: 1060px;

.Highlights {
  text-align: center;
  padding-bottom: $spacer-xl;

  &__modal-header {
    text-align: right;
  }

  &__modal &__modal__btn--cancel {
    color: get-colour("white");
    padding-right: 0;
  }

  &__modal-inner {
    max-width: rem(450px);
    width: 100%;
    align-self: flex-start;
    padding: $spacer-xl $spacer-lg;

    @media (min-width: $break-to-single-stack) {
      max-width: rem(900px) + ($spacer-lg * 2);
      align-self: center;
    }
  }

  &__modal .carousel-item-wrap {
    background: get-colour("white");
  }

  &__slide-inner {
    @media (min-width: $break-to-single-stack) {
      display: flex;
      align-items: center;
    }
  }

  &__slide__image {
    width: 100%;

    @media (min-width: $break-to-single-stack) {
      width: rem(450px);
    }
  }

  &__subtitle {
    @extend %text-milli;
    color: get-colour("grey-light");
    margin-bottom: $spacer-sm;
  }

  &__title {
    @extend %text-giga;
  }

  &__description {
    margin: $spacer-std 0 0;
  }

  &__slide__content {
    padding: $spacer-lg;
    white-space: initial;

    @media (min-width: $break-to-single-stack) {
      width: rem(450px);
    }
  }
}
