@import "~chameleon/helpers/box";

.basket-item {
  white-space: nowrap;

  @supports (display: flex) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.basket-item-price {
  flex: 1;
}

.basket-item-heading {
  flex: 1 auto;
  flex-grow: 3;
}

.basket-item-price {
  @extend %spaced-left-big-on-desktop-up;
  @extend %spaced-left;
}
