@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.help-content-modules {
  padding-top: get-size("less");
  background-color: get-colour("stone-light");
  border-top: rem(1) solid get-colour("stone");

  @include on("fablet-up") {
    padding-top: get-size("default");
    display: flex;
  }
}

.help-content-module {
  padding: $spacer-md;
  background-color: $color-bright-smile; // no wonderbly design color for this one
  display: flex;
  flex-basis: 33.33%;

  &:not(:last-child) {
    @extend %spaced-right-less-on-fablet-up;
    @extend %spaced-bottom-less-on-fablet-down;
  }

  > .Icon--legacy {
    flex-shrink: 0;
    position: relative;
    top: 4px;
  }
}

.help-content-module-content {
  margin-left: rem(12);

  .rich-text p {
    @extend %spaced-none;
  }
}

.help-content-module-title {
  @extend %spaced-none;
  @extend %text-mega;
}
