@import "~wonderbly-css/base";

.ShopNavigation__section {
  background-color: var(--brand-background-color);
}

@include on("desktop-up") {
  .ShopNavigation {
    padding-top: rem(80);
    padding-bottom: calc(rem(80) + rem(24)); // Add the height of the cutout
    display: block;

    &__container {
      display: flex;
    }

    &__menu {
      flex: 1;
    }

    &__primary-list {
      display: grid;
      grid-template-columns: 33% auto;
      grid-row-gap: rem(24);
      position: relative;
      padding: 0;
      margin: 0;
    }

    &__nav-link {
      text-decoration: none;
      font-size: rem(20);
      font-weight: 400;
      color: $color-bright-smile;

      &:hover {
        color: $color-bright-smile;
        opacity: 0.6;
      }
    }

    & .SectionHeading__heading {
      margin-bottom: rem(64);
    }

    &__nav-container {
      display: grid;
      grid-template-columns: 30% 30% auto;
      column-gap: 2rem;
      grid-column: 2;
      position: absolute;
      inset: 0;
    }

    &__image {
      max-width: 370px;
    }

    &__nav-item {
      font-family: $font-family-display;
      font-size: 1.2rem;
      cursor: pointer;
      grid-column: 1;
      display: flex;
    }

    &__nav-item-title {
      display: flex;
      align-items: center;
    }

    &__text {
      font-size: rem(24);
      line-height: rem(32);
      opacity: 0.6;

      &--active {
        opacity: 1;
        margin-left: 0.5rem;
      }
    }

    &__secondary-list {
      font-family: $font-family-sans-serif;
      font-size: 1rem;
      padding: 0;
      margin: 0;
    }

    &__link-item {
      margin-bottom: 1rem;
      margin-top: 0;
      list-style-type: none;
    }
  }
}
