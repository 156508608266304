@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.call-to-action {
  text-align: center;
  @extend %spaced-bottom-more;

  @include on("fablet-up") {
    margin-bottom: get-size("bigger");
  }

  &.call-to-action--left {
    text-align: left;
  }
  &.call-to-action--right {
    text-align: right;
  }
}
