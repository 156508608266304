.PlaceholdImage__image--wrapper {
  position: relative;

  .PlaceholdImage__image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}
