@import "~wonderbly-css/base";
@import "~wonderbly-css/palette";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.inscription-cta-container {
  float: right;
  text-align: right;
  .button {
    @extend %widest-on-fablet-down;
    @extend %spaced-top-small-on-fablet-down;
  }
}

.inscription-textarea {
  line-height: (11 / 7);
  resize: none;
  height: auto;
  @include on("tablet-up") {
    height: calc(100% - 60px);
    position: absolute;
    top: 0;
  }
}

@media all and (max-width: 840px) {
  .canvas-drawer-content .inscription-textarea {
    height: rem(160);
  }
}

.inscription-form {
  position: relative;
  .tooltip {
    bottom: calc(100% + 5px);
  }
  @include on("tablet-up") {
    &:before {
      content: "";
      display: block;
      padding-top: calc(46.5% - 50px);
    }
  }
}

.inscription-image {
  overflow: hidden;
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: percentage(274/492);
  }

  img {
    left: 0;
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;

    @supports (transform: translateY(-50%)) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @include on("fablet-down") {
    max-height: rem(300);
  }

  &:before {
    padding-top: percentage(275 / 590);
  }
}

.js .tooltip--alert:not(.tooltip--absolute).inscription-tooltip {
  margin-bottom: 10px;
}
