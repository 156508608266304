.magnifier {
  cursor: none;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  img {
    float: left;
  }
}
.magnifier-initial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.magnifier-no-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
