@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.cart-order-summary.panel {
  @extend %padded;
  .cart-summary-container & {
    @extend %spaced-top-small-on-tablet-down;
    @extend %spaced-bottom-small;
  }
  font-family: $font-family-sans-serif;
  padding-left: rem(25px);
  padding-right: rem(25px);
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    @extend %spaced-none;
  }

  .see-exact-costs {
    color: get-colour("grey-light");
  }
}

.cart-order-summary-row {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.cart-order-summary-row--discount {
  color: get-colour("red");
  @extend %padded-top-tiny;
  @extend %padded-left-small;
  @extend %padded-right-small;
  padding-bottom: rem(2px);
  margin-left: -#{get-size("small")};
  margin-right: -#{get-size("small")};
  background-color: #fdf1ef;
}

.cart-order-summary-row--discount-code {
  @extend %padded-left-small;
  @extend %padded-right-small;
  padding-top: rem(7px);
  padding-bottom: rem(3px);
  background-color: get-colour("stone-light");
  border-bottom-left-radius: rem(3px);
  border-bottom-right-radius: rem(3px);
  display: flex;
  .cart-order-summary-list &.cart-order-summary-row:not(:first-child) {
    @extend %spaced-top-none;
  }
  .cart-order-summary-label,
  .button {
    @extend %text-milli;
  }
  .cart-order-summary-label {
    @extend %padded-left-tiny;
    line-height: rem(23px);
    width: 100%;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .button {
    @extend %padded-none;
    color: get-colour("stone-dark");
    flex-shrink: 0;
    float: right;
  }
}

.cart-order-summary-row--large {
  .cart-order-summary-label,
  .cart-order-summary-price {
    @extend %text-mega;
  }
}

.cart-order-summary-row--total {
  padding-block: 1rem;
  border-top: 1px solid $color-sharpie-10;
}

.cart-order-summary-row--bottom-hint {
  padding-bottom: 1em;
  font-family: $font-family-display;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  & .ss-icon {
    font-family: inherit;
    border: 1px solid;
    border-radius: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 1.25em;
    height: 1.25em;

    &:before {
      display: block;
      font-family: inherit;
      line-height: 0.9em;
    }
  }
}

.cart-order-summary-list {
  @extend %spaced-bottom-small;
  flex: 1;
  .cart-order-summary-row {
    &:not(:first-child) {
      @extend %spaced-top-small;
    }
    &:last-child {
      @extend %spaced-bottom-small;
    }
  }
}

.cart-order-summary-price,
.checkout-line-item-price {
  text-align: right;
  min-width: rem(82);

  .cart-order-summary-price-crossed {
    text-decoration: line-through;
    margin-right: $spacer-std;
  }
}
