@import "~wonderbly-css/base";

.adventurer {
  position: relative;
}

.js .tooltip--alert:not(.tooltip--absolute).adventurer-gender-tooltip {
  margin-bottom: 10px;
}

.adventurer-phototype-input {
  img {
    max-height: 6.66667rem;
    margin: 0 auto;
    pointer-events: none;
  }
  &.labelled-radio__label {
    border: 2px dashed $color-sharpie-10;
    padding: 0;
    input:not(:checked) + & {
      background-color: transparent;
    }
    input:checked + & {
      border: 2px solid $color-racing-green;
    }
  }
}

.adventurer-phototypes-title {
  display: none;
  @include on("desktop-up") {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
}
