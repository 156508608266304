@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";

.canvas-drawer-message {
  display: inline-block;
  color: #fff;
  width: calc(100% - 30px);
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: rem(8px) get-size("less") get-size("tiny");
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
  .canvas-drawer-message-wrapper {
    display: flex;
    align-items: center;
  }
  .canvas-drawer-massage-content {
    width: 100%;
    .rich-text,
    p {
      margin: 0;
    }
  }
  .complete-tick {
    line-height: 1.5;
    width: 24px;
    height: 24px;
    margin-right: get-size("small");
  }
  @include on("tablet-up") {
    width: auto;
    max-width: 45%;
  }
}

.canvas-drawer-message--hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}
