@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/base";

.CustomAdventurer {
  & .CustomAdventurer__input-hidden {
    display: none;
  }

  &__gender-select {
    position: relative;
  }

  &__gender-input {
    margin-bottom: $spacer-sm;
  }

  &__phototype-column {
    margin-bottom: $spacer-sm;
  }

  &__phototype-input {
    img {
      pointer-events: none;
      max-width: 100%;
    }
    &:not(:checked) + .labelled-radio__label {
      background: none;
      border: rem(2) dashed $color-kraft-paper;
    }
  }

  // Tooltip and panel overrides
  .tooltip--visible {
    margin-top: 0;
    padding-left: 0;
    padding-bottom: $spacer-sm;
    .js &.phototype-error-tooltip {
      margin-bottom: $spacer-sm;
    }
    @include on("desktop-up") {
      max-width: rem(320);
      bottom: calc(100% + #{rem(10)});
      .js & {
        position: absolute;
        left: calc(50% + #{rem(19)});
        transform: translate(-50%, 0%);
      }
    }
  }

  &__phototypes {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    height: 0;

    &--visible {
      height: auto;
      opacity: 1;
      visibility: visible;
      @include on("tablet-down") {
        padding: get-size("more") 0;
      }
    }
  }

  @include on("tablet-down") {
    .tooltip {
      box-shadow: none;
      background-color: transparent;
      &:after {
        display: none;
      }
      .panel {
        background: none;
      }
    }
  }

  @include on("desktop-up") {
    .tooltip-visible--boy:after {
      right: calc((100%) / 3 * 2 + #{rem(50)});
    }
    .tooltip-visible--girl:after {
      right: calc((100%) / 2);
    }
    .tooltip-visible--neutral:after {
      right: calc((100%) / 3 - #{rem(50)});
    }
  }

  .panel {
    height: auto;
    overflow: visible;
    @include on("desktop-up") {
      padding: get-size("small") get-size("small") 0;
    }
  }
}
