@import "~wonderbly-css/base";

.SiteFooter {
  background: get-colour("green-dark");
  padding-top: $spacer-xxl;
  margin-top: -1rem;

  a {
    &:hover,
    &:active {
      color: $color-kraft-paper;
    }
  }

  &__container {
    padding-bottom: $spacer-xl;

    @include on("tablet-up") {
      display: grid;
      grid-template-columns: 3fr 1fr;
      column-gap: $spacer-lg;
    }

    @media (min-width: 900px) {
      column-gap: rem(64);
    }

    @include on("wide-up") {
      column-gap: rem(128);
    }
  }

  &__links {
    @include on("tablet-up") {
      display: grid;
      grid-template-columns: repeat(3, auto);
      column-gap: $spacer-lg;
    }
  }

  &__list {
    h3 {
      font-family: $font-family-sans-serif;
    }
  }

  &__list-title {
    color: white;
    padding: $spacer-md 0 0;
    margin-bottom: 1rem;
    font-family: $font-family-sans-serif;
  }

  &__payment-icons {
    padding: $spacer-lg 0px;
    line-height: 0;
  }

  &__widget-col {
    text-align: center;
    padding-top: $spacer-xxl;

    @include on("tablet-up") {
      padding-top: $spacer-md;
    }
  }

  .payment-icon + .payment-icon {
    margin-left: rem(3);
  }

  .payment-icon {
    padding: $spacer-md 0;
    max-width: rem(42);

    @include on("tablet-up") {
      max-width: rem(38);
    }
  }
}
