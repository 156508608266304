@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";
@import "~mixins";

.contact-and-shipping {
  background-color: $color-bright-smile;
  padding: get-size("less") get-size("less") get-size("small");
  font-size: rem(16);

  &--loading {
    height: auto;
    margin: $spacer-md 0;
  }

  @include on("tablet-down") {
    margin-bottom: get-size("default");
  }
}

.contact-and-shipping__content {
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }

  &:not(:last-child) {
    position: relative;

    &::after {
      @include line-break(rem(-15));
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: rem(-14);
    }
  }

  &:not(:first-child) {
    margin-top: get-size("less");
  }

  > div {
    display: flex;
    margin-bottom: get-size("small");

    @include on("tablet-down") {
      flex-wrap: wrap;
    }
  }

  .contact-and-shipping__content-title {
    color: $color-sharpie;
    width: rem(90);
    font-weight: 500;
    margin-right: rem(8);
    flex-shrink: 0;
  }

  .contact-and-shipping__edit-link {
    @extend %spaced-left-default;
  }
}

.contact-and-shipping--vertical-layout {
  background-color: white;
  padding: get-size("less");

  @include on("tablet-up") {
    padding: get-size("default");
  }

  .contact-and-shipping__content {
    > div {
      flex-direction: column;
    }

    .contact-and-shipping__content-title {
      color: get-colour("grey");
      font-weight: $font-weight-bold;
      font-size: rem(18);
    }
  }

  .contact-and-shipping__content {
    &:not(:last-child) {
      &::after {
        @include line-break(rem(-20));
      }
    }
  }
}
