@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.qualities-wrapper {
  display: grid;
  row-gap: $spacer-std;
  grid-template-rows: auto;
  margin-bottom: $spacer-md;
  @include on("tablet-up") {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $spacer-std;
  }
  @include on("desktop-up") {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $spacer-std;
  }
}
.qualities-item {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    &:checked {
      + label {
        border-color: $color-racing-green;
      }
    }
  }
  label {
    @extend %rounded;
    display: flex;
    position: relative;
    z-index: 5;
    background-color: #fff;
    padding: $spacer-std rem(65) $spacer-std rem(15);
    border: rem(3) solid #fff;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: rem(15);
    }
    .complete-tick {
      line-height: 1.5;
    }
    .complete-tick--ticked {
      position: absolute;
      top: 50%;
      right: rem(20);
      transform: translateY(-50%);
    }
  }
}

.qualities-cta-button {
  @extend %spaced-top-small-on-fablet-down;
  text-align: right;

  .button {
    @extend %widest-on-fablet-down;
  }
}
