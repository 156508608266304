@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/functions/palette";

.payment-method-ii {
  .payment-method-ii__paylater-text {
    padding: $spacer-md 0;
  }
  .payment-method-ii__paylater-buttons {
    padding-top: $spacer-md;
  }
  .payment-method-ii__header {
    img {
      max-height: rem(30);
      float: left;
    }
  }
  .payment-method-ii__content {
    position: relative;
    .rich-text {
      *:first-child {
        margin-top: 0;
      }
      *:last-child {
        margin-bottom: rem(8);
      }
    }
  }
  .payment-method-ii--submitting {
    position: absolute;
    // This needs to be 101 as the Iframe that
    // gets generated via Paypal Checkout has a z-index of 100
    z-index: 101;
    width: calc(100% + #{get-size("more")});
    height: calc(100% + #{rem(32)});
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -#{rem(16)} -#{get-size("less")};
    background-color: rgba(get-colour("stone"), 0.6);
    cursor: not-allowed;
  }

  &:not(.collapsible-ii__item--disabled):not(
      .collapsible-ii__item--non-clickable
    ) {
    .payment-method-ii__header {
      &,
      img {
        cursor: pointer;
      }
    }
  }
  &:first-child {
    .collapsible-ii__header {
      border-top: none;
    }
  }
  &:last-child {
    .collapsible-ii__content {
      border-bottom-left-radius: rem(4);
      border-bottom-right-radius: rem(4);
    }

    &:not(.collapsible-ii__item--active) {
      .collapsible-ii__header {
        overflow: hidden;
        border-bottom-left-radius: rem(4);
        border-bottom-right-radius: rem(4);
      }
    }
  }
}
