@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";
@import "~chameleon/helpers/visibility";

.quick-checkout .button {
  &.button--applepay {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
    -apple-pay-button-style: black;
    -webkit-appearance: -apple-pay-button;
    &.button--disabled {
      opacity: 0.4;
    }
  }

  &.button--paypal,
  &.button--applepay {
    @extend %spaced-bottom-small;
    max-height: 45px;

    > * {
      visibility: hidden;
    }
  }
}

.quick-checkout {
  .widest {
    width: 100%;
  }
}

.quick-checkout-title {
  text-align: center;
}
