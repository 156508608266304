@import "~wonderbly-css/base";

.ShopNavigationTiles {
  position: relative;
  padding: 2rem 0;

  &__container {
    display: flex;
    column-gap: 1.5rem;
    overflow-x: auto;
    scroll-behavior: smooth;
  }

  &__tile {
    display: block;
    min-width: 200px;
    width: 100%;
  }

  &__tile-container {
    display: block;
  }

  &__image {
    position: relative;
    min-height: 15rem;
  }

  &__content {
    background-color: $color-kraft-paper;
    display: block;
  }

  &__text {
    padding: 1rem;
    color: $color-sharpie;
    margin: 0;
  }

  & > .BackgroundImage__image {
    width: 100%;
    background-size: contain;
  }
}
