@import "~wonderbly-css/mixins/responsive";
@import "~chameleon/helpers/box";

.add-to-cart {
  &:last-child {
    margin-bottom: 50px;
  }
  text-align: center;
  .add-to-cart-form {
    margin-top: 0;
  }
  button {
    @extend %padded-left-big-on-fablet-down;
    @extend %padded-right-big-on-fablet-down;
    @extend %padded-left-huge-on-tablet-up;
    @extend %padded-right-huge-on-tablet-up;
    @include on("tablet-up") {
      width: auto;
    }
  }
}
.add-to-cart-form {
  @extend %spaced-top;
}
