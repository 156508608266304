@import "~wonderbly-css/base";

.SitePrimaryNav {
  background: $color-bright-smile;
  list-style-type: none;
  padding: 0;
  max-width: rem(1400);
  margin: 0 auto;

  @include on("desktop-up") {
    display: flex;
    padding-left: $spacer-xl;
  }
}
