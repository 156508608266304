.site-loader {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
}
.site-loader--visible {
  display: flex;
}
.site-loader-panel {
  width: 100px;
  height: 100px;
}
