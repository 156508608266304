@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.value-props-ii {
  padding: get-size("more") 0;
  background-color: $color-kraft-paper;

  @include on("tablet-up") {
    .SectionHeading {
      display: none;
    }
  }
}

.value-props-ii-row {
  display: flex;
  flex-wrap: wrap;
  gap: rem(24px);
  @include on("tablet-up") {
    gap: rem(16px);
  }
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}

.value-prop-ii {
  display: flex;
  width: 100%;
  flex: 0 calc(50% - rem(12px));
  flex-direction: column;
  align-items: center;
  @include on("tablet-up") {
    flex-direction: row;
  }
  .value-prop-ii-shape {
    width: 96px;
    height: 96px;
    margin-top: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: rem(16px);
    }
  }
  .value-prop-ii-content {
    width: calc(100% - 40px);
    padding-top: rem(12px);
    text-align: center;
    @include on("tablet-up") {
      padding: 0 rem(12px);
      display: flex;
      align-items: center;
      text-align: left;
    }
    .rich-text {
      display: none;
    }
    p {
      margin: 0;
    }
  }

  .value-prop-ii-title {
    @extend %text-mega;
    font-family: $font-family-sans-serif;
    @include on("tablet-up") {
      margin-bottom: $spacer-sm;
      font-family: $font-family-display;
    }
  }

  .value-prop-ii-image {
    width: 100%;
    height: 100%;
  }

  @include on("tablet-up") {
    .value-prop-ii-content {
      display: block;
      .rich-text {
        display: block;
      }
    }
  }

  @include on("tablet-up") {
    .value-prop-ii-shape {
      width: 60px;
      height: 60px;
      margin-top: 0;
      span {
        font-size: rem(20px);
      }
    }
    .value-prop-ii-content {
      width: calc(100% - 60px);
    }
  }
  @media (min-width: 1024px) {
    flex: 0 25%;
    margin-bottom: 0;
  }
  @include on("desktop-up") {
    align-items: center;
    .value-prop-ii-shape {
      width: 85px;
      height: 85px;
      span {
        font-size: rem(38px);
      }
    }
    .value-prop-ii-content {
      width: calc(100% - 85px);
    }
  }
}
