@import "~wonderbly-css/base";

.Characters__Model {
  max-width: 350px;
  .panel {
    padding: $spacer-md;
    text-align: center;
    overflow: hidden;
    width: 100%;
  }
  &__Close {
    float: right;
    cursor: pointer;
    margin-bottom: $spacer-sm;
    .icon {
      position: relative;
      display: inline-block;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      top: 2px;
      left: -#{$spacer-sm};
      &:before,
      &:after {
        content: "";
        width: 15px;
        height: 2px;
        display: block;
        background-color: #fff;
        border-radius: 1px;
        top: 6px;
        left: 0;
        position: absolute;
      }

      &:after {
        transform: rotate(90deg);
      }
    }
    &,
    &:hover,
    &:focus {
      color: #fff;
    }
  }
  &__Image {
    margin: -#{$spacer-md} -#{$spacer-md} $spacer-md;
    width: calc(100% + #{$spacer-xl});
    float: left;
  }
  &__Title {
    font-size: 20px;
  }
  &__CTA {
    margin-bottom: 16px;
  }
  &__Link {
    cursor: pointer;
  }
}
