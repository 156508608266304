@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.credit-info-partial {
  @extend %spaced-top-default;
  @extend %spaced-bottom-default;
  @extend %font-style-heading-color-inherit;
  text-align: center;

  .rich-text {
    display: inline-block;
  }

  .panel {
    @extend %padded-default;
    box-shadow: none;

    p {
      @extend %spaced-none;
    }
  }

  .ss-icon {
    @extend %spaced-right-small;
    color: get-colour("grey");
  }
}
