@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";

.HeroField__Phototype {
  display: none;
  &__panel {
    margin-top: $spacer-std;
    width: 100%;
    .HeroField__Phototype__options {
      display: flex;
      flex-wrap: wrap;
      margin: -#{$spacer-sm};

      & > .HeroField__Phototype__option {
        margin: $spacer-sm;
        flex-basis: calc(33.33% - #{$spacer-std});

        input:not(:checked) + label {
          background-color: transparent;
        }

        input:checked + label {
          border: rem(2) solid $color-racing-green;
        }

        label {
          border: rem(2) dashed $color-sharpie-10;
          box-shadow: none;

          img {
            max-width: 100%;
            max-height: 100px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  &--visible {
    display: block;
  }
  @include on("fablet-down") {
    &--force-visible {
      display: block;
    }
    &__panel {
      background: none;
      &:before {
        display: none;
      }
    }
  }
  @include on("tablet-up") {
    &__panel {
      min-width: 300px;
      margin: 0;
      padding: $spacer-std;
      position: absolute;
      left: 50%;
      bottom: calc(100% - #{$spacer-md});
      transform: translateX(-50%);
      z-index: 5;
      // TODO: shadowed - unable to use @extend %shadowed within @media, this should be replaced with a sass var
      box-shadow: 0 1px 3px rgba($color-sharpie, 0.2);
    }
    .HeroField__Phototype__arrow {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-left: $spacer-md solid transparent;
      border-right: $spacer-md solid transparent;
      border-top: $spacer-md solid $color-bright-smile;
      transform-origin: center;
      transform: translate(-50%, 0);
      top: $spacer-md;
      left: 50%;
      transition: left 200ms ease-in;
      z-index: 5;
      &--boy {
        left: 25%;
      }
      &--girl {
        left: 75%;
      }
    }
  }
}
