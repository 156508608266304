@import "~wonderbly-css/base";

.UpsellItems {
  margin: $spacer-std 0 $spacer-sm;
  width: 100%;

  &__info {
    @extend %text-milli;
    color: get-colour("grey-medium");
  }

  &__checkbox {
    padding: $spacer-std;
    border: rem(1) solid $color-sharpie-10;
    border-radius: $spacer-sm;
    width: 100%;
  }

  .UpsellItemLabel {
    margin: 0;

    span {
      @extend %text-milli;
    }

    strong {
      display: block;
      font-weight: 500;
      font-size: 1rem;
    }
  }
}
