@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.related-products {
  padding: get-size("big") 0;
  text-align: center;

  .related-products-content {
    margin: 0 auto $spacer-xl;
    max-width: 400px;
    text-align: center;
  }

  .related-products-grid {
    .loading {
      @extend %spaced-top-big;
    }
    .product-card-list-row {
      justify-content: center;
    }
    .product-item-content {
      align-items: center;
    }
    .product-content {
      .product-card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .ss-navigateright::before {
        color: get-colour("grey-dark");
      }
    }

    @include on("tablet-up") {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
    }
  }
}
