@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";
@import "~chameleon/helpers/position";

.featured-item {
  @extend %padded-top-big-on-fablet-down;
  @extend %padded-bottom-bigger-on-fablet-down;
  @extend %spaced-bottom;

  display: block;
  overflow: hidden;
  position: relative;
  &:last-child {
    @extend %spaced-bottom-none;
  }

  @include on("tablet-up") {
    display: flex;
    align-items: center;
    height: rem(600);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    &.color {
      &-green-light {
        color: get-colour("green-light");
      }
      &-green-medium {
        color: get-colour("green-medium");
      }
      &-green {
        color: get-colour("green");
      }
      &-green-dark {
        color: get-colour("green-dark");
      }
      &-yellow {
        color: get-colour("yellow");
      }
      &-pink {
        color: get-colour("pink");
      }
      &-red {
        color: get-colour("red");
      }
      &-red-dark {
        color: get-colour("red-dark");
      }
      &-white {
        color: get-colour("white");
      }
      &-almost-light {
        color: get-colour("almost-light");
      }
      &-grey-light {
        color: get-colour("grey-light");
      }
      &-grey-medium {
        color: get-colour("grey-medium");
      }
      &-grey {
        color: get-colour("grey");
      }
      &-grey-dark {
        color: get-colour("grey-dark");
      }
      &-almost-black {
        color: get-colour("almost-black");
      }
    }
  }
}

@media (min-width: 1440px) {
  .featured-item {
    transform: translateZ(0);
  }
}

.featured-item-copy {
  position: relative;

  @include on("desktop-up") {
    width: 100%;
    padding-left: 66%;

    .featured-item-reversed & {
      padding-left: 0;
      padding-right: 66%;
    }
  }

  @include on("tablet") {
    margin-left: 50%;
    width: 50%;

    .featured-item-reversed & {
      margin-left: 0;
      margin-right: 50%;
    }
  }

  @include on("fablet") {
    padding-top: rem(360);
  }

  @include on("mobile") {
    padding-top: rem(250);
  }

  .button {
    @include on("mobile") {
      text-align: center;
      width: 100%;
    }
    @include on("fablet") {
      display: block;
      margin: 0 auto;
      text-align: center;
      width: 66%;
    }
  }
}

.featured-item-title {
  @extend %text-giga;
  @extend %aligned-center-on-fablet-down;
  @extend %spaced-top-on-fablet-down;
}

.featured-item-description {
  @extend %text-kilo;
  @extend %aligned-center-on-fablet-down;
  @extend %spaced-top-small-on-desktop-up;
  @extend %spaced-bottom-on-desktop-up;
}

.featured-item-image {
  position: absolute;
  height: auto;
  width: 480px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  @include on("fablet-up") {
    width: 767px;
  }
  @include on("tablet-up") {
    width: 1040px;
  }
  @include on("desktop-up") {
    height: rem(660);
    width: rem(1740);
  }
}

.featured-item-button {
  cursor: pointer;
}
