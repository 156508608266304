@import "~wonderbly-css/base";

.FullWidthImage {
  background: get-colour("stone-light");
  padding-top: 61.8%;
  position: relative;

  @include on("desktop-up") {
    padding-top: rem(720);
  }
}
