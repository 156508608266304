@import "~wonderbly-css/base";

.SiteNavLinksList {
  padding: 0;
  border-bottom: solid rem(0.5) $color-sharpie-30;
  padding: 0 $spacer-md;
  list-style: none;

  @include on("desktop-up") {
    flex: 0 1 rem(275);
    border-bottom: none;
    padding: 0;
    min-height: rem(250);
  }

  &__promo-column {
    flex: 0 0 rem(400);
    align-self: flex-start;
    justify-self: flex-end;
  }

  &__title-item {
    list-style-type: none;
    padding: $spacer-md 0;
    pointer-events: none;

    @include on("desktop-up") {
      height: rem(20);
      padding: 0 0 rem(48) 0;
    }
  }

  &__promo-title-item {
    margin-top: $spacer-md;
  }

  &__promo-title {
    color: $color-racing-green;
    @include on("tablet-down") {
      font-size: 1.25rem;
    }
  }

  &__title {
    color: $color-racing-green;
    font-size: 1.5rem;
  }

  &__link-text,
  &__promo-link-text {
    color: $color-sharpie;
    font-family: $font-family-sans-serif;
    display: block;
    padding: $spacer-md 0;
  }

  &__links-container {
    display: flex;
    flex-direction: column;

    @include on("desktop-up") {
      flex-wrap: wrap;
      height: rem(350);
      column-gap: rem(25);
    }
  }

  &__marketing-card {
    height: auto;

    @include on("desktop-up") {
      height: rem(180);
    }
  }

  &__button {
    border: none;
    background-color: $color-bright-smile;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    display: flex;
    cursor: pointer;
    padding: 0;
    width: 100%;

    @include on("desktop-up") {
      justify-content: normal;
    }
  }

  &__arrow {
    margin-left: $spacer-md;
    color: $color-racing-green;
  }
}
