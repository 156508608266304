@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.Bundle {
  background: white;
  margin-bottom: $spacer-lg;

  &__header {
    background: $color-tinkerbell;
    color: $color-sharpie;
    padding: get-size("less");
    @extend %padded-on-tablet-up;
  }

  &__title h2 {
    @extend %text-mega;
    color: inherit;
    margin: 0 0 $spacer-sm;

    span {
      @extend %rounded;
      font-size: 80%;
      padding: $spacer-sm $spacer-std 0;
      display: inline-block;
      margin: 0 $spacer-sm;
    }
  }

  &__description {
    margin: 0;
  }
}
