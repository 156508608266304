@import "~wonderbly-css/base";

.FooterSub {
  padding: $spacer-md 0;
  background: get-colour("white");

  &__sub-links,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    display: inline-block;
    width: 100%;
    &:before {
      content: "•";
      position: absolute;
      color: get-colour("green-dark");
      display: none;
    }
    &,
    a {
      @extend %text-milli;
      color: get-colour("green-dark");
    }

    @include on("fablet-down") {
      &:not(:last-child) {
        margin-bottom: rem(24);
        &:before {
          display: initial;
          left: 50%;
          top: 100%;
        }
      }
    }
    @include on("tablet-up") {
      width: auto;
      &:not(:first-child) {
        margin-left: rem(24);
        &:before {
          display: initial;
          left: rem(-13);
          top: 0;
        }
      }
    }
  }

  a {
    &:hover,
    &:focus {
      color: get-colour("green-dark");
    }

    &:active {
      color: get-colour("green-dark");
    }
  }

  @include on("fablet-down") {
    text-align: center;
  }
}
