@import "~wonderbly-css/base";

.FeatureCards {
  padding: $spacer-xl 0;

  &__row {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-gap: $spacer-sm;

    @include on("tablet-up") {
      grid-auto-flow: column;
      grid-gap: $spacer-std;
    }

    @include on("desktop-up") {
      grid-gap: $spacer-xxl;
    }
  }
}
