@import "~chameleon/helpers/box";

.upsell {
  @extend %spaced-top;
  @extend %spaced-bottom;
  @extend %spaced-top-big-on-desktop-up;
  @extend %spaced-bottom-big-on-desktop-up;
  text-align: center;

  .header & {
    @extend %padded-top-on-desktop-up;
    padding-bottom: 0;
  }
}

.upsell-description {
  @extend %spaced-bottom;
}

.upsell-cta-button {
  @extend %widest-on-tablet-down;
}
