@import "~wonderbly-css/base";

.PrimaryHero {
  padding-bottom: $spacer-xl;

  .container-guttered {
    // due to the specific grid layout,
    // overriding the container-guttered
    // margin under this specific viewport size
    // will force the text to sit on the edge of the viewport
    // so we cannot use any of the media queries variables
    // define in responsive.js
    @media only screen and (min-width: 1290px) {
      margin-left: 0;
    }
  }
}
