@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.canvas-drawers {
  font-family: $font-family-sans-serif;
}

.canvas-drawers-fixed {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  bottom: 0;
}

.canvas-drawers-content-wrap {
  position: relative;
  z-index: 2;

  @include on("desktop-up") {
    z-index: 1;
  }
}

.canvas-drawers-wrap {
  background: $color-bright-smile;
  border-top: 1px solid $color-sharpie-10;
  border-bottom: 1px solid $color-sharpie-10;
  position: relative;
  z-index: 1;

  @include on("desktop-up") {
    z-index: 2;

    .canvas-drawers-button {
      padding: 0 0 0 get-size("default");
    }
  }
}

.canvas-drawers-wrap-container {
  @include on("tablet-down") {
    margin: 0;
    width: 100%;
  }
  @include on("desktop-up") {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.canvas-drawers-label-wrapper {
  @include on("desktop-up") {
    flex: 2;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    border-left: 1px solid $color-kraft-paper;
    margin-bottom: -1px;

    .canvas-drawers-wrap--multi & {
      flex-wrap: wrap;

      > * {
        flex: 0 1 33.3333%;
        border-bottom: 1px solid $color-kraft-paper;
      }
    }
  }
}

.canvas-drawers-button {
  @extend %padded-on-tablet-down;
  position: relative;

  .button {
    @extend %widest-on-fablet-down;
  }
}
