@import "~chameleon/helpers/box";
@import "~wonderbly-css/functions/sizing";

.Notifications {
  padding: get-size("less");
  flex-direction: column;
  &__NotificationItem {
    max-width: 420px;
    width: 100%;
    padding: rem(16);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: flex;
    &__icon {
      margin-right: rem(8);
      .complete-tick {
        line-height: 1.5;
      }
    }
    &__content {
      align-self: center;
    }
    h4 {
      // Had to force this as the global styles was overriding the color
      color: #fff;
      & + p {
        margin-top: rem(4);
      }
    }
    p {
      margin: 0;
    }
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
}
