@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.basket-contents {
  background: get-colour("white");
  @extend %shadowed;
  @extend %padded;
  position: absolute;
  right: 1rem;
  top: 3rem;
  max-width: 85%;
  box-shadow: 0px 2px 10px 0 rgba(get-colour("almost-black"), 0.5);

  @include on("desktop-up") {
    min-width: 18rem;
    right: 3rem;
  }

  a,
  .button {
    @extend %padded-top-small;
    @extend %padded-bottom-small;
    @extend %text-base;
    @extend %widest;
    line-height: 1.5;
    text-align: center;
    color: white;
  }

  .basket-checkout-button {
    &:active,
    &:focus,
    &:hover {
      color: white;
    }
  }
}

.basket-option-close {
  position: absolute;
  top: -1rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: 0;
  background: $color-kraft-paper;
  border-radius: 50%;
  text-align: center;
  line-height: 0;
  color: $color-sharpie;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    inset: -0.5rem;
  }

  &:hover,
  &:focus {
    outline: none;
    background-color: $color-kraft-paper-60;
  }

  &:active {
    outline: none;
    background-color: $color-sharpie;
    color: $color-bright-smile;
  }
}

.basket-total {
  @extend %padded-top;
  @extend %padded-bottom;

  @supports (display: flex) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
