@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";
@import "~chameleon/helpers/visibility";
@import "~mixins";

.order-confirmation-summary {
  @extend %spaced-top-default;
  @extend %spaced-bottom-default;

  .order-confirmatation-summary__intro {
    @extend %padded-default;

    p {
      @extend %spaced-none;
    }
  }

  @include on("tablet-down") {
    text-align: center;
  }

  hr {
    @include line-break(rem(0));
  }

  .order-summary.panel {
    padding: 0 get-size("less");

    @include on("tablet-up") {
      padding: 0 get-size("default");
    }

    .order-summary-list {
      .order-summary-label {
        color: get-colour("stone-dark");
      }
    }
    .order-summary-label {
      @extend %spaced-right-default;
    }
  }

  .order-summary-row {
    justify-content: flex-end;
  }

  .order-summary-row--total {
    padding-top: 0;
    border: none;
  }

  .checkout-line-item-title {
    font-family: $font-family-heading;
    @include on("tablet-up") {
      font-size: rem(18);
    }
  }

  .checkout-line-item-type {
    font-size: rem(16);
  }

  .checkout-line-item-price,
  .checkout-line-item-title {
    @include on("tablet-up") {
      p {
        font-size: rem(16);
      }
    }
  }

  .checkout-line-item-price {
    align-self: center;
  }

  .pricing {
    min-width: rem(95);

    .current {
      width: 100%;
      text-align: right;
    }
  }

  .checkout-line-item-wrapper {
    align-items: center;
    padding: 0 get-size("less");

    @include on("tablet-up") {
      padding: 0 get-size("default");
    }
  }

  .line-item-ii-image-wrapper {
    width: rem(100);
    height: rem(80);
  }
}
