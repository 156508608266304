@import "~wonderbly-css/base";

.SiteNavItem {
  @include on("desktop-up") {
    padding-right: $spacer-lg;
  }

  // reduce nav item sie when items > 5 on medium screens
  @at-root {
    @media only screen and (min-width: $device-width-desktop) and (max-width: 1150px) {
      .SitePrimaryNav--small & {
        padding-right: $spacer-md;
        font-size: rem(14);
      }
    }
  }

  &__button {
    border: none;
    background-color: white;
    font-family: $font-family-sans-serif;
    color: $color-sharpie;
    flex: 1;
    padding: $spacer-lg $spacer-md;
    border-bottom: solid rem(0.5) $color-sharpie-30;
    width: 100%;
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    white-space: nowrap;

    & > .SiteNavItem__arrow {
      color: $color-racing-green;

      @include on("desktop-up") {
        display: none;
      }
    }

    &--is-link {
      color: $color-sharpie;

      &:focus,
      &:hover {
        color: $color-racing-green;
      }
    }

    @include on("desktop-up") {
      padding: $spacer-md 0 $spacer-md - rem(3) 0;
      border-bottom: solid rem(3) transparent;

      &--is-active {
        border-bottom: solid rem(3) $color-racing-green;
      }

      &--is-link {
        &:focus,
        &:hover {
          border-bottom: solid rem(3) $color-racing-green;
        }
      }
    }
  }
}
