@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

@mixin tags-style {
  display: inline-block;
  font-size: rem(14px);
  line-height: rem(14px);
  padding: rem(8px) get-size("small") get-size("tiny");
}

.tags-wrapper {
  @extend %spaced-top-tiny;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .tag {
    @extend %spaced-top-tiny;
    @include tags-style;

    &:not(:last-child) {
      @extend %spaced-right-tiny;
    }
  }
}
