@import "~chameleon/helpers/box";

.custom-selection {
  img {
    width: 100%;
  }
  .selection-option-wrapper {
    @extend %spaced-top-none;
    margin-bottom: get-size("small");
  }
}
