@import "~wonderbly-css/functions/sizing";

.book-preview {
  position: relative;
  height: auto;
  margin: 1rem auto;
  z-index: 1;

  &-wrapper {
    max-width: 100vw;
  }

  &.book-preview--heidelberg {
    padding-top: 36.71727%;
    margin: 3rem auto;
  }

  .book-preview-wrapper--with-breadcrumb & {
    margin-top: 1rem;
  }

  .loading {
    background: transparent;
    margin: get-size("huge") 0;
  }
}

.click-blocker {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  user-select: none;
}
