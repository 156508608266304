@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.grid__group {
  @extend %spaced-top-default;
  @extend %spaced-bottom-default;
  @extend %spaced-top-big-on-desktop-up;
  @extend %spaced-bottom-big-on-desktop-up;
  margin-left: auto;
  margin-right: auto;
  & > .container-guttered {
    display: flex;
    flex-direction: column;
  }
  .grid__column-wrapper {
    .container-guttered {
      width: 100%;
      margin: 0;
    }
  }
  @include on("desktop-up") {
    .grid__column-wrapper {
      display: flex;
      align-self: center;
      justify-content: center;
    }
  }

  @include on("desktop-up") {
    &.grid__group--desktop-width-full
      > .container-guttered
      .grid__column-wrapper {
      width: 100%;
    }

    &.grid__group--desktop-width-guttered
      > .container-guttered
      .grid__column-wrapper {
      width: 85%;
    }

    &.grid__group--desktop-alignment-row {
      .grid__column-wrapper {
        flex-direction: row;

        & > .grid__column:first-of-type {
          margin-left: 0;
        }

        & > .grid__column:last-of-type {
          margin-right: 0;
        }
      }
    }

    &.grid__group--desktop-alignment-row-reverse {
      .grid__column-wrapper {
        flex-direction: row-reverse;

        & > .grid__column:first-of-type {
          margin-right: 0;
        }

        & > .grid__column:last-of-type {
          margin-left: 0;
        }
      }
    }
  }

  /*
   * Column guttering
   */

  @include on("desktop-up") {
    &.grid__group--desktop-gutter-50px .grid__column {
      margin: 0 rem(25);
    }

    &.grid__group--desktop-gutter-40px .grid__column {
      margin: 0 rem(20);
    }

    &.grid__group--desktop-gutter-30px .grid__column {
      margin: 0 rem(15);
    }

    &.grid__group--desktop-gutter-20px .grid__column {
      margin: 0 rem(10);
    }

    &.grid__group--desktop-gutter-10px .grid__column {
      margin: 0 rem(5);
    }
  }
}
