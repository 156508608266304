@import "~wonderbly-css/functions/sizing";

.prismic-image {
  max-width: 100%;
}
.rich-text {
  h1,
  h2,
  h3 {
    margin: 2.5rem 0 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
  h4 {
    margin: 0.5rem 0;
  }
  h5,
  h6 {
    margin: 0.25rem 0;
  }
  p {
    margin-top: 0.5rem;
  }
  hr {
    margin: get-size("more") 0;
  }
  pre {
    padding: get-size("less");
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
  }
  table {
    width: 100%;
    text-align: left;
    th {
      vertical-align: top;
    }
  }
}
