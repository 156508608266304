@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.accordion-item {
  cursor: pointer;

  .accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }

  &.accordion-item--active {
    .accordion-body {
      max-height: rem(
        200
      ); // transition depends on height. Ideally, we need to calculate it dynamically
      transition: 0.8s;
    }
    .accordion-icon {
      transform: rotate(180deg);
      transform-origin: 50% get-size("default");
    }
  }
}

.accordion-label {
  @extend %padded-bottom-small;
  padding-top: rem(13);
  border-top: rem(1) solid get-colour("stone");
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: rem(16);

  span {
    color: get-colour("green");
    transition: 0.3s;
  }
}
