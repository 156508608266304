@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/config/animations";

.product-hero--lost-my-name {
  position: relative;
  width: 100%;

  .product-price {
    color: get-colour("red");
  }

  .product-hero-decoration--left {
    display: none;
    position: absolute;
    top: rem(30);
    left: 0;
    width: 208px;
    height: 427px;
    background-image: none;
    background-position: left top;
    background-repeat: no-repeat;
  }

  .product-hero-decoration--right {
    position: absolute;
    top: rem(80);
    right: 0;
    width: rem(208);
    height: rem(250);
  }
}

@media (min-width: 1320px) {
  .product-hero--lost-my-name {
    .product-hero-decoration--left {
      background-image: url("img/product-hero-lmn-decoration-left.png");
      display: block;
    }
  }
}

@media (max-width: 1319px) {
  .product-hero--lost-my-name {
    .product-hero-decoration--right,
    .product-hero-earth {
      display: none;
    }
  }
}

.product-hero-leaf {
  position: absolute;
  left: rem(-100);
  bottom: rem(100);

  .product-hero-decoration--right & {
    left: auto;
    right: rem(-100);
  }
}
.product-hero-leaf--1 {
  animation: leaf1 0.7s 1 1s $bezier-out both;
}
.product-hero-leaf--2 {
  animation: leaf2 0.8s 1 1s $bezier-out both;
}
.product-hero-leaf--3 {
  animation: leaf3 0.5s 1 1.5s $bezier-out both;
}
.product-hero-leaf--4 {
  animation: leaf4 0.9s 1 1.5s $bezier-out both;
}

.product-hero-pirate {
  position: absolute;
  bottom: 0;
  left: 0;
  animation: pirate 0.3s 1 1s $bezier-out both;
}

.product-hero-cow {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: cow 0.3s 1 1.5s $bezier-in both;
}

.product-hero-earth {
  right: 0;
  bottom: 0;
  position: absolute;
}

/*
 * Left Decoration Animation
 *========================================
 */

@keyframes pirate {
  0% {
    transform: translate(-220px, 100px);
  }
  100% {
    transform: none;
  }
}

@keyframes leaf1 {
  0% {
    transform: none;
  }
  100% {
    transform: translate(150px, -200px) rotate(200deg) rotateZ(200deg);
  }
}

@keyframes leaf2 {
  0% {
    transform: none;
  }
  100% {
    transform: translate(220px, -30px) rotateZ(180deg) scale(0.5);
  }
}

/*
 * Right Decoration Animation
 *========================================
 */

@keyframes cow {
  0% {
    transform: translateX(220px);
  }
  100% {
    transform: none;
  }
}

@keyframes leaf3 {
  0% {
    transform: none;
  }
  100% {
    transform: translate(-180px, -100px) rotateZ(180deg) scale(0.5);
  }
}

@keyframes leaf4 {
  0% {
    transform: none;
  }
  100% {
    transform: translate(-150px, 100px) rotateZ(120deg);
  }
}
