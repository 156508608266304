@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/functions/palette";

.navigation__list {
  margin: 0;
  padding: 0;
  list-style: none;
  &:not(:last-child) {
    margin-bottom: get-size("less");
  }
  &.navigation__list--child {
    background-color: get-colour("almost-light");
    .navigation__item {
      background-color: transparent;
    }
  }
  &.navigation__list--root {
    overflow: hidden;
  }
}
