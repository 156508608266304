@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.order-details {
  .order-details__title {
    font-size: rem(20px);
    & + .order-buttons .order-buttons__wrapper:not(:empty) {
      @extend %spaced-top-small;
    }
  }

  .shipping-summary-wrapper {
    display: flex;
    & > * {
      width: 100%;
      justify-content: space-between;
    }
    @include on("fablet-down") {
      flex-wrap: wrap;
      .shipping__item {
        &:not(:last-child) {
          margin-bottom: get-size("small");
        }
      }
    }
  }
}
