@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.related-products {
  .product-item {
    display: flex;
    cursor: pointer;
    height: 100%;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto;
  }
  .product-item-link {
    width: 100%;
    @include on("tablet-up") {
      width: 33.33%;
    }
    @include on("desktop-up") {
      min-width: 320px;
    }
  }
  .product-item-image {
    width: 100%;
    img {
      max-width: 250px;
    }
  }
  .product-item-content {
    padding-left: 0;
    color: get-colour("almost-black");
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    button {
      cursor: pointer;
      margin-top: 10px;
      color: get-colour("green");
      background-color: transparent;
      border: none;
    }
    .product-item:hover & {
      button {
        color: get-colour("pink");
      }
    }
  }
}
