@import "~wonderbly-css/base";

.AgeFeatureCards {
  padding: 2rem 0;

  .AgeFeatureCards__grid {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  @include on("desktop-up") {
    .AgeFeatureCards__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.5rem;
    }
  }
}

.AgeFeatureCard {
  display: block;
  position: relative;

  &__container {
    display: flex;
    height: calc(33.33333vw - 1em);
    max-height: 10rem;
    padding: 1.5rem;
    width: 100%;
    align-items: center;
  }

  &__content {
    width: 100%;
    z-index: 2;
    flex: 1;
  }

  &__text {
    margin: 0;
    color: $color-bright-smile;
    width: 40%;
    font-size: 1.2rem;

    @include on("tablet-up") {
      width: 20%;
    }
  }

  &__button {
    border: none;
    background: none;
    z-index: 2;
    cursor: pointer;

    & > svg {
      display: block;
    }
  }
}
