@import "~wonderbly-css/functions/units";

.order-information {
  font-size: rem(16px);
  p {
    margin: 0;
  }
  &.order-information--cancelled {
    opacity: 0.5;
  }
}
