@import "~wonderbly-css/base";

.Sitemap {
  padding: $spacer-xl 0 $spacer-md;

  .Sitemap__Categories {
    display: flex;
    margin: 0 -#{$spacer-md};
    flex-flow: wrap;

    .Sitemap__Category {
      margin: 0 $spacer-md $spacer-md;
      width: 100%;

      .Sitemap__Category__title {
        color: $color-racing-green;
        padding: $spacer-md 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        & > li > a {
          font-weight: 400;
        }
      }
    }
  }

  @include on("tablet-up") {
    .Sitemap__Categories {
      display: flex;
      flex-flow: wrap;

      .Sitemap__Category {
        width: calc(100% / 3 - #{$spacer-xl});
      }
    }
  }
}
