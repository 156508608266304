@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

#modal-container {
  position: relative;
  z-index: 100;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: none;
}
.modal--open {
  display: block;
  -webkit-overflow-scrolling: touch;
}
.modal--default {
  &.modal--open {
    display: flex;
  }
  background-color: rgba(get-colour("almost-black"), 0.8);
  justify-content: center;
  align-items: center;
  .close-modal {
    color: get-colour("white");
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    height: rem(48);
    line-height: rem(50);
    text-align: center;
    position: absolute;
    width: rem(48);
    top: rem(-16);
    right: rem(-16);
    cursor: pointer;

    &:before {
      @extend %circled;

      background: get-colour("red");
      content: "";
      height: rem(32);
      width: rem(32);
      position: absolute;
      left: rem(8);
      top: rem(8);
      transition: 0.25s ease;
    }

    &:hover,
    &:focus {
      outline: none;
      &:before {
        background: get-colour("red-dark");
      }
    }

    &:active {
      outline: none;
      &:before {
        background: get-colour("almost-black");
      }
    }

    span {
      position: relative;
    }
  }
}

.modal--transparent {
  background-color: transparent;
}

.modal--fit {
  .modal__container {
    width: 100%;
    height: 100%;
  }
}
