@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";

.product-card-list {
  padding: get-size("default") 0;
  background-color: #f5f2ed;
  &.full-height {
    .product-link {
      height: calc(100% - 30px);
    }
    .product {
      height: 100%;
      display: flex;
      flex-direction: column;
      .product-content {
        flex: 1;
      }
    }
  }
  @include on("desktop-up") {
    padding: get-size("big") 0;
  }
}

.product-card-list-grid {
  width: 100%;
}

.product-card-list-row {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;
  @media (max-width: 639px) {
    justify-content: center;
  }
}
.product-card-list-row > * {
  max-width: 400px;
  width: 100%;
  @media (min-width: 640px) {
    max-width: 100%;
    flex-basis: 50%;
  }
  @include on("desktop-up") {
    flex-basis: calc(100% / 3);
  }
}
