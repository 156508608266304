@import "~wonderbly-css/base";

.ProductForm {
  padding: $spacer-lg 0;
  background-color: get-colour("stone-light");
  .ProductForm__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ProductForm__image,
    .ProductForm__content {
      width: 100%;
    }

    .ProductForm__image {
      margin-bottom: $spacer-std;

      img {
        width: 100%;
      }
    }

    .ProductForm__form-panel {
      background-color: transparent;
    }

    .ProductForm__title {
      margin-bottom: $spacer-std;
    }

    @include on("tablet-down") {
      .ProductForm__form-panel {
        .field-container {
          width: 100%;
        }
      }
    }
    @include on("tablet-up") {
      flex-direction: row-reverse;
      margin: 0 -#{$spacer-std};
      .ProductForm__image,
      .ProductForm__content {
        margin: 0 $spacer-std;
      }
      .ProductForm__image {
        margin-bottom: 0;
      }
    }
  }
}
