@import "~wonderbly-css/base";

.Avatars {
  .button {
    width: 100%;
  }
  &__list {
    margin: $spacer-md 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: $spacer-std;
  }
  @include on("fablet-up") {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
    .button {
      width: auto;
    }
  }
  @include on("tablet-up") {
    &__list {
      grid-template-columns: repeat(auto-fit, 164px);
    }
    .button {
      width: auto;
    }
  }
}
