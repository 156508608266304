@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.interest-option-column {
  padding-left: rem(12);
}

label.interest-option {
  @extend %spaced-bottom-small-on-tablet-down;

  position: relative;
  display: block;
  text-align: center;
  border: rem(2) solid transparent;
  transition: 0.3s border-color $bezier-out;
  cursor: pointer;

  .panel {
    overflow: hidden;
  }

  .interest-option-name {
    @extend %padded-top-small;
    @extend %padded-bottom-small;
    @extend %text-milli;
    display: block;
    background-color: $color-bright-smile;
  }

  .interest-option-image {
    display: block;
    width: 100%;
  }

  .interest-option__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem(8);

    &--upsold {
      justify-content: space-between;
    }
  }

  .interest-option__upsell-icon {
    background: $color-ketchup;
    color: $color-bright-smile;
    position: absolute;
    top: 0;
    left: 0;
    padding: rem(8);
    font-size: rem(16);
    line-height: rem(18);
  }

  .interest-option__upsell-price {
    color: $color-ketchup;
    font-size: rem(14);
  }

  .Badge {
    position: absolute;
    top: rem(2);
    left: rem(2);

    & > .Badge__inner {
      align-items: normal;
    }
  }

  &:hover,
  &:focus,
  &:active {
    border-color: $color-sharpie-10;
  }

  input:checked + & {
    border-color: $color-racing-green;
    .interest-option-name {
      color: $color-racing-green;
    }
  }
}
