@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";
@import "~mixins";

.shipping-methods {
  .shipping-method {
    @extend %spaced-bottom;
  }
  .radio-input:not(.radio-input--custom-label) {
    align-items: flex-start;
  }
  .radio-input .radio-input__label * {
    float: none;
  }
  .radio-input__label {
    width: 100%;
  }
  .field-description {
    width: 100%;
    .rich-text {
      @extend %spaced-bottom-none;
      font-size: rem(16);
    }
    .ss-icon {
      display: none;
    }
    em {
      font-style: normal;
    }
  }
  .option-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .shipping-method-label {
    flex-grow: 1;
    font-size: rem(18);
  }
  .shipping-method-label,
  .shipping-price,
  em {
    color: #645b9b;
  }
  .shipping-method--is-checked {
    .radio-input__label {
      span {
        color: get-colour("green");
      }
      .shipping-price {
        color: get-colour("green");
      }
      .field-description em {
        color: get-colour("green");
      }
    }
  }
}
