@import "~wonderbly-css/functions/sizing";

.klarna__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .klarna__submission {
    position: absolute;
    bottom: get-size("more");
  }
  &.klarna__container--form-available {
    padding-bottom: calc(3 * #{get-size("more")});
  }
}
