@import "~wonderbly-css/base";

.Characters__Header {
  @include on("fablet-down") {
    margin-bottom: $spacer-md;
    .button {
      width: 100%;
    }
  }
}
