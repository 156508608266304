@import "~wonderbly-css/utilities/aesthetic";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.column-carousel__header {
  @extend %spaced-bottom-small;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .column-carousel__link {
    text-decoration: underline;
    line-height: rem(30);
  }
}
.column-carousel__wrapper {
  .column-carousel__item {
    width: 100%;
    & > * {
      height: 100%;
    }
  }
  &:not(.column-carousel__wrapper--slider) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .column-carousel__item {
      @extend %spaced-bottom;
      flex-basis: calc(50% - #{get-size("small")});
    }
  }
  &.column-carousel__wrapper--slider {
    margin: 0 -#{get-size("default")};
    .column-carousel__item {
      @extend %spaced-right-small;
    }
    .slick-list {
      overflow: visible;
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      height: auto;
      display: flex;
      > div {
        display: flex;
        width: 100%;
      }
    }
    .slick-dots {
      @extend %spaced-top-small;
      position: relative;
      bottom: 0;
    }
  }
}
