@import "~wonderbly-css/functions/units";
@import "~wonderbly-css/functions/sizing";

@include classes("aligned-center", $on: "base");
@include classes("aligned-right", $on: "base");

// Overriding alert messages to remove the arrow
.js .tooltip--alert {
  &:not(.tooltip--absolute) {
    margin-bottom: 35px;
  }
  &:after {
    display: none;
  }
}

.panel--default {
  width: calc(100% - 30px);
  max-width: rem(600);
  margin: get-size("big") get-size("less");
  padding: rem(30);
  max-height: calc(100vh - 60px);
  position: relative;
  .panel-content {
    overflow: auto;
    max-height: calc(100vh - 120px);
  }
}

;@import "sass-embedded-legacy-load-done:2404";