@import "~wonderbly-css/base";

.ProductTiles {
  padding: $spacer-xl 0;

  &__section-content {
    text-align: left;
  }

  &__tiles-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-column-gap: rem(24);
    grid-row-gap: rem(24);

    @include on("tablet-up") {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
    }
  }

  &__description {
    margin-bottom: $spacer-lg;
  }
}
