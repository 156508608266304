@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.breadcrumb {
  text-align: center;
  @extend %spaced-top-on-tablet-up;

  .breadcrumb-cta {
    color: get-colour("green");
  }
}

.breadcrumb-item {
  position: relative;
  display: inline-block;

  .breadcrumb-item-image {
    @extend %spaced-tiny;
    @extend %rounded;
    max-width: rem(40);
    max-height: rem(40);
    border: solid 2px $color-bright-smile;
    display: block;
  }

  .breadcrumb-item-text-overlay {
    @extend %text-milli;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    color: $color-bright-smile;
    pointer-events: none;
  }

  &.breadcrumb-item--active {
    .breadcrumb-item-image {
      border-color: $color-racing-green;
    }
  }

  .tooltip {
    @extend %text-milli;
    @extend %padded-tiny;
    white-space: nowrap;
    transform: translateX(-50%);
    left: 50%;
    width: auto;
    opacity: 0;
    font-family: $font-family-sans-serif;
    transition: opacity 0.25s ease, visibility 0.25s ease 0.25s;
    visibility: hidden;
    z-index: auto;
  }

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s, 0s;
    }
  }
}
