@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";

.form {
  padding: get-size("big") 0;
}

.form__subtitle {
  text-align: center;
  max-width: 35em;
  margin: 0 auto $spacer-lg;
}

.form-wrapper {
  width: 100%;
  margin: 0 auto;
  @include on("tablet-up") {
    max-width: 38em;
  }
  &.hidden {
    display: none;
  }
}

.form form {
  display: flex;
  margin: 0 -10px;
  @include on("fablet-down") {
    flex-direction: column;
  }
}
.form-field,
.form-field-submit {
  padding: get-size("small");
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > * {
    width: 100%;
  }
  &:not(:last-child) {
    @include on("fablet-down") {
      padding-bottom: 0;
    }
  }
}

.form-field-submit {
  @include on("tablet-up") {
    max-width: rem(300);
  }
}

.errors {
  padding: get-size("small") get-size("less");
  background-color: get-colour("red");
  color: get-colour("stone-light");
  overflow: hidden;
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}
.form-successful {
  background-color: get-colour("yellow");
  padding: get-size("default");
  text-align: center;
  margin: 0 auto;
  max-width: rem(800);
  p {
    margin: 0;
  }
}
