@import "~wonderbly-css/base";

.ProductHeroIISlide {
  position: relative;
  margin: 0;

  &__caption {
    @extend %text-giga;
    @extend %font-style-heading;
    position: absolute;
    top: $spacer-md;
    left: $page-padding-mobile;
    right: $page-padding-mobile;
    color: $text-color-heading;
    z-index: 1;

    &--inverse {
      color: get-colour("white");
    }

    @include on("tablet-up") {
      top: $spacer-lg;
      left: $spacer-lg;
      right: $spacer-lg;
    }
  }

  &__image--wrapper {
    position: relative;
  }

  &__image {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
