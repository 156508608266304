@import "~wonderbly-css/mixins/responsive";
@import "~chameleon/helpers/box";

.birthday-form {
  &--error {
    @extend %padded-top;
  }
  @supports (display: flex) {
    @include on("tablet-up") {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
    }
  }

  .inline-label {
    @extend %spaced-bottom-small-on-fablet-down;
    @include on("fablet-down") {
      display: block;
    }
  }
}

.js .tooltip--alert:not(.tooltip--absolute).birthday-form-tooltip {
  margin-bottom: 0;
}

.birthday-form-row {
  @extend %padded-top;
  @supports (display: flex) {
    flex: 1;
  }
}

.birthday-form-button {
  @extend %padded-top;
  @extend %padded-left-on-tablet-up;

  .button {
    @extend %widest-on-fablet-down;
  }
}
