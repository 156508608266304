@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.multi-birthday {
  display: flex;
  margin: 0 rem(-10px);
  flex-direction: column;
  & > div {
    @extend %spaced-left-small;
    @extend %spaced-right-small;
  }
  .multi-birthday__carousel-container {
    @extend %spaced-bottom-small;
  }
  .multi-birthday__form-container {
    display: flex;
    flex-direction: column;
    .multi-birthday__form-wrapper {
      flex: 1 0 auto;
      position: relative;
      .error-tooltip--multi-birthday-base {
        bottom: auto;
        top: rem(-55px);
      }
      &.multi-birthday__form-wrapper--empty {
        @extend %padded;
        @extend %spaced-bottom-small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: rem(2px) dashed get-colour("stone");
      }
    }
    .multi-birthday__form-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        color: get-colour("stone-dark");
        margin: 0;
        font-size: rem(16px);
      }
    }
    .multi-birthday__form-submit {
      @extend %spaced-bottom-small;
      @extend %padded-top-tiny;
      @extend %padded-right-big;
      @extend %padded-bottom-tiny;
      @extend %padded-left-big;
      width: 100%;
    }
    .multi-birthday__form {
      display: flex;
      flex-wrap: wrap;
      margin: 0 rem(-5px);
      flex-direction: column;
      .multi-birthday__field {
        @extend %spaced-top-none;
        @extend %spaced-left-tiny;
        @extend %spaced-bottom-small;
        @extend %spaced-right-tiny;
        min-height: rem(124px);
        padding: get-size("less");
        position: relative;
        .multi-birthday__field-name {
          color: get-colour("almost-black");
        }
        .multi-birthday__field-title {
          margin-bottom: rem(4px);
        }
        .birthday-form-row {
          @extend %padded-top-none;
          margin: 0 rem(-5px);
          display: flex;
          & > div {
            @extend %padded-left-none;
            @extend %spaced-left-tiny;
            @extend %spaced-right-tiny;
          }
          .inline-label {
            @extend %spaced-top-none;
          }
          .birthday-form-col--day {
            width: rem(75px);
          }
          .birthday-form-col--month {
            width: calc(100% - #{rem(175px)});
            min-width: rem(80px);
          }
          .birthday-form-col--year {
            width: rem(100px);
          }
        }
        .error-tooltip--multi-birthday-field {
          width: calc(100% - #{get-size("more")});
          bottom: rem(75px);
        }
        &.multi-birthday__field--empty {
          display: none;
          background-color: #efeee8;
        }
        &:last-child {
          @extend %spaced-bottom-none;
        }
      }
    }
  }
  @include on("fablet-up") {
    .multi-birthday__form-wrapper--empty {
      margin-bottom: 0;
    }
    .multi-birthday__form-container {
      .multi-birthday__form-footer {
        flex-direction: row;
        margin-top: get-size("small");
        p {
          margin: 0 0 0 get-size("small");
        }
      }
      .multi-birthday__form-submit {
        margin-bottom: 0;
        width: auto;
      }
      .multi-birthday__form {
        flex-direction: row;
        .multi-birthday__field {
          width: calc(50% - #{rem(10px)});
        }
      }
    }
  }
  @include on("tablet-up") {
    flex-direction: row;
    & > div {
      width: 50%;
    }
    .multi-birthday__form-wrapper--empty {
      margin-bottom: get-size("small");
    }
    .multi-birthday__carousel-container {
      margin-bottom: 0;
    }
    .multi-birthday__form-container {
      .multi-birthday__form-footer {
        margin-top: 0;
      }
      .multi-birthday__form {
        flex-direction: column;
        .multi-birthday__field {
          width: auto;
        }
      }
    }
  }
  @include on("desktop-up") {
    .multi-birthday__form-wrapper--empty {
      margin-bottom: 0;
    }
    .multi-birthday__form-container {
      .multi-birthday__form-footer {
        flex-direction: row;
        margin-top: get-size("small");
      }
      .multi-birthday__form {
        flex-direction: row;
        .multi-birthday__field {
          width: calc(50% - #{get-size("small")});
          &.multi-birthday__field--empty {
            display: block;
          }
        }
        &.multi-birthday__form--even {
          .multi-birthday__field {
            &:nth-last-child(2) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
