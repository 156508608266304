@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.format-option {
  @extend %shadowed;
  background: get-colour("white");
  cursor: pointer;
  position: relative;
  transition: 0.25s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px get-colour("green-light");
  }

  &:active {
    box-shadow: 0 0 0 4px get-colour("green");
  }
}

.format-option-selected {
  box-shadow: 0 0 0 4px get-colour("green-light");
}

.format-badge {
  background: get-colour("yellow");
  color: get-colour("grey-dark");
  @extend %padded-tiny;
  @extend %text-milli;

  @include on("fablet-up") {
    position: absolute;
  }

  text-align: center;
  bottom: 100%;
  left: 0;
  width: 100%;

  .ss-icon {
    display: inline-block;
    @extend %spaced-left-small;
    @extend %spaced-right-small;
  }
}

.format-option-name {
  @extend %text-mega;
  @extend %padded-small;

  border-bottom: 1px solid get-colour("grey-light");
  text-align: center;
}

.format-option-content {
  @extend %padded;
  flex: 2;
  text-align: center;

  @include on("fablet-up") {
    flex: none;
    display: block;
    width: 100%;
  }

  @include on("desktop-up") {
    .formats > .col-1-2 & {
      display: flex;
      align-items: center;
    }
  }
}

.format-option-image-wrap {
  @extend %spaced-bottom-on-mobile;
  position: relative;
  margin: 0 auto;
  display: inline-block;
  max-width: rem(200);
  width: 100%;
  @include on("fablet-up") {
    max-width: rem(400);
  }
}

.format-option-image {
  display: inline-block;
  max-width: 100%;
}

.format-option-price {
  font-family: $font-family-display;
  color: get-colour("white");
  height: rem(80px);
  width: rem(80px);
  position: absolute;
  right: rem(10px);
  top: rem(-10px);
  text-align: center;
  line-height: rem(74px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.format-option-price--savings {
  color: get-colour("almost-black");
}

.format-option-price-bg {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.format-option-price-text {
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 18px;
  text-align: right;
}
.format-option-was-price-text {
  align-self: flex-end;
  display: inline-block;
  line-height: 9px;
  font-size: 9px;
  margin-bottom: 3px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: get-colour("almost-black");
    margin-top: -2px;
  }
}
.format-option-currency-text {
  font-size: 10px;
  line-height: 10px;
  display: inline-block;
  float: right;
}

.format-option-description {
  @extend %spaced-bottom-none;
  text-align: left;
  width: 100%;
}

.format-option-button-wrap {
  @extend %padded-small;

  button {
    width: 100%;
  }
}
