@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.features {
  padding: get-size("more") 0;
}
.features-row {
  display: flex;
  flex-direction: column;

  .feature-link {
    width: 100%;
    display: block;
  }
  .feature {
    @extend %padded;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vw / 3 - 20px);
    min-height: 180px;
    max-height: 300px;
    text-align: center;
    background: transparent none no-repeat center;
    background-size: cover;
    h3,
    p {
      margin: 0 0 get-size("tiny");
    }
    h3 {
      font-size: rem(26px);
    }
    p {
    }
    .feature-background-overlay {
      display: none;
    }
    .feature-content {
      width: 100%;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
    }
    .arrow {
      position: relative;
      display: none;
      width: 22px;
      height: 22px;
      color: white;
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        border-top: 3px solid;
        transform-origin: 95% 0;
        transform: rotate(40deg) translate(0, -50%);
        top: 50%;
      }
      &:after {
        transform: rotate(-40deg) translate(0, -50%);
      }
    }
    .button {
      display: none;
      align-self: flex-start;
      margin: 0 auto;
    }
  }
  @include on("fablet-down") {
    .feature-link {
      &:not(:last-child) {
        margin-bottom: $spacer-sm;
      }
    }
    .feature {
      align-items: center;
    }
    .features--condensed & {
      min-height: 0;
      height: 100%;
      text-align: left;
      .feature {
        position: relative;
        flex-direction: row;
        align-items: center;
        text-align: left;
        height: 100%;
        min-height: 0;
        overflow: hidden;
      }
      .feature-background-overlay {
        display: block;
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .feature-content {
        width: calc(100% - 22px);
        position: relative;
      }
      .arrow {
        display: block;
      }
    }
  }
  @include on("tablet-up") {
    flex-direction: row;
    margin: 0 -10px;
    .feature-link {
      margin-left: get-size("tiny");
      margin-right: get-size("tiny");
    }
    .feature {
      .feature-content {
        flex: 1;
      }
      .button {
        display: block;
      }
    }
  }
  @include on("desktop-up") {
    margin: 0 -15px;
    .feature-link {
      margin-left: get-size("less");
      margin-right: get-size("less");
    }
  }
}
