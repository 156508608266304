@import "~wonderbly-css/base";

.HomepageHeroII {
  &__title {
    // TODO: This is a custom font size that's not yet available in our design system,
    // but will/should be added soon, as well as to `wonderbly-css`.
    font-size: rem(36);
    line-height: rem(40);
    margin: $spacer-lg 0;

    @include on("desktop-up") {
      font-size: rem(44);
      line-height: rem(42);
      margin: $spacer-xl 0;
    }
  }

  &__carousel-container {
    margin-left: 0;
    margin-right: $spacer-md;

    @include on("desktop-up") {
      margin-left: inherit;
      margin-right: inherit;
    }
  }
}
