@import "~wonderbly-css/base";

.Klarna {
  font-size: rem(12);
  color: #000000;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;

  svg {
    margin-right: $spacer-std;
  }
}
