@import "~wonderbly-css/base";

.SiteAdditionalLinks {
  padding: 0;
  margin: 0;

  @include on("desktop-up") {
    display: flex;
  }

  &__link {
    list-style-type: none;
    margin: 0 0 $spacer-std $spacer-lg;

    @include on("desktop-up") {
      margin: 0 0 0 $spacer-xl;
    }
  }

  &__link-text {
    font-family: $font-family-sans-serif;
    font-size: 1rem;

    @include on("desktop-up") {
      font-size: 0.75rem;
    }
    color: $color-sharpie;
    text-align: left;
  }
}
