@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.drawer-inputs__fieldset {
  @extend %spaced-top-small;
  position: relative;
  border: none;
  padding: 0;
  .error-tooltip {
    bottom: calc(100% + #{$spacer-sm});
    @include on("fablet-down") {
      position: relative;
      bottom: 0;
      margin-top: $spacer-sm;
      margin-bottom: $spacer-std;
    }
  }
}

.drawer-inputs .drawer-inputs-cta-button {
  @extend %widest-on-fablet-down;
  @extend %spaced-top-small-on-fablet-down;
  text-align: right;
  vertical-align: bottom;

  .button {
    @extend %widest-on-fablet-down;
    @extend %spaced-top-small-on-fablet-down;
  }
}
