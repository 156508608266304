@import "~wonderbly-css/base";
@import "~wonderbly-css/utilities/aesthetic";

$country-image-size: 56px;

.SwitchCountry {
  &__close-button {
    position: absolute;
    top: $spacer-std;
    right: $spacer-std;
    padding: 0;
  }
  &__panel {
    position: relative;
    margin: 0 $spacer-std;
    max-width: calc(488px - #{$spacer-std * 2});
    max-height: calc(100% - #{$spacer-std * 2});
    overflow-y: auto;
    border-radius: 0;

    .panel__section {
      border-bottom: none;
    }

    &__main {
      padding: $spacer-xxl $spacer-md $spacer-lg;
    }
    &__content {
      text-align: center;
      margin-bottom: $spacer-md;

      h1 {
        margin-bottom: $spacer-std;
      }

      p {
        margin: 0;

        strong {
          font-weight: $font-weight-bold;
        }
      }
    }
    &__footer {
      background: $color-kraft-paper;
      padding: $spacer-md;
    }
  }
  &__country {
    @extend %rounded;
    color: $color-sharpie;
    background: $color-kraft-paper;
    padding: $spacer-std $spacer-md;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__flag {
      width: $country-image-size;
      height: $country-image-size;
      background-color: #fff;
      border-radius: 50%;
      margin-right: $spacer-md;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      & > * {
        width: rem(40);
      }
    }

    &__wrapper {
      flex: 1 1 auto;
      padding: 0 $spacer-md 0 0;
    }

    &__arrow {
      flex: 0 0 auto;
    }

    &__content {
      @extend %font-style-heading-color-inherit;

      h4,
      p {
        margin: 0;
      }
    }

    &:hover {
      color: get-colour("green");
    }

    &:not(:last-child) {
      margin-bottom: $spacer-std;
    }
  }
  &__link {
    text-align: center;
    p {
      @extend %text-milli;
      margin: 0;
    }
  }
}
