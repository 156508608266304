@import "~chameleon/helpers/box";

.bear-fam-active,
.bear-fam-subs {
  display: flex;
  overflow-x: auto;
}

.bear-fam-active-inner {
  display: flex;
}

.bear-fam-subs {
  flex-wrap: wrap;
  flex: 1;
}

.bear-fam-cta-row {
  @include on("tablet-up") {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .bear-fam-cta-button .button {
    @extend %widest-on-fablet-down;
    @extend %spaced-top-on-fablet-down;
  }
}
