@import "~wonderbly-css/base";

.SectionHeading {
  &__heading {
    @extend %text-tera;
    margin-bottom: rem(24);

    &--center {
      text-align: center;
    }

    &--left {
      text-align: left;
    }
  }
}
