@import "~wonderbly-css/base";

.GladlyHelp {
  margin: $spacer-lg 0;

  @include on("tablet-up") {
    margin: $spacer-xxl 0;
  }

  &__success {
    text-align: center;
    padding: $spacer-lg;
    margin-bottom: $spacer-lg;
    background-color: $color-racing-green;
    color: $color-bright-smile;
    border-radius: rem(4);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }

  .gladlyHC {
    & > .gladlyHC-faq {
      & > .gladlyHC-answersIndex-link {
        & > a {
          color: $color-racing-green;
        }
      }

      > .gladlyHC-faqContainer {
        & > .gladlyHC-faqHeading {
          color: $color-racing-green;
          font-size: 1.5rem;
          padding: $spacer-lg 0;
        }

        > .gladlyHC-faqSections > .gladlyHC-faqSection {
          padding: $spacer-md 0;

          & > .gladlyHC-faqSection-header {
            font-size: 1.5rem;
            padding-bottom: $spacer-md;
            color: $color-racing-green;
          }

          & > .gladlyHC-faqSection-list {
            & > li > a {
              font-weight: 400;
            }

            & > .gladlyHC-faqSection-listItem-more {
              padding-top: $spacer-md;

              & > a {
                color: $color-racing-green;
              }
            }
          }
        }
      }
    }
  }
}
