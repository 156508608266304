@import "~wonderbly-css/mixins/responsive";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.wrapper.two-column-layout {
  max-width: 1280px !important; //no other way - we need to override the wrapper styling

  @include on("desktop-up") {
    padding: rem(37) 0;
    width: calc(100% - #{get-size("big")});
    margin: 0 auto;
    display: flex;

    .container-guttered {
      width: 100%;
      margin: 0;
    }

    .left-column {
      width: calc(66.66% - #{get-size("less")});
      margin-right: get-size("less");
    }

    .right-column {
      width: 33.33%;
    }
  }

  .left-column,
  .right-column {
    > * {
      @extend %spaced-bottom-default;
      margin-top: 0;
    }
  }
}

#range.product-added {
  margin-top: rem(39); // product-added-banner height mobile

  @include on("tablet-up") {
    margin-top: rem(50); // product-added-banner height desktop
  }
}
