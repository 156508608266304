@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.wrapper--checkout {
  &.two-column-layout {
    display: flex;
    flex-direction: column;

    .left-column {
      align-self: flex-start;
    }

    .full-width-column {
      width: 100%;
    }

    @include on("desktop-up") {
      flex-direction: row;
      max-width: rem(1000) !important;
      flex-wrap: wrap;
    }

    @include on("tablet-down") {
      padding: 0 get-size("less") 0;

      .left-column {
        width: 100%;
      }
      .right-column {
        order: -1; // hack to keep order summary always on top
      }

      section {
        &:not(:last-child) {
          margin: 0 auto get-size("default") auto;
          width: 100%;
        }
      }
    }
  }

  .accordion {
    .accordion-label {
      border-top: none;
      border-bottom: rem(1) solid get-colour("stone");

      > div {
        font-size: rem(18);
        font-weight: $font-weight-bold;

        &:first-child {
          flex-grow: 1;
        }

        &:nth-child(2) {
          color: get-colour("green");
        }
      }

      .ss-icon {
        @extend %spaced-left-small;
      }
    }

    .discount-form {
      background-color: transparent;
    }

    .padded-wrapper {
      @include on("tablet-down") {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .accordion-item--active .accordion-body {
    max-height: 1000px;
  }

  .panel--border-shadow {
    box-shadow: none;
  }
}

.back-to-link {
  @extend %spaced-top-default;
  display: block;
  text-align: center;
}
