@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";

@keyframes agitated {
  10% {
    transform: translateX(-20px);
  }
  20% {
    transform: none;
  }
}

.scrolling-book-container {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.scrolling-book {
  overflow-x: visible;
  white-space: nowrap;

  &.agitated-monkey {
    animation: agitated 4s infinite $bezier-out forwards;
  }

  img {
    display: inline-block;
    min-width: 200px;

    &.isSplit {
      // Is a hard-coded value for accurate calculations of scroll amounts / active pages
      margin-right: 10px;
    }
  }
}

.scrolling-book-zoom-icon {
  position: absolute;
  bottom: 20px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 30px;
  width: rem(60);
  height: rem(60);
  border: none;
  border-radius: 50%;
  outline: 0;
  span {
    position: absolute;
    top: calc(50% + 4px);
    left: calc(50% - 2px);
    transform: translate(-50%, -50%);
  }
}
