@import "~wonderbly-css/mixins/responsive";

.recent-orders {
  .order-summary {
    height: 100%;
  }
  .recent-orders__content--empty-orders {
    width: 100%;
    text-align: center;
    @include on("tablet-up") {
      text-align: left;
    }
  }
}
