@import "~wonderbly-css/base";

.NavCountryToggle {
  position: relative;

  &__pill {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    background: white;
    border: solid 1px $color-kraft-paper;
    color: $color-sharpie;
    border-radius: rem(64);
    line-height: rem(30);
    padding: $spacer-sm $spacer-std;
    display: flex;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $color-racing-green;
      outline: none;
      background-color: $color-kraft-paper;
    }

    &:active {
      color: $color-racing-green;
      outline: none;
    }
  }

  &__image {
    width: rem(24);
    margin-top: rem(2);

    @include on("desktop-up") {
      width: rem(30);
      margin-top: 0;
    }
  }

  &__text {
    margin-left: $spacer-sm;
    @media only screen and (min-width: $device-width-desktop) and (max-width: 1200px) {
      width: rem(60);
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
