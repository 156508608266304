@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.header-wrap--checkout {
  @include on("tablet-down") {
    min-height: rem(42);
  }

  @include on("desktop-up") {
    min-height: rem(80);
  }
}

.header-checkout {
  background: get-colour("white");
  width: 100%;
  position: fixed;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  z-index: 10;

  .header-navigation-inner-checkout {
    display: flex;
    align-items: center;
    .header-wrap--hide-navigation & {
      justify-content: center;
    }
  }

  .header-navigation-logo-checkout {
    @include on("tablet-down") {
      padding: get-size("tiny") 0 get-size("tiny") get-size("less");
    }
    svg {
      margin: 0 auto;
      height: rem(32);
      @include on("fablet-up") {
        height: rem(40);
      }
    }
  }

  .header-logo .header-logo-inner {
    height: auto;

    @include on("tablet-down") {
      margin-right: get-size("less");
    }
  }

  .main-navigation-link {
    @extend %padded-none;
    color: get-colour("grey-light");
    position: relative;

    &.navigation-link--active {
      color: $text-color-heading;
      pointer-events: none;
      cursor: default;
    }
  }

  .header-logo-inner {
    @include on("tablet-down") {
      margin-right: get-size("more");
    }
  }

  .main-navigation--checkout {
    @include on("tablet-down") {
      padding-left: get-size("less");
      border-left: 1px solid get-colour("stone");
    }

    ul {
      @extend %spaced-none;
      @extend %padded-none;
      list-style: none;

      li {
        display: inline-block;
        &:not(:last-child) {
          .main-navigation-link {
            position: relative;
            &:after {
              color: get-colour("grey-light");
              content: ">";
              position: absolute;
              right: -1.5rem;
              font-weight: normal;
            }
          }
        }
      }
    }

    .main-navigation-link {
      font-size: rem(14);
      @include on("tablet-up") {
        .Icon {
          display: none;
        }
      }
      @include on("fablet-down") {
        .link-text {
          display: none;
        }
        &.navigation-link--active {
          .link-text--mobile {
            display: block;
            & + .ss-icon {
              display: none;
            }
          }
        }
      }

      @include on("tablet-up") {
        .ss-icon {
          display: none;
        }
      }
      @include on("desktop-up") {
        font-size: rem(20);
      }
    }

    .main-navigation-link {
      @extend %spaced-right-more;
      display: inline-block;
    }

    .main-navigation-link-text {
      flex-direction: row-reverse;
      gap: 0.5rem;
    }
  }
}
