@import "~wonderbly-css/base";

.form.sign-up-newsletter {
  padding: 4rem 0 5rem;
  background: $color-lily-pad-60;
  margin-top: $spacer-xxl;

  .form-wrapper {
    width: 100%;
    margin: 0 auto;

    @include on("tablet-up") {
      max-width: 26em;
    }
  }

  #sign-up-newsletter {
    display: block;
  }

  .form-field-submit {
    max-width: initial;
  }
}
