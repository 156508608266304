@import "~wonderbly-css/functions/palette";
@import "~wonderbly-css/functions/sizing";
@import "~chameleon/helpers/box";

.multi-adventurer-form {
  .characters {
    @extend %spaced-bottom-small;
    display: flex;
    .character {
      @extend %padded;
      position: relative;
      width: rem(166px);
      min-height: rem(209px);
      text-align: center;
      .character__remove {
        @extend %padded-none;
        width: rem(30px);
        height: rem(30px);
        border: none;
        background-color: get-colour("red");
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        top: rem(-15px);
        right: rem(-8px);
        .ss-delete {
          top: rem(3px);
        }
      }
      .character__image {
        max-width: rem(80px);
      }
      .character__title {
        color: get-colour("grey-dark");
      }
      .character__edit-link {
        color: get-colour("green");
        @extend %padded-none;
        text-decoration: underline;
        font-size: rem(16px);
      }
      &:not(:last-child) {
        @extend %spaced-right-small;
      }
    }
    .character--add-new {
      color: get-colour("stone-dark");
      border: rem(2px) dashed #d8d4c6;
      background-color: transparent;
      font-size: rem(16px);
      cursor: pointer;
    }
  }
  @include on("fablet-down") {
    .multi-adventurer-form__title {
      margin: get-size("default") get-size("default") 0;
    }
  }
  @media screen and (max-width: 480px) {
    .characters {
      flex-wrap: wrap;
      justify-content: space-between;

      .character {
        margin-bottom: get-size("small");
        flex: calc(50% - #{get-size("tiny")});
        &:not(:nth-child(odd)) {
          margin-right: get-size("none");
        }
        &:last-child {
          margin-bottom: get-size("none");
        }
      }

      .character--add-new {
        padding: get-size("small") get-size("default");
        flex: 0 100%;
        min-height: 0;
      }
      &--even {
        .character {
          &:nth-last-child(2) {
            margin-bottom: get-size("none");
          }
        }
        .character--add-new {
          flex: 0 calc(50% - #{get-size("tiny")});
        }
      }
      &--odd {
        .character--add-new {
          flex: 0 100%;
        }
      }
    }
    .canvas-drawer-field & {
      button[type="submit"] {
        width: 100%;
      }
    }
  }
}
