@import "~wonderbly-css/mixins/responsive";

[class~="description"] {
  margin: 10px 0;
  &:first-of-type {
    @include on("tablet-up") {
      margin-top: 50px;
    }
  }
  &:last-of-type {
    @include on("tablet-up") {
      margin-bottom: 50px;
    }
  }
  .row > div {
    margin: 0 auto;
    display: block;
    @include on("fablet-down") {
      width: 100%;
    }
  }
}
