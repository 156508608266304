@import "~chameleon/helpers/box";
@import "~wonderbly-css/base";

.order {
  @extend %spaced-bottom-small;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 rem(2) rem(2) 0 rgba(0, 0, 0, 0.1);
  padding: rem(2);
  &,
  .order-images {
    overflow: hidden;
  }
  .order-images {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .order__content {
    display: flex;
    flex-wrap: wrap;
    .order-status {
      width: 100%;
    }
    .order-status {
      @extend %spaced-bottom-tiny;
    }
    .order-delivery {
      @extend %spaced-right-small;
    }
  }
  .order__buttons {
    @extend %spaced-top-small;
  }

  &.order--canceled,
  &.order--delivered {
    .order__content {
      .order-delivery {
        display: none;
      }
    }
  }
}
