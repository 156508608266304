@import "~wonderbly-css/base";

.HorizontalCards {
  margin: $spacer-xl 0;
  &__title,
  &__description {
    text-align: center;
    margin: 0;
  }
  &__title {
    @extend %text-tera;
  }
  &__description {
    @extend %text-mega;
    margin-top: $spacer-sm;
  }

  &__product-info {
    display: flex;
    justify-content: space-between;
    margin-top: $spacer-md;
    &--age {
      @extend %font-style-heading;
      @extend %text-kilo;
      color: $text-color-base;
    }
    @include on("fablet-down") {
      flex-direction: column;
    }
  }

  .ScrollableCards {
    margin-top: $spacer-lg;
  }

  .Feature {
    display: flex;
    flex-direction: column;
    height: 100%;
    .Feature__body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      p {
        flex-grow: 1;
      }
    }
  }

  @include on("fablet-down") {
    margin: $spacer-lg 0;
  }
}
