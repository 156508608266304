@import "~wonderbly-css/functions/sizing";

.custom-select {
  .custom-select__select {
    margin-bottom: get-size("big");
  }
  // This is to ensure the page doesn't jump around when the input shows
  .custom-select__input {
    margin-top: -0.8 * get-size("big");
  }
}
