@import "~wonderbly-css/functions/sizing";

.trustpilot-rating {
  display: flex;
  align-content: center;
  float: right;

  img {
    max-height: rem(17px);
    margin-right: rem(1px);
  }
}
