@import "~wonderbly-css/base";
@import "~chameleon/helpers/box";

.drawer-input-cta-row {
  @extend %spaced-top-big;

  .button {
    @extend %widest-on-fablet-down;
  }

  .drawer-input-cta-button {
    margin-top: $spacer-std;
    text-align: right;
  }
}
