@import "~wonderbly-css/base";

.LineItemModal {
  padding: $spacer-std;

  &__wrap {
    width: 100%;
    max-width: rem(420);
    padding: $spacer-md $spacer-md $spacer-lg;
  }

  &__inner {
    width: 100%;
    max-width: rem(420);
    background: white;
    padding: $spacer-lg $spacer-md;
    text-align: center;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacer-md;
  }

  &__image-wrap {
    padding: 0 $spacer-xl;
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__title {
    @extend %text-giga;
    margin: 0 0 $spacer-md;
  }

  &__cta + &__cta {
    margin-top: $spacer-std;
  }
}
