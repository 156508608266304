@import "~chameleon/helpers/box";

.order-breakdown {
  .order_breakdown__title {
    @extend %spaced-bottom-tiny;
  }
  .order-breakdown__row {
    display: flex;
    & > * {
      width: 100%;
      justify-content: space-between;
    }
    .order-breakdown__item__label,
    .order-breakdown__item__price {
      margin: 0;
    }

    .order-breakdown__item__price {
      text-align: right;
    }
    &:not(:last-child) {
      @extend %spaced-bottom-tiny;
    }
  }
}
