@import "~wonderbly-css/base";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~mixins";
@import "~chameleon/helpers/box";

.checkout-order-summary {
  align-self: baseline;

  hr {
    @include line-break(rem(-15));
    border-color: $color-sharpie-10;
  }

  .loading {
    @extend %spaced-bottom-small;
    width: 100% !important;
  }

  .line-item-ii-image-wrapper {
    margin-right: 0;
  }

  .discount-form {
    @extend %padded-none;
    margin-bottom: 0;
    border: none;
    width: 100%;

    .discount-cta-button {
      flex: 1 0 auto;
    }

    .discount-form-input {
      width: 50%;
      flex-grow: 1;
    }

    @include on("tablet-down") {
      form {
        display: flex;
        align-items: center;
      }

      .discount-cta-button {
        margin-top: 0;
        margin-left: get-size("small");
        width: auto;
      }
    }
  }

  .cart-order-summary.panel {
    background: transparent;
  }

  .cart-order-summary-list {
    position: relative;

    p {
      font-size: rem(14);
    }

    &::after {
      @include line-break(rem(-15));
      content: "";
      width: calc(100% + #{rem(30)});
      position: absolute;
      left: 0;
    }

    .cart-order-summary-row {
      margin-bottom: 0;

      .cart-order-summary-label {
        color: $color-sharpie;
        line-height: rem(18);
      }
    }
  }

  .cart-order-summary-row--large {
    border: none;

    .cart-order-summary-label {
      @extend %font-style-heading;
      font-family: $font-family-sans-serif;
    }
  }

  @include on("desktop-up") {
    background-color: $color-bright-smile;
    margin-top: get-size("default");
    padding: get-size("less") get-size("less") 0;
  }

  @include on("tablet-down") {
    margin-top: get-size("small");
    position: relative;

    &::after {
      @include line-break(rem(-15));
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: rem(-11);
    }
  }
}

.checkout-order-summary__collapsible__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-order-summary__collapsible-item {
  .wrapper.two-column-layout .right-column > & {
    @extend %spaced-top;
  }

  .collapsible-ii__header {
    border-top: none;
  }

  .collapsible-ii__content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .checkout-line-item-wrapper {
    padding: 0;
  }

  &.collapsible-ii__item--active .collapsible-ii__header {
    box-shadow: none;
  }
}

.checkout-order-summary-title {
  @extend %font-style-heading;
}

.checkout-order-summary-total {
  color: get-colour("green");
  font-weight: 600;
  margin: 0;
}
