@import "~wonderbly-css/base";

.SiteMobilePrimaryNav {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: $color-sharpie;
    visibility: hidden;
    z-index: 7;

    &--is-visible {
      opacity: 0.6;
      transition: opacity 0.15s ease-out;
      visibility: visible;
    }
  }

  &__button {
    position: fixed;
    top: 0;
    // width of the nav
    left: calc(100vw - 5rem);
    @include on("tablet-up") {
      left: rem(400);
    }
    border: none;
    background-color: transparent;
    padding: $spacer-md;
  }

  &__country-toggle {
    padding: 1.5rem 1rem;
  }

  &__animation {
    position: fixed;
    top: 0;
    height: 100%;
    width: calc(100% - 5rem);
    max-width: rem(400);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    background-color: $color-bright-smile;
    z-index: 7;
  }

  &__btn-animation {
    position: fixed;
    top: 0;
    height: 100%;
    width: calc(100% - 5rem);
    max-width: rem(400);
    z-index: 7;
  }

  &__choose-country-modal {
    @include on("desktop-up") {
      display: none;
    }
  }
}

.preventScroll {
  overflow: hidden;
}
