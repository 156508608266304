@import "~chameleon/helpers/box";

.hero-form-row {
  display: flex;
  justify-content: flex-end;
  .hero-form-field {
    flex: 1;
    & > div {
      position: relative;
    }
    &.adventurer {
      min-width: 250px;
    }
  }

  .hero-form-cta-button {
    min-width: 250px;
    flex: 0.315;
  }

  &.hero-form-row--one-subfield {
    justify-content: space-between;

    div:first-child {
      @include on("desktop-up") {
        max-width: 370px;
      }
    }

    &::after,
    &::before {
      display: none;
    }
  }

  // No need to display this as it is just used to unset the selection of gender
  #gender-empty {
    display: none;
  }
}
