@import "~wonderbly-css/base";

@keyframes cardSelectInvalid {
  0% {
    transform: translateX(rem(3));
  }
  25% {
    transform: translateX(rem(-3));
  }
  50% {
    transform: translateX(rem(3));
  }
  75% {
    transform: translateX(rem(-3));
  }
  100% {
    transform: translateX(0);
  }
}

.CardSelect {
  &__item-wrap {
    margin-bottom: rem(14);
  }

  @include on("fablet-up") {
    &__wrap {
      overflow: auto;
      padding: $spacer-sm $spacer-md $spacer-std;
      margin-bottom: $spacer-std;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      &::-webkit-scrollbar {
        height: $spacer-std;
      }

      &::-webkit-scrollbar-track {
        background: get-colour("white");
      }

      &::-webkit-scrollbar-thumb {
        background: get-colour("stone-dark");
      }
    }

    &__items {
      display: flex;
      width: $page-max-width;
      margin: 0 auto;
    }

    &__item-wrap {
      flex: 0 0 rem(260);
      padding: 0 rem(14) 0 0;
      margin-bottom: 0;

      & > * {
        height: 100%;
      }
    }
  }

  &__helper {
    transition: color 0.2s;
    margin: 0 0 $spacer-md;

    &--invalid {
      font-weight: $font-weight-semi;
      animation: cardSelectInvalid 0.3s ease-in-out;
      color: get-colour("red");
    }

    @include on("fablet-up") {
      margin-bottom: 0;
    }
  }

  &__cta {
    @include on("fablet-up") {
      max-width: rem(230);
      margin-left: $spacer-md;
    }
  }

  &__footer {
    margin-top: $spacer-lg;

    @include on("fablet-up") {
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
