@import "~wonderbly-css/base";

.AvatarCreationModal {
  .modal__container {
    height: 100%;
    width: 100%;
    max-width: rem(560);

    @include on("fablet-up") {
      padding: $spacer-md;
    }

    @include on("desktop-up") {
      height: 90%;
    }
  }
}
