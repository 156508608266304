@import "~wonderbly-css/base";
@import "~wonderbly-css/config/palette";
@import "~wonderbly-css/functions/sizing";
@import "~wonderbly-css/utilities/aesthetic";
@import "~chameleon/helpers/box";

.NavCountryOptions {
  @extend %padded;
  background: rgba($color-sharpie, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  overflow: auto;
  transition: visibility 0.25s ease 0.25s, opacity 0.25s ease;
  visibility: hidden;
  opacity: 0;

  h3 {
    @extend %spaced-bottom;
    text-align: left;
    font-size: 1.125rem;
    color: $color-racing-green;
  }

  @include on("desktop-up") {
    position: absolute;
    top: 100%;
    left: 50%;
    padding: rem(5);
    width: rem(288);
    transition: visibility 0.25s ease 0.25s, opacity 0.25s ease,
      transform 0.25s $bezier-bounce;
    transform: translateX(rem(-144)) rotateX(-25deg);
    background: none;
    height: auto;
    overflow: visible;
    transform-origin: 50% 0;
  }

  &--open {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 0s;

    @include on("desktop-up") {
      transform: translateX(rem(-144)) rotateX(0);
      transition-delay: 0s, 0s, 0s;
      left: 50%;
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include on("desktop-up") {
      display: none;
    }
  }

  &__inner {
    @extend %rounded;
    @extend %padded;
    background: $color-bright-smile;
    box-shadow: 0px 2px 10px 0 rgba($color-sharpie, 0.5);
    position: relative;
    width: 100%;
    max-width: rem(480);
    height: 100%;
    margin: 0 auto;
  }

  &__close {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: -1rem;
    right: -1rem;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 50%;
    background: $color-sharpie;
    color: $color-bright-smile;
    font-size: 1rem;
    line-height: 0;
    text-align: center;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      inset: -1rem;
    }

    &:hover,
    &:focus {
      background: $color-racing-green;
    }

    &:active {
      outline: none;
      background: $color-sharpie;
      color: $color-bright-smile;
    }
  }

  &__wrap {
    max-height: calc(100% - 46px);
    overflow: auto;
  }
}
